import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { IvaTypology } from 'src/app/commons/models/iva-typology.model';
import { AlertService } from 'src/app/commons/services/alert.service';
import * as IvaTypologyActions from 'src/app/store/actions/iva-typology.actions';
import { AppState } from 'src/app/store/reducers';

@Component({
  selector: 'app-iva-typology-edit',
  templateUrl: './iva-typology-edit.component.html',
  styleUrls: ['./iva-typology-edit.component.scss']
})
export class IvaTypologyEditComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  private _ivaTypology: IvaTypology;

  currentIvaTypology: IvaTypology;

  ivaTypologyForm: FormGroup;
  constructor(private store$: Store<AppState>, private alertService: AlertService, private fb: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private translateService: TranslateService) {
  }

  ngOnInit() {
    if (this.data) {
      this.ivaTypology = this.data.ivaTypology;
    }
    this._createForm();
    this.ngOnChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private _createForm() {
    let group = {
      code: ["", Validators.required],
      percentage: ["", Validators.required],
      nature: [""],
    }
    this.ivaTypologyForm = this.fb.group(group);
  }

  private ngOnChanges() {
    if (this.ivaTypologyForm) {
      this.ivaTypologyForm.reset();
      if (this.ivaTypology) {
        this._initFormValues(this.ivaTypology);
      }
    }

  }

  private _initFormValues(ivaTypology: IvaTypology) {
    if (ivaTypology) {
      this.ivaTypologyForm.patchValue({
        code: ivaTypology.code,
        percentage: ivaTypology.percentage,
        nature: ivaTypology.nature,
      });
    }
  }

  get ivaTypology(): IvaTypology {
    return this._ivaTypology;
  }

  set ivaTypology(ivaTypology: IvaTypology) {
    this._ivaTypology = ivaTypology;
    this.ngOnChanges();
  }

  private _prepareSaveIvaTypology(): IvaTypology {
    let savingIvaTypology: IvaTypology = IvaTypology.fromFormGroup(this.ivaTypologyForm, this.ivaTypology);
    return savingIvaTypology;
  }

  save() {
    let unsavedEntity = this._prepareSaveIvaTypology();
    this.store$.dispatch(IvaTypologyActions.saveIvaTypology({ ivaTypology: unsavedEntity }));
  }

  close() {
    if (this.ivaTypologyForm.pristine) {
      this.store$.dispatch(IvaTypologyActions.closeIvaTypologyDialog())
    } else {
      this.alertService
        .showConfirmDialog(
          `${this.translateService.instant('shared.Chiudi')}`,
          `${this.translateService.instant('shared.Ci sono modifiche non salvate. Sei sicuro di voler chiudere?')}`
        )
        .subscribe(result => {
          if (result) {
            this.store$.dispatch(IvaTypologyActions.closeIvaTypologyDialog())
          }
        });
    }
  }

  deleteIvaTypology() {
    if (this.ivaTypology) {
      this.store$.dispatch(IvaTypologyActions.deleteIvaTypology({ ivaTypology: this.ivaTypology.toDTO() }))
    }
  }

  revert() {
    this.ngOnChanges();
  }
}
