<header mat-dialog-title class="u-flex-full">
  <span> {{'contacts.Seleziona contatto' | translate}} </span>
</header>

<mat-dialog-content>
  <contact-list
    [contacts]="contacts"
    [defaultFilters]="defaultFilters"
    [displayedColumns]="displayedColumns"
    [total]="total"
    [canAdd]="canAdd"
    [canSelect]="true"
    (onSortChange)="sortChange($event)"
    (onPageChange)="pageChange($event)"
    (onFilterChange)="filtersChange($event)"
    (onAddContact)="addContact()"
    (onSelectContact)="selectContact($event)"
  ></contact-list>
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span> </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">
    {{'shared.Chiudi' | translate}}
  </button>
</mat-dialog-actions>
