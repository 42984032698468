import { AbstractControl } from '@angular/forms';

import { Base, BaseDTO } from './base.model';
import { Currency, CurrencyDTO } from './currency.model';

export type Role = "admin" | "professional" | "super_user";
export type Seniority = 'Partner' | 'Consultant' | 'External professional' // "Internal support" | "Trainee / Stagiaire" | "Junior Consultant" | "Senior Consultant" | "Principal"
export const SENIORITIES: Seniority[] = ['Partner', 'Consultant', 'External professional'] // 'Internal support', 'Trainee / Stagiaire', 'Junior Consultant', 'Senior Consultant', 'Principal',
export const SEPARATORS: string[] = [',', ';']

export interface UserFilters {
  search?: string;
  roles?: Role[];
  active?: boolean;
}

export interface UserDTO extends BaseDTO {
  name: string;
  lastname: string;
  email: string;
  password: string;
  user_roles: Role[];
  active?: boolean;
  company_name?: string;
  address?: string;
  city?: string;
  province?: string;
  country?: string;
  CAP?: string;
  PEC?: string;
  price?: string;
  price_percentage?: boolean;
  phone?: string;
  SDI?: string;
  VAT?: string;
  identifier?: string;
  seniority: Seniority;
  currency_id: number;
  currency: CurrencyDTO;
  export_separator: string;
}

export class User extends Base {
  name: string;
  lastname: string;
  email: string;
  password: string;
  role: Role;
  active?: boolean;
  companyName?: string;
  address?: string;
  city?: string;
  province?: string;
  country?: string;
  CAP?: string;
  PEC?: string;
  price?: string;
  pricePercentage?: boolean;
  phone?: string;
  SDI?: string;
  VAT?: string;
  identifier?: string;
  seniority: Seniority;
  currency: Currency;
  exportSeparator: string;

  constructor(source: UserDTO) {
    super(source);
    if (source) {
      this.name = source.name;
      this.lastname = source.lastname;
      this.email = source.email;
      this.password = source.password;
      if (source.user_roles) {
        this.role = source.user_roles[0];
      }
      if (source.currency) {
        this.currency = new Currency(source.currency);
        this.addLoadedRelation('currency');
      }
      this.active = source.active;
      this.companyName = source.company_name;
      this.address = source.address;
      this.city = source.city;
      this.province = source.province;
      this.country = source.country;
      this.CAP = source.CAP;
      this.PEC = source.PEC;
      this.price = source.price;
      this.phone = source.phone;
      this.SDI = source.SDI;
      this.VAT = source.VAT;
      this.identifier = source.VAT;
      this.seniority = source.seniority;
      this.pricePercentage = source.price_percentage;
      this.exportSeparator = source.export_separator;
    }
  }

  toDTO(): UserDTO {
    let result: UserDTO = <UserDTO>super.toDTO();
    result.name = this.name;
    result.lastname = this.lastname;
    result.email = this.email;
    result.password = this.password;
    result.user_roles = [];
    if (this.role) {
      result.user_roles.push(this.role);
    }
    result.active = this.active;
    result.company_name = this.companyName;
    result.address = this.address;
    result.city = this.city;
    result.province = this.province;
    result.country = this.country;
    result.CAP = this.CAP;
    result.PEC = this.PEC;
    result.price = this.price;
    result.phone = this.phone;
    result.SDI = this.SDI;
    result.VAT = this.VAT;
    result.identifier = this.identifier;
    result.seniority = this.seniority;
    result.currency = this.currency ? this.currency.toDTO() : null;
    result.currency_id = this.currency ? this.currency.id : null;
    result.price_percentage = this.pricePercentage;
    result.export_separator = this.exportSeparator;

    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: User) {
    const formModel = formGroup.value;
    let user: User = new User(null);
    user.name = formModel.name;
    user.lastname = formModel.lastname;
    user.email = formModel.email;
    user.password = formModel.password;
    user.role = formModel.role;
    user.active = formModel.active;
    user.companyName = formModel.companyName;
    user.address = formModel.address;
    user.city = formModel.city;
    user.province = formModel.province;
    user.country = formModel.country;
    user.CAP = formModel.CAP;
    user.PEC = formModel.PEC;
    user.price = formModel.price;
    user.phone = formModel.phone;
    user.SDI = formModel.SDI;
    user.VAT = formModel.VAT;
    user.identifier = formModel.identifier;
    user.seniority = formModel.seniority;
    user.currency = formModel.currency;
    user.pricePercentage = formModel.pricePercentage;
    user.exportSeparator = formModel.exportSeparator;

    if (original) {
      user.id = original.id;
    }
    return user;
  }

  get admin(): boolean {
    return this.hasRole("admin");
  }

  get professional(): boolean {
    return this.hasRole("professional");
  }
  get superUser(): boolean {
    return this.hasRole("super_user");
  }

  hasRole(role: Role): boolean {
    return this.role && this.role === role;
  }

  get fullName(): string {
    return `${this.name} ${this.lastname}`;
  }

  get fullAddress() {
    const parts = [this.address, this.city, this.CAP, this.province, this.country];
    return parts.join(' ');
  }
}
