import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';

import { ActivityFilters, ActivityReport } from '../../../commons/models/activity.model';
import { PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';

export type ActivitiesReportColumn = "client" | "project" | "professional" | "date" | "hours" | "actions";

@Component({
  selector: 'activity-report-list',
  templateUrl: './activity-report-list.component.html',
  styleUrls: ['./activity-report-list.component.scss'],
})
export class ActivityReportListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  activitiesReport: ActivityReport[];

  @Input()
  defaultFilters: ActivityFilters;

  pageSizeOptions = PAGE_SIZE_OPTIONS;
  expandedElement: ActivityReport | null;

  @Input()
  total: number;
  @Input()
  displayedColumns: ActivitiesReportColumn[] = ["client", "project", "professional", "date", "hours", "actions"];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<ActivityFilters> = new EventEmitter<ActivityFilters>();
  @Output()
  onExportActivityReport: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  onFilter(filters: ActivityFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }

  exportActivityReport() {
    this.onExportActivityReport.emit();
  }

  get totalHours(): number {
    return this.activitiesReport?.map(a => Number(a.hours)).reduce((a, b) => a + b, 0);
  }
}
