<ng-container [formGroup]="form">
  <ng-container
    *ngFor="let professionalCtrl of form.controls; let index = index"
    [formGroupName]="index"
  >
    <div class="u-full-width professional">
      <button
        mat-icon-button
        mat-raised-button
        color="accent"
        (click)="removeProfessional(index)"
        matTooltip="{{'shared.Rimuovi professionista' | translate}}"
      >
        <mat-icon>delete</mat-icon>
      </button>
      <mat-form-field>
        <mat-label>{{'shared.Professionista' | translate}}</mat-label>
        <app-user-field formControlName="user"></app-user-field>
        <mat-error *ngIf="professionalCtrl.get('user').errors?.required"
          >{{'shared.Campo obbligatorio' | translate}}</mat-error
        >
        <mat-error *ngIf="professionalCtrl.get('user').errors?.userAlreadyAdded"
          >{{'professional.Professionista già presente' | translate}}</mat-error
        >
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder="{{'projects.Fatturato € / km' | translate}}"
          formControlName="kmRevenue"
          type="number"
          min="0"
          forceNumber
        />
        <mat-icon matSuffix>euro</mat-icon>
        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder="{{'projects.Rimborso € / km' | translate}}"
          formControlName="kmCost"
          type="number"
          min="0"
          forceNumber
        />
        <mat-icon matSuffix>euro</mat-icon>
        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
      </mat-form-field>
      <mat-form-field *ngIf="advisoryDebitType !== 'Fisso totale'">
        <!--
          [readonly]="
            professionalCtrl?.value?.user?.seniority == 'Consultant' ||
            professionalCtrl?.value?.user?.seniority == 'Partner'
          "
        -->
        <input
          matInput
          placeholder="{{'shared.Ricavo giornaliero' | translate}}"
          formControlName="dailyRevenue"
          type="number"
          min="0"
          forceNumber
        />
        <mat-icon matSuffix>euro</mat-icon>
        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
      </mat-form-field>
      <mat-form-field
        *ngIf="advisoryPayment === 'A ore' || advisoryPayment === 'A giornata'"
      >
        <input
          matInput
          placeholder="{{'shared.Costo giornaliero' | translate}}"
          formControlName="dailyCost"
          type="number"
          min="0"
          forceNumber
        />
        <mat-icon matSuffix>euro</mat-icon>
        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
      </mat-form-field>
      <mat-form-field
        *ngIf="
          advisoryPayment === 'Fisso totale' || advisoryPayment === 'Mensile'
        "
      >
        <input
          matInput
          placeholder="{{'professional.Costo forfettario' | translate}}"
          formControlName="rateCost"
          type="number"
          min="0"
          forceNumber
        />
        <mat-icon matSuffix>euro</mat-icon>
        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
      </mat-form-field>
      <button
        mat-raised-button
        color="primary"
        *ngIf="!professionalCtrl?.value.headProject"
        (click)="setHeadProject(index)"
      >
        {{'professional.Imposta come Capo Commessa' | translate}}
      </button>
      <mat-icon
        *ngIf="!!professionalCtrl?.value.headProject"
        [matTooltip]="professionalCtrl?.value?.user?.fullName + ' ' + ('shared.è Capo commessa' | translate)"

        >groups</mat-icon
      >
    </div>
    <!-- <button mat-raised-button (click)="removeProfessional(index)">REMOVE</button> -->
  </ng-container>
</ng-container>
<div class="u-center-text">
  <button
    mat-raised-button
    type="button"
    color="accent"
    (click)="addProfessional()"
    [disabled]="form.invalid"
  >
    <span>{{'professional.Aggiungi professionista' | translate}}</span>
  </button>
</div>
