<!-- <app-professional-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters" [showProject]="showProject">
</app-professional-filters> -->
<mat-card class="u-center-text" *ngIf="!(professionals | async).length">
  <h4>{{'professional.Nessun professionista trovato' | translate}}</h4>
  <button *ngIf="canAdd" mat-raised-button (click)="addProfessional()">{{'shared.Aggiungi' | translate}}</button>
</mat-card>
<div class="table-container" [hidden]="!(professionals | async).length">
  <table mat-table matSort [dataSource]="professionals">

    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef> {{'shared.Utente' | translate}} </th>
      <td mat-cell *matCellDef="let row">
        {{row.user?.name}} {{row.user?.lastname}}
      </td>
    </ng-container>
    <ng-container matColumnDef="dailyCost">
      <th mat-header-cell *matHeaderCellDef class="text-right"> {{'shared.Costo giornaliero' | translate}} </th>
      <td mat-cell *matCellDef="let row" class="text-right no-wrap">
        {{row.dailyCost | customCurrency }}&nbsp;€
      </td>
    </ng-container>
    <ng-container matColumnDef="rateCost">
      <th mat-header-cell *matHeaderCellDef> {{'professional.Costo forfettario' | translate}} </th>
      <td mat-cell *matCellDef="let row">
        {{row.rateCost}}
      </td>
    </ng-container>
    <ng-container matColumnDef="kmCost">
      <th mat-header-cell *matHeaderCellDef> {{'shared.Costo € / km' | translate}} </th>
      <td mat-cell *matCellDef="let row">
        {{row.kmCost }}
      </td>
    </ng-container>
    <ng-container matColumnDef="dailyRevenue">
      <th mat-header-cell *matHeaderCellDef> {{'shared.Ricavo giornaliero' | translate}} </th>
      <td mat-cell *matCellDef="let row">
        {{ row.dailyRevenue}}
      </td>
    </ng-container>
    <ng-container matColumnDef="kmRevenue">
      <th mat-header-cell *matHeaderCellDef> {{'professional.Ricavo € / km' | translate}} </th>
      <td mat-cell *matCellDef="let row">
        {{row.kmRevenue}}
      </td>
    </ng-container>
    <ng-container matColumnDef="headProject">
      <th mat-header-cell *matHeaderCellDef> {{'shared.Capo Commessa' | translate}} </th>
      <td mat-cell *matCellDef=" let row">
        {{row.headProject ? "Sì" : "No"}}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <button *ngIf="canAdd" mat-icon-button mat-raised-button color="accent"
          (click)="$event.stopPropagation();addProfessional()">
          <mat-icon>playlist_add</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="canEdit">
          <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
            <button mat-menu-item (click)="editProfessional(row)">
              <mat-icon>create</mat-icon>
              {{'shared.Modifica' | translate}}
            </button>
          </mat-menu>
        </ng-container>
        <button *ngIf="canSelect" mat-raised-button color="primary"
          (click)="selectProfessional(row)">{{'shared.Seleziona' | translate}}</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>