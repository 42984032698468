<header mat-dialog-title class="u-flex-full">
  <span>
    <span *ngIf="contact">Modifica contatto: {{contact.name}} {{contact.lastname}}</span>
    <span *ngIf="!contact">Nuovo contatto</span>
  </span>
  <span *ngIf="contact">
    <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
      <button mat-menu-item (click)="deleteContact()">
        <mat-icon>delete</mat-icon>
        Elimina
      </button>
    </mat-menu>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="contactForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input matInput placeholder="Nome" formControlName="name" type="text">
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput placeholder="Cognome" formControlName="lastname" type="text">
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input matInput placeholder="Email" formControlName="email" type="email">
        <mat-error>Email non valida</mat-error>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput placeholder="Telefono" formControlName="phone" type="text">
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input matInput placeholder="Cellulare" formControlName="mobile" type="text">
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput placeholder="Fax" formControlName="fax" type="text">
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <app-user-field placeholder="Internal Contact" formControlName="internalUser"> </app-user-field>
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <textarea matInput formControlName="note" placeholder="Note" rows="3"></textarea>
      </mat-form-field>
    </div>

  </form>
  <!-- {{contactForm.value | json}} -->

</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()"
      [disabled]="contactForm.pristine">Reset</button>
    <button mat-raised-button color="primary" (click)="save()"
      [disabled]="contactForm.pristine || !contactForm.valid">Salva</button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">Chiudi</button>
</mat-dialog-actions>