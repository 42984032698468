<mat-expansion-panel style="margin-bottom: 16px;">
  <mat-expansion-panel-header>
    {{'shared.Filtra' | translate}}
  </mat-expansion-panel-header>
  <form [formGroup]="filtersForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>{{'professional.User' | translate}}</mat-label>
        <app-user-field formControlName="user"></app-user-field>
      </mat-form-field>

      <mat-form-field *ngIf="showProject" floatLabel="always" class="u-full-width">
        <mat-label>{{'shared.Commessa' | translate}}</mat-label>
        <app-project-field formControlName="project" [canSelectClosed]="true"></app-project-field>
      </mat-form-field>
    </div>

    <div>
      <span class="u-flex-full">
        <button class="filter" mat-raised-button [disabled]="filtersForm.pristine" color="primary"
          (click)="applyFilters()">{{'shared.Filtra' | translate}}</button>
        <button mat-icon-button *ngIf="!filtersForm.pristine" (click)="resetFilters()"
          matTooltip="{{'shared.Reset' | translate}}">
          <mat-icon>replay</mat-icon>
        </button>
      </span>
    </div>
  </form>
</mat-expansion-panel>