import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ProjectFilters } from '../../../commons/models/project.model';
import { booleanForFilters } from 'src/app/helpers/form.utils';

@Component({
  selector: 'app-project-filters',
  templateUrl: './project-filters.component.html',
  styleUrls: ['./project-filters.component.scss']
})
export class ProjectFiltersComponent {


  @Output()
  onFilter: EventEmitter<ProjectFilters> = new EventEmitter();

  private _defaultFilters: ProjectFilters;
  filtersForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this._createForm();
  }

  private _createForm() {
    let group = {
      search: "",
      client: "",
      professional: "",
      start: "",
      end: "",
      creator: "",
      modifier: "",
      promotion: "",
      excludeWithOffer: "",
      trashed: "",
      closed: ""
    }

    this.filtersForm = this.fb.group(group);
  }

  applyFilters() {
    this.onFilter.emit(this._getProjectFilters());
  }

  private _getProjectFilters(): ProjectFilters {
    let filters: ProjectFilters = {}
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.search = values.search;
      filters.client = values.client;
      filters.professional = values.professional;
      filters.start = values.start;
      filters.end = values.end
      filters.creator = values.creator;
      filters.modifier = values.modifier;
      filters.promotion = values.promotion;
      filters.excludeWithOffer = values.excludeWithOffer;
      filters.trashed = values.trashed,
        filters.closed = values.closed
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      search: "",
      client: "",
      professional: "",
      start: "",
      end: "",
      creator: "",
      modifier: "",
      excludeWithOffer: "",
      trashed: "",
      closed: ""
    });
    this._setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): ProjectFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: ProjectFilters) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }
  @Input()
  showPromoFilter: boolean = true;
  @Input()
  showClientFilter: boolean = true;

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
          search: this.defaultFilters.search,
          client: this.defaultFilters.client,
          professional: this.defaultFilters.professional,
          start: this.defaultFilters.start,
          end: this.defaultFilters.end,
          creator: this.defaultFilters.creator,
          modifier: this.defaultFilters.modifier,
          promotion: booleanForFilters(this.defaultFilters.promotion),
          trashed: booleanForFilters(this.defaultFilters.trashed),
          closed: booleanForFilters(this.defaultFilters.closed),
          excludeWithOffer: this.defaultFilters.excludeWithOffer
        })
      }
    }
  }


}
