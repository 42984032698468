
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { CurrencyEditComponent } from 'src/app/modules/home/currencies/currency-edit/currency-edit.component';
import { CurrencySelectionComponent } from 'src/app/modules/shared/currency-selection/currency-selection.component';
import { AlertService } from '../../commons/services/alert.service';
import { LaravelCurrencyService } from '../../commons/services/backend/laravel-currency.service';
import * as CurrencyActions from '../actions/currency.actions';
import { AppState } from '../reducers';
import { getCurrencyDialogId, getCurrenciesTableState, getSelectionDialogId } from '../selectors/currency.selectors';
import { TranslateService } from '@ngx-translate/core';



@Injectable()
export class CurrencyEffects {

  error$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrencyActions.saveCurrencyFailed),
      tap(({ error }) => {
        if (error) {
          this.alertService.showErrorMessage(`${this.translateService.instant('shared.errore')}`, error);
        }
      })
    ), { dispatch: false }
  );

  loadCurrencys$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrencyActions.loadCurrencies),
      switchMap(({ page, perPage, order, direction, filters, includes }) => {
        return this.currencyService.list(page, perPage, order, direction, filters, includes)
          .pipe(
            map(result =>
              CurrencyActions.loadCurrenciesCompleted({ currencies: result.data, currentPage: page, total: result.total, perPage, order, direction, filters, includes })
            ),
            catchError(error => {
              return of(CurrencyActions.loadCurrenciesFailed({ error }))
            })
          )
      })
    )
  );

  changePage = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrencyActions.changePage),
      withLatestFrom(this.store$.select(getCurrenciesTableState)),
      map(([{ page, size }, { total, currentPage, perPage, direction, order, filters, includes }]) => CurrencyActions.loadCurrencies({ page: page, perPage: size, order, direction, filters, includes }))
    )
  );

  changeSort = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrencyActions.changeSort),
      withLatestFrom(this.store$.select(getCurrenciesTableState)),
      map(([action, { total, currentPage, perPage, direction, order, filters, includes }]) => CurrencyActions.loadCurrencies({ page: currentPage, perPage: perPage, order: action.order, direction: action.direction, filters, includes }))
    )
  );

  changeFilters = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrencyActions.changeFilters),
      withLatestFrom(this.store$.select(getCurrenciesTableState)),
      map(([{ filters }, { total, currentPage, perPage, direction, order, includes }]) => CurrencyActions.loadCurrencies({ page: currentPage, perPage: perPage, order, direction, filters, includes }))
    )
  );

  editCurrency$ = createEffect(() => this.actions$.pipe(
    ofType(CurrencyActions.editCurrency),
    map(({ currency }) => {
      let dialogRef = this.dialog.open(CurrencyEditComponent, {
        data: {
          currency
        }
      });
      return CurrencyActions.currencyDialogOpened({ dialogId: dialogRef.id });
    }))
  );

  saveCurrency$ = createEffect(() =>
  this.actions$.pipe(
    ofType(CurrencyActions.saveCurrency),
    mergeMap(({ currency }) =>
      this.currencyService.upsert(currency.toDTO())
        .pipe(
          map(result =>
            CurrencyActions.saveCurrencyCompleted({ currency: result })
          ),
          catchError(error => of(CurrencyActions.saveCurrencyFailed({ error })))
        )
    )
  )
);

onSaveCompleted$ = createEffect(() =>
  this.actions$.pipe(
    ofType(CurrencyActions.saveCurrencyCompleted),
    map(action => action.currency),
    tap(currency => this.alertService.showConfirmMessage(`${currency.name} ${this.translateService.instant('shared.salvata con successo')}`)),
    map(() => CurrencyActions.closeCurrencyDialog())
  )
);
   

  deleteCurrency$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrencyActions.deleteCurrency),
      switchMap(({ currency }) =>
        this.alertService.showConfirmDialog(`${this.translateService.instant('shared.Conferma eliminazione')}`, `${this.translateService.instant('currencies.Sei sicuro di voler eliminare la valuta')} ${currency.name}?`)
          .pipe(
            mergeMap((confirm) => {
              return confirm ?
                this.currencyService.delete(currency.id)
                  .pipe(
                    map(() => CurrencyActions.deleteCurrencyCompleted({ currency })),
                    catchError(error => of(CurrencyActions.deleteCurrencyFailed({ error })))
                  )

                : of(CurrencyActions.deleteCurrencyCancelled());
            })
          )
      )
    )
  );

  onDeleteCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrencyActions.deleteCurrencyCompleted),
      tap(({ currency }) => this.alertService.showConfirmMessage(`${this.translateService.instant('currencies.Valuta eliminata', {total:currency.name})}`)),
      map(() => CurrencyActions.closeCurrencyDialog())
    )
  );


  closeDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrencyActions.closeCurrencyDialog),
      withLatestFrom(this.store$.select(getCurrencyDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }
      })
    ), { dispatch: false }
  );

  reloadAfterSave = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrencyActions.saveCurrencyCompleted),
      withLatestFrom(this.store$.select(getCurrenciesTableState)),
      map(([_, { currentPage, perPage, direction, order, filters, includes }]) => CurrencyActions.loadCurrencies({ page: currentPage, perPage, order, direction, filters, includes }))
    )
  );

  reloadAfterDelete = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrencyActions.deleteCurrencyCompleted),
      withLatestFrom(this.store$.select(getCurrenciesTableState)),
      map(([_, { currentPage, perPage, direction, order, filters, includes }]) => CurrencyActions.loadCurrencies({ page: currentPage, perPage, order, direction, filters, includes }))
    )
  );


  selectCurrency$ = createEffect(() => this.actions$.pipe(
    ofType(CurrencyActions.selectCurrency),
    map(({ filters }) => {
      let dialogRef = this.dialog.open(CurrencySelectionComponent, {
        data: {
          defaultFilters: filters
        }
      });
      return CurrencyActions.selectionDialogOpened({ selectionDialogId: dialogRef.id });
    }))
  );
  closeSelectionDialog = createEffect(() =>
  this.actions$.pipe(
    ofType(CurrencyActions.closeSelectionDialog),
    withLatestFrom(this.store$.select(getSelectionDialogId)),
    tap(([_, dialogId]) => {
      if (dialogId) {
        this.dialog.getDialogById(dialogId).close();
      }

    })
  ), { dispatch: false }
);

  currencysSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrencyActions.currencySelected),
      map(() => CurrencyActions.closeSelectionDialog())
    ))


  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private currencyService: LaravelCurrencyService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private translateService: TranslateService
  ) { }
}
