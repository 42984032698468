<app-bank-account-filters
  (onFilter)="onFilter($event)"
  [defaultFilters]="defaultFilters"
>
</app-bank-account-filters>
<mat-card class="u-center-text" *ngIf="!(bankAccounts | async).length">
  <h4>{{'bank-accounts.Nessun conto corrente trovato' | translate}}</h4>
  <button *ngIf="canAdd" mat-raised-button (click)="addBankAccount()">
    Aggiungi
  </button>
</mat-card>
<div class="table-container" [hidden]="!(bankAccounts | async).length">
  <table mat-table matSort [dataSource]="bankAccounts">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>{{'shared.ID' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        {{ row.id }}
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'shared.Nome' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        {{ row.name }}
      </td>
    </ng-container>
    <ng-container matColumnDef="iban">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'bank-accounts.IBAN' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        {{ row.iban }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <button
          *ngIf="canAdd"
          mat-icon-button
          mat-raised-button
          color="accent"
          (click)="$event.stopPropagation(); addBankAccount()"
        >
          <mat-icon matTooltip="{{'shared.Aggiungi' | translate}}">playlist_add</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="canEdit">
          <button
            mat-icon-button
            (click)="$event.stopPropagation(); editBankAccount(row)"
          >
            <mat-icon matTooltip="{{'shared.Modifica' | translate}}">create</mat-icon>
          </button>
        </ng-container>
        <button
          *ngIf="canSelect"
          mat-raised-button
          color="primary"
          (click)="selectBankAccount(row)"
        >
          {{'shared.Seleziona' | translate}}
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageSizeOptions[0]"
    [length]="total | async"
    showFirstLastButtons
  >
  </mat-paginator>
</div>
