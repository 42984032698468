<header mat-dialog-title class="u-flex-full">
  <span>
    <span *ngIf="riskCategory"
      >{{'risks.Modifica categoria contromisura' | translate}} : {{ riskCategory.name }}</span
    >
    <span *ngIf="!riskCategory">{{'risks.Nuova categoria contromisura' | translate}}</span>
  </span>
  <span *ngIf="riskCategory">
    <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
      <button mat-menu-item (click)="deleteRiskCategory()">
        <mat-icon>delete</mat-icon>
        {{'shared.Elimina' | translate}}
      </button>
    </mat-menu>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="riskCategoryForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'shared.Nome' | translate}}" formControlName="name" type="text" />
        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
      </mat-form-field>
    </div>
  </form>
  <!-- {{riskCategoryForm.value | json}} -->
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button
      mat-raised-button
      color="basic"
      type="reset"
      (click)="revert()"
      [disabled]="riskCategoryForm.pristine"
    >
    {{'shared.Reset' | translate}}
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="save()"
      [disabled]="riskCategoryForm.pristine || !riskCategoryForm.valid"
    >
    {{'shared.Salva' | translate}}
    </button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">
    {{'shared.Chiudi' | translate}}
  </button>
</mat-dialog-actions>
