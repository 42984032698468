<header mat-dialog-title class="u-flex-full">
  <span>
    {{'offers.Seleziona offerta' | translate}}
  </span>
</header>
<mat-dialog-content>
  <offer-list [offers]="offers" [defaultFilters]="defaultFilters" [displayedColumns]="displayedColumns" [total]="total"
    [canSelect]="true" (onSortChange)="sortChange($event)" (onPageChange)="pageChange($event)"
    (onFilterChange)="filtersSelectionChange($event)" (onSelectOffer)="selectOffer($event)"></offer-list>
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">{{'shared.Chiudi' | translate}}</button>
</mat-dialog-actions>