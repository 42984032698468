<mat-expansion-panel style="margin-bottom: 16px">
  <mat-expansion-panel-header> {{'shared.Filtra' | translate}} </mat-expansion-panel-header>
  <form [formGroup]="filtersForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>{{'shared.Data' | translate}}</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="{{'shared.Inizio' | translate}}" formControlName="start" />
          <input matEndDate placeholder="{{'shared.Fine' | translate}}" formControlName="end" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>{{'shared.Cliente' | translate}}</mat-label>
        <app-client-field formControlName="client"></app-client-field>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>{{'shared.Professionista' | translate}}</mat-label>
        <app-user-field formControlName="professional"></app-user-field>
      </mat-form-field>
    </div>

    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>{{'shared.Commessa' | translate}}</mat-label>
        <app-project-field formControlName="project" [canSelectClosed]="true"></app-project-field>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>{{'shared.Capo Commessa' | translate}}</mat-label>
        <app-user-field formControlName="head_project"></app-user-field>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>{{'shared.Fattura cliente' | translate}}</mat-label>
        <input matInput type="text" formControlName="client_invoice_id">
      </mat-form-field>

      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>{{'shared.Fattura professionista' | translate}}</mat-label>
        <input matInput type="text" formControlName="professional_invoice_id">
      </mat-form-field>

    </div>
    <div class="u-flex-full">

      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-select matInput placeholder="{{'shared.Pagatotre' | translate}}" formControlName="payer">
          <mat-option value="">{{'shared.-- Tutti --' | translate}}</mat-option>
          <mat-option *ngFor="let payer of payers" [value]="payer">
            {{ 'expenses.' + payer | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-select matInput placeholder="{{'projects.Addebito al cliente' | translate}}"
          formControlName="expenses_debit_type">
          <mat-option value="">{{'shared.-- Tutti --' | translate}}</mat-option>
          <mat-option *ngFor="let type of expenseTypes" [value]="type">
            {{ type }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-select matInput placeholder="{{'projects.Pagamento al professionista' | translate}}"
          formControlName="expenses_refund_type">
          <mat-option value="">{{'shared.-- Tutti --' | translate}}</mat-option>
          <mat-option *ngFor="let type of refundTypes" [value]="type">
            {{ type }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <mat-label>{{'shared.Contabilizzata'}}</mat-label>
        <mat-select formControlName="accounted">
          <mat-option value="">{{'shared.-- Tutti --' | translate}}</mat-option>
          <mat-option [value]="true">{{'shared.Sì' | translate}}</mat-option>
          <mat-option [value]="false">{{'shared.No' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <mat-label>{{'shared.Fatturabile al cliente' | translate}}</mat-label>
        <mat-select formControlName="billable">
          <mat-option value="">{{'shared.-- Tutti --' | translate}}</mat-option>
          <mat-option [value]="true">{{'shared.Sì' | translate}}</mat-option>
          <mat-option [value]="false">{{'shared.No' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="u-full-width">
        <mat-label>{{'shared.Pagabile al professionista' | translate}}</mat-label>
        <mat-select formControlName="payable">
          <mat-option value="">{{'shared.-- Tutti --' | translate}}</mat-option>
          <mat-option [value]="true">{{'shared.Sì' | translate}}</mat-option>
          <mat-option [value]="false">{{'shared.No' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="u-full-width">
        <mat-label>{{'shared.Nulla Osta' | translate}}</mat-label>
        <mat-select formControlName="validated">
          <mat-option value="">{{'shared.-- Tutti --' | translate}}</mat-option>
          <mat-option [value]="true">{{'shared.Sì' | translate}}</mat-option>
          <mat-option [value]="false">{{'shared.No' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="u-full-width">
        <mat-label>{{'shared.Promozione' | translate}}</mat-label>
        <mat-select formControlName="promo">
          <mat-option value="">{{'shared.-- Tutti --' | translate}}</mat-option>
          <mat-option [value]="true">{{'shared.Sì' | translate}}</mat-option>
          <mat-option [value]="false">{{'shared.No' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <span class="u-flex-full">
      <button class="filter" mat-raised-button [disabled]="filtersForm.pristine" color="primary"
        (click)="applyFilters()">
        {{'shared.Filtra' | translate}}
      </button>
      <button mat-icon-button *ngIf="!filtersForm.pristine" (click)="resetFilters()"
        matTooltip="{{'shared.Reset' | translate}}">
        <mat-icon>replay</mat-icon>
      </button>
    </span>
  </form>
</mat-expansion-panel>