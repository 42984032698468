<app-action-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters">
</app-action-filters>
<mat-card class="u-center-text" *ngIf="!(actions | async).length">
  <h4>{{'shared.Nessuna attività trovata' | translate}}</h4>
  <button *ngIf="canAdd" mat-raised-button (click)="addAction()">{{'shared.Aggiungi' | translate}}</button>
</mat-card>
<div class="table-container" [hidden]="!(actions | async).length">
  <table mat-table matSort [dataSource]="actions">

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> {{'shared.ID' | translate}} </th>
      <td mat-cell *matCellDef="let row">
        {{row.id}}
      </td>
    </ng-container>
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef> {{'shared.Data' | translate}} </th>
      <td mat-cell *matCellDef="let row">
        {{row.date | dateFormat }}
      </td>
    </ng-container>

    <ng-container matColumnDef="businessOpportunity">
      <th mat-header-cell *matHeaderCellDef> {{'shared.Business Opportunity' | translate}} </th>
      <td mat-cell *matCellDef="let row">
        {{row.businessOpportunity?.title}}
      </td>
    </ng-container>
    <ng-container matColumnDef="offer">
      <th mat-header-cell *matHeaderCellDef> {{'shared.Offerta' | translate}} </th>
      <td mat-cell *matCellDef="let row">
        {{row.offer?.title}}
      </td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef> {{'shared.Descrizione' | translate}} </th>
      <td mat-cell *matCellDef="let row">
        {{row.description}}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <button *ngIf="canAdd" mat-icon-button mat-raised-button color="accent"
          (click)="$event.stopPropagation();addAction()">
          <mat-icon>playlist_add</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let row">
        <button *ngIf="canSelect" mat-raised-button color="primary" (click)="selectAction(row)">{{'shared.Seleziona' | translate}}</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="showAction(row)"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>
