<header mat-dialog-title class="u-flex-full">
  <span>
    {{'shared.Seleziona allegato' | translate}}
  </span>
</header>
<mat-dialog-content>
  <project-attachment-list [projectAttachments]="projectAttachments" [defaultFilters]="defaultFilters"
    [displayedColumns]="displayedColumns" [total]="total" [canSelect]="true" (onSortChange)="sortChange($event)"
    (onPageChange)="pageChange($event)" (onFilterChange)="filtersChange($event)"
    (onSelectProjectAttachment)="selectProjectAttachment($event)"></project-attachment-list>
  <!-- {{ (projectAttachments | async) | json }} -->
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">{{'shared.Chiudi' | translate}}</button>
</mat-dialog-actions>