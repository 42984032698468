<header mat-dialog-title class="u-flex-full">
  <span>
    <span *ngIf="actionAttachment">{{'shared.Modifica allegato' | translate}} : {{actionAttachment.name}}</span>
    <span *ngIf="!actionAttachment">{{'shared.Nuovo allegato' | translate}}</span>
  </span>
  <span *ngIf="actionAttachment">
    <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
      <button mat-menu-item (click)="deleteActionAttachment()">
        <mat-icon>delete</mat-icon>
        {{'shared.Elimina' | translate}}
      </button>
    </mat-menu>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="actionAttachmentForm" novalidate>
    <div class="u-flex-full">

      <mat-form-field class="u-full-width">
        <mat-label>{{'shared.Nome' | translate}}</mat-label>
        <input matInput formControlName="name">
        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
      </mat-form-field>
    </div>
    <div class="u-flex-full" *ngIf="!actionAttachment">
      <mat-form-field class="u-full-width">
        <!-- <ngx-mat-file-input formControlName="file" placeholder="File">
        </ngx-mat-file-input>
        <mat-icon matSuffix color="primary">folder</mat-icon> -->
        <mat-label>{{'shared.File' | translate}}</mat-label>
        <app-attachment-upload formControlName="file" [attachment]="actionAttachment?.file"
          [attachmentName]="actionAttachment?.name"
          [attachmentPath]="actionAttachment?.filePath"></app-attachment-upload>
        <mat-error>{{'shared.File non valido' | translate}}</mat-error>
      </mat-form-field>
    </div>
  </form>
  <!-- {{actionAttachmentForm.value | json}} -->

</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()"
      [disabled]="actionAttachmentForm.pristine">{{'shared.Reset' | translate}}</button>
    <button mat-raised-button color="primary" (click)="save()"
      [disabled]="actionAttachmentForm.pristine || !actionAttachmentForm.valid">{{'shared.Salva' | translate}}</button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">{{'shared.Chiudi' | translate}}</button>
</mat-dialog-actions>