<mat-card class="u-center-text" *ngIf="!(defaultValues | async)">
  <h4>{{'default-values.Nessun valore predefinito trovato' | translate}}</h4>
  <button *ngIf="canAdd" mat-raised-button (click)="addDefaultValues()">
    {{'shared.Aggiungi' | translate}}
  </button>
</mat-card>
<div class="table-container" [hidden]="!(defaultValues | async)">
  <table mat-table matSort [dataSource]="defaultValues">
    <ng-container matColumnDef="bonusClient">
      <th mat-header-cell *matHeaderCellDef>{{'shared.Bonus Cliente' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        {{ row.bonusClient }}
      </td>
    </ng-container>
    <ng-container matColumnDef="bonusSales">
      <th mat-header-cell *matHeaderCellDef>{{'default-values.Bonus Vendite' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        {{ row.bonusSales }}
      </td>
    </ng-container>
    <ng-container matColumnDef="bonusProject">
      <th mat-header-cell *matHeaderCellDef>{{'default-values.Bonus Progetto' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        {{ row.bonusProject }}
      </td>
    </ng-container>
    <ng-container matColumnDef="advisoryFee">
      <th mat-header-cell *matHeaderCellDef>{{'default-values.Advisory fee' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        {{ row.advisoryFee }}
      </td>
    </ng-container>
    <ng-container matColumnDef="kmCost">
      <th mat-header-cell *matHeaderCellDef>{{'shared.Costo € / km' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        {{ row.kmCost }}
      </td>
    </ng-container>
    <ng-container matColumnDef="currency">
      <th mat-header-cell *matHeaderCellDef>{{'shared.Valuta' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        {{ row.currency.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="canEdit">
          <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu
            x-position="before"
            overlapTrigger="false"
            #actionMenu="matMenu"
          >
            <button mat-menu-item (click)="editDefaultValues(row)">
              <mat-icon>create</mat-icon>
              {{'shared.Modifica' | translate}}
            </button>
          </mat-menu>
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageSizeOptions[0]"
    [length]="total | async"
    showFirstLastButtons
  >
  </mat-paginator>
</div>
