import { createSelector } from '@ngrx/store';

import { selectProjectState } from '../reducers';
import { ProjectState } from '../reducers/project.reducer';

// Project Selectors

export const getProjectsTableState = createSelector(
  selectProjectState,
  (state: ProjectState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getProjects = createSelector(
  selectProjectState,
  (state: ProjectState) => state.list
);

export const getTotalProjects = createSelector(
  getProjectsTableState,
  (tableState) => tableState.total
);

export const getProjectsCurrentPage = createSelector(
  getProjectsTableState,
  (tableState) => tableState.currentPage
);

export const getProjectsPerPage = createSelector(
  getProjectsTableState,
  (tableState) => tableState.perPage
);

export const getProjectsOrder = createSelector(
  getProjectsTableState,
  (tableState) => tableState.order
);

export const getProjectsDirection = createSelector(
  getProjectsTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getProjectsTableState,
  (tableState) => tableState.filters
);

export const getPageSize = createSelector(
  getProjectsTableState,
  (tableState) => tableState.perPage
);

export const getPage = createSelector(
  getProjectsTableState,
  (tableState) => tableState.currentPage
);

export const getOrder = createSelector(
  getProjectsTableState,
  (tableState) => tableState.order
);

export const getDirection = createSelector(
  getProjectsTableState,
  (tableState) => tableState.direction
);

export const getProjectDialogId = createSelector(
  selectProjectState,
  (state: ProjectState) => state.dialogId
);
export const getBonusPaymentDialogId = createSelector(
  selectProjectState,
  (state: ProjectState) => state.bonusPaymentDialogId
);

export const getSelectionDialogId = createSelector(
  selectProjectState,
  (state: ProjectState) => state.selectionDialogId
);

export const getCurrentProject = createSelector(
  selectProjectState,
  (state: ProjectState) => state.currentProject
);
export const getProjectId = createSelector(
  selectProjectState,
  (state: ProjectState) => state.projectId
);
export const getCurrentFormControlName = createSelector(
  selectProjectState,
  (state: ProjectState) => state.currentFormControlName
);
