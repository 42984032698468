import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { ActionAttachment } from 'src/app/commons/models/action-attachment.model';
import { Action } from 'src/app/commons/models/action.model';
import { AlertService } from 'src/app/commons/services/alert.service';
import * as ActionAttachmentActions from 'src/app/store/actions/action-attachment.actions';
import { AppState } from 'src/app/store/reducers';
import * as ActivitiesSelectors from 'src/app/store/selectors/action.selectors';
import { map, takeUntil } from 'rxjs/operators';
import * as BusinessOpportunitySelectors from 'src/app/store/selectors/business-opportunity.selectors';
import { BusinessOpportunity } from 'src/app/commons/models/business-opportunity.model';
import { TranslateService } from '@ngx-translate/core';

// Implement file upload
@Component({
  selector: 'app-action-attachment-edit',
  templateUrl: './action-attachment-edit.component.html',
  styleUrls: ['./action-attachment-edit.component.scss']
})
export class ActionAttachmentEditComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  private _actionAttachment: ActionAttachment;
  action: Action;
  actionId: number;
  businessOpportunity: BusinessOpportunity


  currentActionAttachment: ActionAttachment;

  actionAttachmentForm: FormGroup;
  constructor(private store$: Store<AppState>, private alertService: AlertService, private fb: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private translateService: TranslateService) {
    this.store$.pipe(select(BusinessOpportunitySelectors.getBusinessOpportunity), takeUntil(this.unsubscribe$), map(dto => new BusinessOpportunity(dto))).subscribe(
      bo => {
        this.businessOpportunity = bo;       
      }
    )
  }

  ngOnInit() {
    if (this.data) {
      this.actionAttachment = this.data.actionAttachment;
      this.actionId = this.data.actionId;
      this.action = this.businessOpportunity.actions.find(action => action.id == this.actionId);
      
    }
    this._createForm();
    this.ngOnChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private _createForm() {
    let group = {
      action: ['', Validators.required],
      name: ['', Validators.required],
      file: ['', Validators.required],
    }
    this.actionAttachmentForm = this.fb.group(group);
    this.actionAttachmentForm.get('file').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
      if (data && !this.actionAttachment) {
        if(data._fileNames) {
        let fileName = data._fileNames.split('.')[0];
        this.actionAttachmentForm.patchValue({name : fileName })
      } else {
        this.actionAttachmentForm.patchValue({name : "" })
      }
    }
    })
  }

  private ngOnChanges() {
    if (this.actionAttachmentForm) {
      this.actionAttachmentForm.reset();
      this._initFormValues(this.actionAttachment);
    }

  }

  private _initFormValues(actionAttachment?: ActionAttachment) {
    if (actionAttachment) {
      this.actionAttachmentForm.patchValue({
        action: actionAttachment.action,
        name: actionAttachment.name,
        file: actionAttachment.file,
      });
    } else {
      this.actionAttachmentForm.patchValue({
        action: this.action,
      });
    }
  }

  get actionAttachment(): ActionAttachment {
    return this._actionAttachment;
  }

  set actionAttachment(actionAttachment: ActionAttachment) {
    this._actionAttachment = actionAttachment;
    this.ngOnChanges();
  }

  private _prepareSaveActionAttachment(): ActionAttachment {
    let savingActionAttachment: ActionAttachment = ActionAttachment.fromFormGroup(this.actionAttachmentForm, this.actionAttachment);
    return savingActionAttachment;
  }

  save() {
    let unsavedEntity = this._prepareSaveActionAttachment();
    this.store$.dispatch(ActionAttachmentActions.saveActionAttachment({ actionAttachment: unsavedEntity }));
  }

  close() {
    if (this.actionAttachmentForm.pristine) {
      this.store$.dispatch(ActionAttachmentActions.closeActionAttachmentDialog())
    } else {
      this.alertService
        .showConfirmDialog(
          `${this.translateService.instant('shared.Chiudi')}`,
          `${this.translateService.instant('shared.Ci sono modifiche non salvate. Sei sicuro di voler chiudere?')}`
        )
        .subscribe(result => {
          if (result) {
            this.store$.dispatch(ActionAttachmentActions.closeActionAttachmentDialog())
          }
        });
    }
  }

  deleteActionAttachment() {
    if (this.actionAttachment) {
      this.store$.dispatch(ActionAttachmentActions.deleteActionAttachment({ actionAttachment: this.actionAttachment.toDTO() }));
    }
  }

  revert() {
    this.ngOnChanges();
  }
}
