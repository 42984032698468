import { state, style, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';

import { CostLine, CostLineFilters } from '../../../commons/models/cost-line.model';
import { PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';

export type CostLineSelectionMode = 'accountable' | 'not_accountable';
export type CostLinesColumn = "date" | "description" | "amount" | "project" | "client" | "type" | "accountable" | "invoice" | "invoicePaymentDate" | "selected" | "user" | "actions";

@Component({
  selector: 'cost-line-list',
  templateUrl: './cost-line-list.component.html',
  styleUrls: ['./cost-line-list.component.scss'],
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({ height: "0px", minHeight: "0", display: "none" })
      ),
      state("expanded", style({ height: "*" })),
      // transition(
      //   "expanded <=> collapsed",
      //   animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      // ),
    ]),
  ],
})
export class CostLineListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  costLines: CostLine[];
  @Input()
  selectedCostLines: CostLine[];
  @Input()
  selectedCostLinesCurrentPage: CostLine[];
  @Input()
  selectionMode?: CostLineSelectionMode;
  @Input()
  idAdmin: boolean;

  @Input()
  defaultFilters: CostLineFilters;

  pageSizeOptions = PAGE_SIZE_OPTIONS;
  expandedElement: CostLine | null;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: CostLinesColumn[] = ["selected", "user", "date", "amount", "project", "client", "description", "type", "accountable", "invoice", "invoicePaymentDate", "actions"];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<CostLineFilters> = new EventEmitter<CostLineFilters>();

  @Output()
  onSelectCostLine: EventEmitter<CostLine> = new EventEmitter<CostLine>();
  @Output()
  onAddCostLine: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditCostLine: EventEmitter<CostLine> = new EventEmitter<CostLine>();
  @Output()
  onShowCostLine: EventEmitter<CostLine> = new EventEmitter<CostLine>();
  @Output()
  onAddSelectedCostLine: EventEmitter<CostLine> = new EventEmitter<CostLine>();
  @Output()
  onRemoveSelectedCostLine: EventEmitter<CostLine> = new EventEmitter<CostLine>();
  @Output()
  onAddSelectedInvoicedCostLine: EventEmitter<CostLine> = new EventEmitter<CostLine>();
  @Output()
  onRemoveSelectedInvoicedCostLine: EventEmitter<CostLine> = new EventEmitter<CostLine>();
  @Output()
  onInsertPayment: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  onInsertInvoice: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  onExportCostLines: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  onMultipleAccountable: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  onAccountable: EventEmitter<{ costLine, cancel }> = new EventEmitter<{ costLine, cancel }>();

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  isSelected(costLine: CostLine): boolean {
    return this.selectedCostLines.map(obj => obj.id).includes(costLine.id)
  }

  isSelectable(costLine: CostLine): boolean {
    if (this.selectionMode === "accountable") {
      return !costLine.invoiceDate && !costLine.invoiceNumber && !costLine.invoicePaymentDate;
    }
    if (this.selectionMode === "not_accountable") {
      return !costLine.accountable && !costLine.paidByCompany;
    }
    // if(costLine.payable) {
    //   if (!this.idAdmin) {
    //     return !costLine.invoicePaymentDate;
    //   }
    // }
    // return false;
    return costLine.accountable;
  }

  tooltip(costLine: CostLine): string {
    if (this.selectionMode !== "not_accountable") {
      if (costLine.invoicePaymentDate) {
        return 'Fattura già pagata al professionista'
      }
      if (costLine.invoiceDate || costLine.invoiceNumber) {
        return 'Fattura già emessa dal professionista'
      }
    }
    if (costLine.paidByCompany) {
      return `Riga pagata dall'Azienda`;
    }
    return !costLine.accountable ? 'Riga non pagabile' : ''
  }

  addCostLine() {
    this.onAddCostLine.emit();
  }

  addSelectedCostLine(costLine: CostLine) {
    if (this.isSelectable(costLine)) {
      this.onAddSelectedCostLine.emit(costLine)
    }
  }

  removeSelectedCostLine(costLine: CostLine) {
    this.onRemoveSelectedCostLine.emit(costLine)
  }

  toggleSelectedCostLine(costLine: CostLine) {
    if (this.isSelected(costLine)) {
      this.removeSelectedCostLine(costLine);
    } else {
      this.addSelectedCostLine(costLine);
    }
  }


  editCostLine(costLine: CostLine) {
    this.onEditCostLine.emit(costLine);
  }

  selectCostLine(costLine: CostLine) {
    this.onSelectCostLine.emit(costLine);
  }

  onFilter(filters: CostLineFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }

  showCostLine(activityList: CostLine) {
    this.onShowCostLine.emit(activityList);
  }

  insertPayment() {
    this.onInsertPayment.emit();
  }
  insertInvoice() {
    this.onInsertInvoice.emit();
  }

  exportCostLines() {
    this.onExportCostLines.emit();
  }

  accountable(costLine: CostLine, cancel: boolean = false) {
    this.onAccountable.emit({ costLine, cancel });
  }

  isAllSelected() {
    return this.selectedCostLinesCurrentPage.length === this.costLines.filter(cl => this.isSelectable(cl)).length
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selectedCostLinesCurrentPage.forEach(cl => this.removeSelectedCostLine(cl));
    } else {
      this.costLines.filter(cl => this.isSelectable(cl)).forEach(cl => {
        this.addSelectedCostLine(cl);
      });
    }
  }

  get selectedInvoicedCostLines(): CostLine[] {
    return this.selectedCostLines.filter(cl => cl.accountable && !!cl.invoiceDate);
  }

  get selectedInvoicedCostLinesCurrentPage(): CostLine[] {
    return this.selectedCostLinesCurrentPage.filter(cl => cl.accountable && !!cl.invoiceDate);
  }

  get selectedInvoiceableCostLines(): CostLine[] {
    return this.selectedCostLines.filter(cl => cl.accountable && !cl.invoiceDate);
  }

  get selectedInvoiceableCostLinesCurrentPage(): CostLine[] {
    return this.selectedCostLinesCurrentPage.filter(cl => cl.accountable && !cl.invoiceDate);
  }

  get totalActivities() {
    return this.selectedCostLines.filter(c => c.type == 'Attività').map(c => c.amount).reduce((s, v) => s + v, 0);
  }
  get totalExpenses() {
    return this.selectedCostLines.filter(c => c.type == 'Spesa').map(c => c.amount).reduce((s, v) => s + v, 0);
  }

  get totalBonuses() {
    return this.selectedCostLines.filter(c => c.type == 'Bonus').map(c => c.amount).reduce((s, v) => s + v, 0);
  }
}
