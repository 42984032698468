<app-offer-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters">
</app-offer-filters>
<mat-card class="u-center-text" *ngIf="!(offers | async)?.length">
  <h4>{{'offers.Nessuna offerta trovata' | translate}}</h4>
  <button *ngIf="canAdd" mat-raised-button (click)="addOffer()">
    {{'shared.Aggiungi' | translate}}
  </button>
</mat-card>
<div class="table-container" [hidden]="!(offers | async)?.length">
  <table mat-table matSort [dataSource]="offers">
    <ng-container matColumnDef="version">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'offers.Versione' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        {{ row.version }}
      </td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'shared.Titolo' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        {{ row.title }}
      </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'shared.Data' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        {{ row.date | dateFormat }}
      </td>
    </ng-container>

    <ng-container matColumnDef="businessOpportunity">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{'shared.Business Opportunity' | translate}}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.businessOpportunity?.title }}
      </td>
    </ng-container>

    <ng-container matColumnDef="expenseType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="expense_type">
        {{'offers.Tipologia di spesa' | translate}}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.expenseType }}
      </td>
    </ng-container>

    <ng-container matColumnDef="totalAmount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="total_amount" class="text-right">
        {{'offers.Valore totale' | translate}}
      </th>
      <td mat-cell *matCellDef="let row" class="text-right no-wrap">{{ row.totalAmount | customCurrency }}&nbsp;€</td>
    </ng-container>

    <ng-container matColumnDef="probability">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'shared.Probabilità' | translate}}</th>
      <td mat-cell *matCellDef="let row">{{ row.probability }} %</td>
    </ng-container>

    <ng-container matColumnDef="kmCost">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="km_cost">
        {{'shared.Costo € / km' | translate}}
      </th>
      <td mat-cell *matCellDef="let row" class=" no-wrap">{{ row.kmCost | customCurrency }}&nbsp;€</td>
    </ng-container>

    <ng-container matColumnDef="project">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'shared.Commessa' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        {{ row.project?.identifier }}
      </td>
    </ng-container>

    <ng-container matColumnDef="notes">
      <th mat-header-cell *matHeaderCellDef>{{'shared.Note' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        <mat-icon *ngIf="row.notes" [matTooltip]="row.notes">description</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <button *ngIf="canAdd" mat-icon-button mat-raised-button color="accent"
          (click)="$event.stopPropagation(); addOffer()">
          <mat-icon>playlist_add</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="canEdit">
          <button mat-icon-button [mat-menu-trigger-for]="actionMenu" (click)="$event.stopPropagation()">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
            <button mat-menu-item (click)="editOffer(row)">
              <mat-icon>create</mat-icon>
              {{'shared.Modifica' | translate}}
            </button>
            <button mat-menu-item (click)="duplicateOffer(row)">
              <mat-icon>content_copy</mat-icon>
              {{'offers.Duplicate' | translate}}
            </button>
          </mat-menu>
        </ng-container>
        <button *ngIf="canSelect" mat-raised-button color="primary" (click)="selectOffer(row)">
          {{'shared.Seleziona' | translate}}
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>