<app-project-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters"
  [showClientFilter]="showClientFilter" [showPromoFilter]="showPromoFilter">
</app-project-filters>
<mat-card class="u-center-text" *ngIf="!(projects | async).length">
  <h4>Nessuna commessa trovata</h4>
  <button *ngIf="canAdd" mat-raised-button (click)="addProject()">
    Aggiungi
  </button>
</mat-card>
<div class="table-container" [hidden]="!(projects | async).length">
  <table mat-table matSort [dataSource]="projects" multiTemplateDataRows matSortDisableClear matSortActive="identifier"
    matSortDirection="desc">
    <ng-container matColumnDef="identifier" sticky>
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Identificativo</th>
      <td mat-cell *matCellDef="let row">
        {{ row.identifier }}
      </td>
    </ng-container>

    <ng-container matColumnDef="client">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Cliente</th>
      <td mat-cell *matCellDef="let row">
        {{ row.client?.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Descrizione</th>
      <td mat-cell *matCellDef="let row">
        {{ row.description }}
        <span>
          <span *ngIf="row.closed" class="badge">
            Chiusa
          </span>
          <span *ngIf="row.deletedAt" class="badge badge--error">
            Archiviata
          </span>
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="professionals">
      <th mat-header-cell *matHeaderCellDef>Professionisti</th>
      <td mat-cell *matCellDef="let row">
        <span *ngFor="let professional of row.professionals; let i = index">{{ professional.user?.fullName
          }}<span *ngIf="i < row.professionals.length - 1">,&nbsp;</span></span>
      </td>
    </ng-container>
    <ng-container matColumnDef="hours">
      <th mat-header-cell *matHeaderCellDef>Contatore ore</th>
      <td mat-cell *matCellDef="let row">
        <div class="no-wrap">
          Budget ore - {{ row.totalBudgetHours | customCurrency : "1.0-0" }}
        </div>
        <div class="no-wrap">
          Ore consumate - {{ row.activityHours | customCurrency : "1.0-0" }}
        </div>
        <div class="no-wrap">
          Ore fatturate -
          {{ row.billedActivityHours | customCurrency : "1.0-0" }}
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="headProject">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Capo commessa</th>
      <td mat-cell *matCellDef="let row">
        <span *ngFor="let professional of row.professionals"><span *ngIf="professional?.headProject">{{
            professional.user?.name }}
            {{ professional.user?.lastname }}</span>
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="start">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Data inizio</th>
      <td mat-cell *matCellDef="let row">
        {{ row.start | date : "dd/MM/yyyy" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="end">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Data fine</th>
      <td mat-cell *matCellDef="let row">
        {{ row.end | date : "dd/MM/yyyy" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions" stickyEnd>
      <th mat-header-cell *matHeaderCellDef>
        <button *ngIf="canAdd" mat-icon-button mat-raised-button color="accent"
          (click)="$event.stopPropagation(); addProject()">
          <mat-icon>playlist_add</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let row">
        <!-- <ng-container>
          <button mat-icon-button [mat-menu-trigger-for]="actionMenu" (click)="$event.stopPropagation()">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
            <button mat-menu-item (click)="editProject(row)">
              <mat-icon>create</mat-icon>
              Modifica
            </button>
          </mat-menu>
        </ng-container> -->
        <button *ngIf="canSelect" mat-raised-button color="primary" (click)="selectProject(row)"
          [disabled]="row.deletedAt || (!canSelectClosed && row.closed)"
          [matTooltip]="row.deletedAt || (!canSelectClosed && row.closed) ? 'Le commesse chiuse/archiviate non possono essere selezionate' : null">
          Seleziona
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="showProject(row)"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>