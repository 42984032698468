import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { map, Observable, Subject, takeUntil } from 'rxjs';
import { Bonus } from 'src/app/commons/models/bonus.model';
import { BonusLine, BonusLineFilters } from 'src/app/commons/models/bonus_line.model';
import { AlertService } from 'src/app/commons/services/alert.service';
import { PAGE_SIZE_OPTIONS } from 'src/app/helpers/table.helper';
import { AppState } from 'src/app/store/reducers';

import * as BonusLineActions from '../../../../store/actions/bonus-line.actions';
import * as BonusActions from '../../../../store/actions/bonus.actions';
import * as BonusLineSelectors from '../../../../store/selectors/bonus-line.selectors';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-bonus-lines',
  templateUrl: './bonus-lines.component.html',
  styleUrls: ['./bonus-lines.component.scss']
})
export class BonusLinesComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;


  private unsubscribe$ = new Subject<void>();
  bonusLines: BonusLine[];
  pageSizeOptions = PAGE_SIZE_OPTIONS;
  filters: Observable<BonusLineFilters>;
  bonus: Bonus;
  defaultFilters: BonusLineFilters;
  total: Observable<number>;


  private _includes: string[] = [
    "user",
    "project",
    "project.client",
    "cost_line",
    "bonus",
  ];


  showCostLineColumn: boolean = true;
  showProjectColumn: boolean = true;


  constructor(
    private store$: Store<AppState>,
    private alertService: AlertService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService

  ) {
    this.store$.pipe(select(BonusLineSelectors.getBonusLines), takeUntil(this.unsubscribe$), map(dtos => dtos ? dtos.map(dto => new BonusLine(dto)) : null)).subscribe(bonusLines => this.bonusLines = bonusLines);
    this.total = this.store$.pipe(select(BonusLineSelectors.getTotalBonusLines), takeUntil(this.unsubscribe$));
    this.filters = this.store$.pipe(select(BonusLineSelectors.getFilters), takeUntil(this.unsubscribe$));
  }

  ngOnInit() {
    if (this.data) {
      this.bonus = this.data.bonus.bonus;
      this.defaultFilters = {
        bonus: this.bonus
      };
    }
    this.load();
  }

  load() {
    this.store$.dispatch(BonusLineActions.loadBonusLines(
      { page: 1, perPage: this.pageSizeOptions[0], filters: this.defaultFilters, includes: this._includes }));
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  sortChange(sort: Sort) {
    this.store$.dispatch(BonusLineActions.changeSort({ order: sort.active, direction: sort.direction }));
  }

  pageChange(pageEvent: PageEvent) {
    this.store$.dispatch(BonusLineActions.changePage({ page: pageEvent.pageIndex + 1, size: pageEvent.pageSize }))
  }

  filtersChange(filters: BonusLineFilters) {
    this.store$.dispatch(BonusLineActions.changeFilters({ filters }));
  }

  exportBonusLines() {
    this.store$.dispatch(BonusLineActions.exportRecords({ filters: this.defaultFilters }))
  }

  cancelBonusDialog() {
    const notCancellable = this.bonusLines?.some(bonusLine => !!bonusLine.costLine);
    if (notCancellable) {
      this.alertService.showErrorDialog(`${this.translateService.instant('bonuses.Cannot cancel R&D generation')}`, `${this.translateService.instant('bonuses.The R&D for the selected year has already been unlocked, the cost lines relating to this R&D must be deleted to proceed with the cancellation')}`).subscribe()
    } else {
      this.store$.dispatch(BonusActions.cancelBonus({ year: this.bonus.year }));
    }
  }

  close() {
    this.store$.dispatch(BonusActions.closeBonusLinesDialog())
  }




}
