import { saveAs } from 'file-saver';

/**
    * Method is use to download file.
    * @param data - Array Buffer data
    * @param type - type of the document.
    */
export function downLoadFile(data: any, filename: string = 'export', type = 'text/csv',) {
    let blob = new Blob([data], { type: type });
    saveAs(blob, filename + '.' + type.split('/')[1]);
}
