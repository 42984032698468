import {Component, Inject, OnInit, Optional} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {select, Store} from '@ngrx/store';
import {Observable, Subject} from 'rxjs';
import {filter, map, startWith, takeUntil} from 'rxjs/operators';
import {ClientBonus} from 'src/app/commons/models/client-bonus.model';
import {Client} from 'src/app/commons/models/client.model';
import {NationDTO} from 'src/app/commons/models/nation.model';
import {AlertService} from 'src/app/commons/services/alert.service';
import {PivaValidator} from 'src/app/commons/validators/piva.validator';
import * as ClientActions from 'src/app/store/actions/client.actions';
import {AppState} from 'src/app/store/reducers';
import * as DefaultValuesSelectors from 'src/app/store/selectors/default-values.selectors';

import {DefaultValues} from './../../../../commons/models/default-values.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-client-edit',
  templateUrl: './client-edit.component.html',
  styleUrls: ['./client-edit.component.scss'],
})
export class ClientEditComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  private _client: Client;
  currentClient: Client;
  defaults: DefaultValues;

  clientForm: FormGroup;

  nations: NationDTO[] = [];
  regions: string[] = [];
  filteredRegions$: Observable<string[]>;
  filteredNations: Observable<NationDTO[]>;

  constructor(
    private store$: Store<AppState>,
    private alertService: AlertService,
    private fb: FormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService
  ) {
    this.store$
      .pipe(
        select(DefaultValuesSelectors.getDefaultValues),
        takeUntil(this.unsubscribe$),
        map((dto) => (dto ? new DefaultValues(dto) : null))
      )
      .subscribe((defaults) => {
        this.defaults = defaults;
      });
    this.store$
      .pipe(
        select(DefaultValuesSelectors.getNations),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((nations) => {
        this.nations = nations;
      });

    this.store$
      .pipe(
        select(DefaultValuesSelectors.getRegions),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((regions) => {
        this.regions = regions;
      });
  }

  ngOnInit() {
    if (this.data) {
      this.client = this.data.client;
    }
    this._createForm();

    this.filteredNations = this.clientForm.get('country').valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || ''))
    );

    this.clientForm.get('country').valueChanges.subscribe((country) => {
      this.clientForm.get('countryCode').reset();
      const code = this.nations.find((nation) => nation.name === country)?.code;
      this.clientForm.get('countryCode').patchValue(code);
      this.clientForm.get('VAT').clearValidators();
      if (code == 'IT') {
        this.clientForm.get('VAT').addValidators([PivaValidator]);
      }
      this.clientForm.get('VAT').updateValueAndValidity();
    });

    this.filteredRegions$ = this.clientForm.get('region').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterRegion(value))
      );

    this.ngOnChanges();
  }

  private _filterRegion(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.regions.filter((region) =>
      region.toLowerCase().includes(filterValue)
    );
  }

  private _filter(value: string): NationDTO[] {
    const filterValue = value.toLowerCase();

    return this.nations.filter((nation) =>
      nation.name.toLowerCase().includes(filterValue)
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private _createForm() {
    let group = {
      name: ['', Validators.required],
      companyName: ['', Validators.required],
      address: [],
      city: [],
      province: [],
      region: [],
      CAP: [],
      country: [],
      VAT: ['', PivaValidator],
      identifier: [],
      PEC: [
        '',
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ],
      email: [
        '',
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ],
      adminEmail: [
        '',
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ],
      contacts: [],
      clientLeaders: [],
      payment: [],
      currency: [],
      recipientCode: ['', [Validators.pattern('[a-zA-Z0-9\b]{7}')]],
      countryCode: ['', [Validators.pattern('^[a-zA-Z]{2}')]],
      reference: ['', [Validators.pattern('[0-9]*-[0-9]*')]],
      referenceDate: [],
      bonusClient: ['', Validators.required],
      note: [],
      clientBonuses: [],
      ivaTypology: ['', Validators.required],
      bankAccount: [],
    };
    this.clientForm = this.fb.group(group);
    this.clientForm.get('countryCode').disable();
    this.clientForm
      .get('clientBonuses')
      .valueChanges.pipe(
        takeUntil(this.unsubscribe$),
        filter((clientBonuses) => !!clientBonuses)
      )
      .subscribe((clientBonuses) => {
        if (clientBonuses.length > 0) {
          let totalBonusClient: number = 0;
          clientBonuses.forEach((clientBonus: ClientBonus) => {
            if (clientBonus && clientBonus.percentage) {
              totalBonusClient += clientBonus.percentage;
            }
          });
          if (totalBonusClient != 100) {
            this.clientForm.get('clientBonuses').setErrors({incorrect: true});
          } else {
            this.clientForm.get('clientBonuses').setErrors(null);
          }
        }
      });
  }

  get totalBonusClient(): number {
    let total: number = 0;
    this.clientForm
      .get('clientBonuses')
      .value.forEach((element: ClientBonus) => {
        total += element.percentage;
      });
    return total;
  }

  private ngOnChanges() {
    if (this.clientForm) {
      this.clientForm.reset();
      if (this.client) {
        this._initFormValues(this.client);
      } else {
        this._initFormValues();
        this.clientForm.markAllAsTouched();
      }
    }
  }

  private _initFormValues(client?: Client) {
    if (client) {
      this.clientForm.patchValue({
        name: client.name,
        companyName: client.companyName,
        address: client.address,
        city: client.city,
        province: client.province,
        region: client.region,
        CAP: client.CAP,
        country: client.country,
        VAT: client.VAT,
        identifier: client.identifier,
        PEC: client.PEC,
        email: client.email,
        adminEmail: client.adminEmail,
        contacts: client.contacts,
        clientLeaders: client.clientLeaders ? client.clientLeaders : [],
        payment: client.payment,
        currency: client.currency || this.defaults?.currency,
        recipientCode: client.recipientCode,
        countryCode: client.countryCode,
        reference: client.reference,
        referenceDate: client.referenceDate,
        bonusClient: client.bonusClient || this.defaults?.bonusClient,
        note: client.note,
        clientBonuses: client.clientBonuses,
        ivaTypology: client.ivaTypology,
        bankAccount: client.bankAccount,
      });
    } else {
      this.clientForm.patchValue({
        bonusClient: this.defaults?.bonusClient,
        currency: this.defaults?.currency,
        country: 'Italy',
        countryCode: 'IT',
        ivaTypology: this.defaults?.ivaTypology
      });
    }
  }

  get client(): Client {
    return this._client;
  }

  set client(client: Client) {
    this._client = client;
    this.ngOnChanges();
  }

  private _prepareSaveClient(): Client {
    let savingClient: Client = Client.fromFormGroup(
      this.clientForm,
      this.client
    );
    return savingClient;
  }

  save() {
    let unsavedEntity = this._prepareSaveClient();
    this.store$.dispatch(ClientActions.saveClient({client: unsavedEntity}));
  }

  close() {
    if (this.clientForm.pristine) {
      this.store$.dispatch(ClientActions.closeClientDialog());
    } else {
      this.alertService
        .showConfirmDialog(
          `${this.translateService.instant('shared.Chiudi')}`,
          `${this.translateService.instant('shared.Ci sono modifiche non salvate. Sei sicuro di voler chiudere?')}`
        )
        .subscribe((result) => {
          if (result) {
            this.store$.dispatch(ClientActions.closeClientDialog());
          }
        });
    }
  }

  deleteClient() {
    if (this.client) {
      this.store$.dispatch(
        ClientActions.deleteClient({client: this.client.toDTO()})
      );
    }
  }

  revert() {
    this.ngOnChanges();
  }
}
