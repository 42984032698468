
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { BankAccountEditComponent } from 'src/app/modules/home/bank-accounts/bank-account-edit/bank-account-edit.component';
import { BankAccountSelectionComponent } from 'src/app/modules/shared/bank-account-selection/bank-account-selection.component';
import { AlertService } from '../../commons/services/alert.service';
import { LaravelBankAccountService } from '../../commons/services/backend/laravel-bank-account.service';
import * as BankAccountActions from '../actions/bank-account.actions';
import { AppState } from '../reducers';
import { getBankAccountDialogId, getBankAccountsTableState, getSelectionDialogId } from '../selectors/bank-account.selectors';
import { TranslateService } from '@ngx-translate/core';


@Injectable()
export class BankAccountEffects {

  error$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BankAccountActions.saveBankAccountFailed),
      tap(({ error }) => {
        if (error) {
          this.alertService.showErrorMessage(`${this.translateService.instant('shared.errore')}`, error);
        }
      })
    ), { dispatch: false }
  );

  loadBankAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BankAccountActions.loadBankAccounts),
      switchMap(({ page, perPage, order, direction, filters, includes }) => {
        return this.bankAccountService.list(page, perPage, order, direction, filters, includes)
          .pipe(
            map(result =>
              BankAccountActions.loadBankAccountsCompleted({ bankAccounts: result.data, currentPage: page, total: result.total, perPage, order, direction, filters, includes })
            ),
            catchError(error => {
              return of(BankAccountActions.loadBankAccountsFailed({ error }))
            })
          )
      })
    )
  );

  changePage = createEffect(() =>
    this.actions$.pipe(
      ofType(BankAccountActions.changePage),
      withLatestFrom(this.store$.select(getBankAccountsTableState)),
      map(([{ page, size }, { total, currentPage, perPage, direction, order, filters, includes }]) => BankAccountActions.loadBankAccounts({ page: page, perPage: size, order, direction, filters, includes }))
    )
  );

  changeSort = createEffect(() =>
    this.actions$.pipe(
      ofType(BankAccountActions.changeSort),
      withLatestFrom(this.store$.select(getBankAccountsTableState)),
      map(([action, { total, currentPage, perPage, direction, order, filters, includes }]) => BankAccountActions.loadBankAccounts({ page: currentPage, perPage: perPage, order: action.order, direction: action.direction, filters, includes }))
    )
  );

  changeFilters = createEffect(() =>
    this.actions$.pipe(
      ofType(BankAccountActions.changeFilters),
      withLatestFrom(this.store$.select(getBankAccountsTableState)),
      map(([{ filters }, { total, currentPage, perPage, direction, order, includes }]) => BankAccountActions.loadBankAccounts({ page: currentPage, perPage: perPage, order, direction, filters, includes }))
    )
  );

  editBankAccount$ = createEffect(() => this.actions$.pipe(
    ofType(BankAccountActions.editBankAccount),
    map(({ bankAccount }) => {
      let dialogRef = this.dialog.open(BankAccountEditComponent, {
        data: {
          bankAccount
        },
        width: '600px',
      });
      return BankAccountActions.bankAccountDialogOpened({ dialogId: dialogRef.id });
    }))
  );

  saveBankAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BankAccountActions.saveBankAccount),
      mergeMap(({ bankAccount }) =>
        this.bankAccountService.upsert(bankAccount.toDTO())
          .pipe(
            map(result =>
              BankAccountActions.saveBankAccountCompleted({ bankAccount: result })
            ),
            catchError(error => of(BankAccountActions.saveBankAccountFailed({ error })))
          )
      )
    )
  );

  onSaveCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BankAccountActions.saveBankAccountCompleted),
      map(action => action.bankAccount),
      tap(bankAccount => this.alertService.showConfirmMessage(`${bankAccount.name} ${this.translateService.instant('shared.salvata con successo')}`)),
      map(() => BankAccountActions.closeBankAccountDialog())
    )
  );


  deleteBankAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BankAccountActions.deleteBankAccount),
      switchMap(({ bankAccount }) =>
        this.alertService.showConfirmDialog(`${this.translateService.instant('shared.Conferma eliminazione')}`, `${this.translateService.instant('bank-accounts.Sei sicuro di voler eliminare il conto corrente')} ${bankAccount.name}?`)
          .pipe(
            mergeMap((confirm) => {
              return confirm ?
                this.bankAccountService.delete(bankAccount.id)
                  .pipe(
                    map(() => BankAccountActions.deleteBankAccountCompleted({ bankAccount })),
                    catchError(error => of(BankAccountActions.deleteBankAccountFailed({ error })))
                  )

                : of(BankAccountActions.deleteBankAccountCancelled());
            })
          )
      )
    )
  );

  onDeleteCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BankAccountActions.deleteBankAccountCompleted),
      tap(({ bankAccount }) => this.alertService.showConfirmMessage(`${this.translateService.instant('bank-accounts.Conto corrente eliminato', {total:bankAccount.name})}`)),
      map(() => BankAccountActions.closeBankAccountDialog())
    )
  );


  closeDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(BankAccountActions.closeBankAccountDialog),
      withLatestFrom(this.store$.select(getBankAccountDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }
      })
    ), { dispatch: false }
  );

  reloadAfterSave = createEffect(() =>
    this.actions$.pipe(
      ofType(BankAccountActions.saveBankAccountCompleted),
      withLatestFrom(this.store$.select(getBankAccountsTableState)),
      map(([_, { currentPage, perPage, direction, order, filters, includes }]) => BankAccountActions.loadBankAccounts({ page: currentPage, perPage, order, direction, filters, includes }))
    )
  );

  reloadAfterDelete = createEffect(() =>
    this.actions$.pipe(
      ofType(BankAccountActions.deleteBankAccountCompleted),
      withLatestFrom(this.store$.select(getBankAccountsTableState)),
      map(([_, { currentPage, perPage, direction, order, filters, includes }]) => BankAccountActions.loadBankAccounts({ page: currentPage, perPage, order, direction, filters, includes }))
    )
  );
  selectBankAccout$ = createEffect(() => this.actions$.pipe(
    ofType(BankAccountActions.selectBankAccount),
    map(({ filters }) => {
      let dialogRef = this.dialog.open(BankAccountSelectionComponent, {
        data: {
          defaultFilters: filters
        }
      });
      return BankAccountActions.selectionDialogOpened({ selectionDialogId: dialogRef.id });
    }))
  );


  closeSelectionDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(BankAccountActions.closeSelectionDialog),
      withLatestFrom(this.store$.select(getSelectionDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }

      })
    ), { dispatch: false }
  );

  bankAccountsSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BankAccountActions.bankAccountSelected),
      map(() => BankAccountActions.closeSelectionDialog())
    ))


  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private bankAccountService: LaravelBankAccountService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private translateService: TranslateService
  ) { }
}
