import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Role, SENIORITIES, Seniority, SEPARATORS, User } from 'src/app/commons/models/user.model';
import { AlertService } from 'src/app/commons/services/alert.service';
import { PivaValidator } from 'src/app/commons/validators/piva.validator';
import * as UserActions from 'src/app/store/actions/user.actions';
import { AppState } from 'src/app/store/reducers';
import * as AuthSelectors from 'src/app/store/selectors/auth.selectors';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  private _user: User;

  currentUser: User;
  roles: Role[];
  seniorities: Seniority[] = SENIORITIES;
  separators: string[] = SEPARATORS;

  userForm: FormGroup;
  constructor(private store$: Store<AppState>, private alertService: AlertService, private fb: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private translateService: TranslateService) {
    this.store$.pipe(select(AuthSelectors.getCurrentUser), takeUntil(this.unsubscribe$), map(dto => dto ? new User(dto) : null)).subscribe(user => this.currentUser = user);
  }

  ngOnInit() {
    if (this.data) {
      this.user = this.data.user;
    }
    this._createForm();
    this.ngOnChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private _createForm() {
    let group = {
      name: ["", Validators.required],
      lastname: ["", Validators.required],
      email: ["", [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      role: ["", Validators.required],
      active: [],
      companyName: [],
      address: [],
      CAP: [],
      city: [],
      province: [],
      country: [],
      phone: [],
      VAT: ["", PivaValidator],
      identifier: [],
      PEC: ["", Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")],
      SDI: [],
      price: [],
      pricePercentage: [],
      seniority: ["", Validators.required],
      currency: ["", Validators.required],
      exportSeparator: [","],
    }
    if (this.user) {

      this.userForm = this.fb.group(group);
    } else {
      group["password"] = ["", Validators.required];
      group["confirmPass"] = ["", Validators.required];
      this.userForm = this.fb.group(group, {
        validator: this.checkIfMatchingPasswords("password", "confirmPass")
      });
    }
  }

  checkIfMatchingPasswords(
    passwordKey: string,
    passwordConfirmationKey: string
  ) {
    return (group: FormGroup) => {
      let passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true });
      } else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }

  private ngOnChanges() {
    if (this.userForm) {
      this.userForm.reset();
      this._initFormValues(this.user);
    }

  }

  private _initFormValues(user: User) {
    this.userForm.markAllAsTouched();
    if (user) {
      this.userForm.patchValue({
        name: user.name,
        lastname: user.lastname,
        email: user.email,
        password: user.password,
        role: user.role,
        active: user.active,
        companyName: user.companyName,
        address: user.address,
        CAP: user.CAP,
        city: user.city,
        province: user.province,
        country: user.country,
        phone: user.phone,
        VAT: user.VAT,
        identifier: user.identifier,
        PEC: user.PEC,
        SDI: user.SDI,
        price: user.price,
        seniority: user.seniority,
        currency: user.currency,
        pricePercentage: user.pricePercentage,
        exportSeparator: user.exportSeparator
      });
    } else {
      this.userForm.patchValue({
        exportSeparator: ","
      });
    }
  }

  get user(): User {
    return this._user;
  }

  set user(user: User) {
    this._user = user;
    this.ngOnChanges();
  }

  private _prepareSaveUser(): User {
    let savingUser: User = User.fromFormGroup(this.userForm, this.user);
    return savingUser;
  }

  save() {
    let unsavedEntity = this._prepareSaveUser();
    this.store$.dispatch(UserActions.saveUser({ user: unsavedEntity }));
  }

  close() {
    if (this.userForm.pristine) {
      this.store$.dispatch(UserActions.closeUserDialog())
    } else {
      this.alertService
        .showConfirmDialog(
          `${this.translateService.instant('shared.Chiudi')}`,
          `${this.translateService.instant('shared.Ci sono modifiche non salvate. Sei sicuro di voler chiudere?')}`
        )
        .subscribe(result => {
          if (result) {
            this.store$.dispatch(UserActions.closeUserDialog())
          }
        });
    }
  }

  deleteUser() {
    if (this.user) {
      this.store$.dispatch(UserActions.deleteUser({ user: this.user.toDTO() }))
    }
  }

  changePassword() {
    if (this.user) {
      this.store$.dispatch(UserActions.changeUserPassword({ user: this.user.toDTO() }));
    }
  }

  revert() {
    this.ngOnChanges();
  }
}
