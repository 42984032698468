import { AbstractControl } from '@angular/forms';

import { Base, BaseDTO } from './base.model';
import { Client } from './client.model';
import { User, UserDTO } from './user.model';


export interface ContactFilters {
  search?: string;
  client?: Client;
}

export interface ContactDTO extends BaseDTO {
  name: string;
  lastname: string;
  email: string;
  phone: string;
  mobile: string;
  fax: string;
  internal_user?: UserDTO;
  internal_user_id?: number;
  note: string;
}
export class Contact extends Base {
  name: string;
  lastname: string;
  email: string;
  phone: string;
  mobile: string;
  fax: string;
  internalUser?: User;
  internalUserId?: number;
  note: string;
  constructor(source: ContactDTO) {
    super(source);
    if (source) {
      this.name = source.name;
      this.lastname = source.lastname;
      this.email = source.email;
      this.phone = source.phone;
      this.mobile = source.mobile;
      this.fax = source.fax;
      this.note = source.note;
      this.internalUserId = source.internal_user_id;
      if (source.internal_user) {
        this.internalUser = new User(source.internal_user);
      }
    }
  }

  toDTO(): ContactDTO {
    let result: ContactDTO = <ContactDTO>super.toDTO();
    result.name = this.name;
    result.lastname = this.lastname;
    result.email = this.email;
    result.phone = this.phone;
    result.mobile = this.mobile;
    result.fax = this.fax;
    result.note = this.note;
    result.internal_user_id = this.internalUserId;
    if (this.internalUser) {
      result.internal_user = this.internalUser.toDTO();
      result.internal_user_id = result.internal_user.id;
    }
    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: Contact) {
    const formModel = formGroup.value;
    let contact: Contact = new Contact(null);
    contact.name = formModel.name;
    contact.lastname = formModel.lastname;
    contact.email = formModel.email;
    contact.phone = formModel.phone;
    contact.mobile = formModel.mobile;
    contact.fax = formModel.fax;
    contact.note = formModel.note;
    contact.internalUser = formModel.internalUser;

    if (original) {
      contact.id = original.id;
    }
    return contact;
  }

}
