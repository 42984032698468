<app-user-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters" [showActiveFilter]="!canSelect">
</app-user-filters>
<mat-card class="u-center-text" *ngIf="!(users | async).length">
  <h4>{{'users.Nessun utente trovato' | translate}}</h4>
  <button *ngIf="canAdd" mat-raised-button (click)="addUser()">{{'shared.Aggiungi' | translate}}</button>
</mat-card>
<div class="table-container" [hidden]="!(users | async).length">
  <table mat-table matSort [dataSource]="users" multiTemplateDataRows matSortActive="lastname" matSortDirection="asc">
    <ng-container matColumnDef="active">
      <th mat-header-cell *matHeaderCellDef>{{'users.Attivo' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        <mat-icon *ngIf="row.active">done_outline</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="lastname">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'shared.Cognome' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        {{ row.lastname }}
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'shared.Nome' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        {{ row.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>{{'shared.Email' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        {{ row.email }}
      </td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef>{{'users.Ruolo' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        {{ ('shared.' + (row.role | userRole)) | translate }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <button *ngIf="canAdd" mat-icon-button mat-raised-button color="accent"
          (click)="$event.stopPropagation(); addUser()">
          <mat-icon matTooltip="{{'shared.Aggiungi' | translate}}">playlist_add</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="canEdit">
          <button mat-icon-button (click)="$event.stopPropagation(); editUser(row)">
            <mat-icon matTooltip="{{'shared.Modifica' | translate}}">create</mat-icon>
          </button>
        </ng-container>
        <button *ngIf="canSelect" mat-raised-button color="primary" (click)="selectUser(row)">
          {{'shared.Seleziona' | translate}}
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="element-detail">
          <div class="element-info">
            <app-detail label="{{('users.Seniority' | translate)}}" [value]="element.seniority"></app-detail>
            <app-detail label="{{('users.Ragione sociale' | translate)}}" [value]="element.companyName"></app-detail>
            <app-detail label="{{('shared.PEC' | translate)}}" [value]="element.PEC"></app-detail>
            <app-detail label="{{('shared.P.IVA' | translate)}}" [value]="element.VAT"></app-detail>
            <app-detail label="{{('users.SDI' | translate)}}" [value]="element.SDI"></app-detail>
            <app-detail label="{{('shared.Cellulare' | translate)}}" [value]="element.phone"></app-detail>
            <app-detail label="{{('shared.Codice fiscale' | translate)}}" [value]="element.identifier"></app-detail>
            <app-detail label="{{('shared.Indirizzo' | translate)}}" [value]="element.fullAddress"></app-detail>
            <app-detail *ngIf="element.pricePercentage && element.price" label="{{('shared.Costo giornaliero') | translate}}"
              [value]="element.price + '%'"></app-detail>
            <app-detail *ngIf="!element.pricePercentage && element.price" label="{{('shared.Costo giornaliero') | translate}}"
              [value]="(element.price | customCurrency) + ' €'"></app-detail>
            <app-detail label="{{('shared.Valuta compensi') | translate}}" [value]="element.currency?.name"></app-detail>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns" class="element-row"
      (click)="expandedElement = expandedElement === element ? null : element"></tr>
    <tr mat-row *matRowDef="let element; columns: ['expandedDetail']"
      [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>