<ng-container [formGroup]="form">
  <ng-container
    *ngFor="let control of form.controls; let index = index"
    [formGroupName]="index"
  >
    <div class="u-full-width risk">
      <button
        mat-icon-button
        mat-raised-button
        color="accent"
        (click)="removeRiskIdentification(index)"
        matTooltip="{{'risks.Rimuovi rischio' | translate}}"
      >
        <mat-icon>delete</mat-icon>
      </button>
      <mat-form-field style="flex: 1">
        <mat-label>{{'risks.Rischio' | translate}}</mat-label>
        <app-risk-field formControlName="risk"></app-risk-field>
        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
      </mat-form-field>
      <mat-form-field>
      <mat-label>{{'shared.Probabilità' | translate}}</mat-label>
        <input
          matInput
          placeholder="{{'shared.Probabilità' | translate}}"
          formControlName="probability"
          type="number"
          min="0"
          max="100"
          forceNumber
        />
        <mat-icon matSuffix>percent</mat-icon>
        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder="{{'risks.Impatto' | translate}}"
          formControlName="impact"
          type="number"
          forceNumber
        />
        <mat-icon matSuffix>euro</mat-icon>
        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{'risks.Risk Owner' | translate}}</mat-label>
        <app-user-field formControlName="riskOwner"></app-user-field>
        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder="{{'risks.Risk actionee' | translate}}"
          formControlName="actionee"
          type="text"
        />
        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
      </mat-form-field>
    </div>
    <!-- <button mat-raised-button (click)="removeProfessional(index)">REMOVE</button> -->
  </ng-container>
</ng-container>
<div class="u-center-text">
  <button
    mat-raised-button
    type="button"
    color="accent"
    (click)="addRiskIdentification()"
    [disabled]="form.invalid"
  >
    <span>{{'risks.Aggiungi rischio' | translate}}</span>
  </button>
</div>
