<app-invoice-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters">
</app-invoice-filters>
<mat-card class="u-center-text" *ngIf="!(invoices | async)?.length">
  <h4>{{'invoices.Nessuna fattura trovata' | translate}}</h4>
  <!-- <button *ngIf="canAdd" mat-raised-button (click)="addInvoice()">Aggiungi</button> -->
</mat-card>
<div class="table-container" [hidden]="!(invoices | async)?.length">
  <table mat-table matSort matSortActive="identifier" matSortDirection="desc" matSortDisableClear
    [dataSource]="invoices" multiTemplateDataRows>
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="identifier">
        {{'shared.Numero fattura' | translate}}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.invoiceIdentifier }}
      </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'shared.Data' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        {{ row.date | dateFormat }}
      </td>
    </ng-container>

    <ng-container matColumnDef="expiration">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'invoices.Scadenza' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        {{ row.expiration | dateFormat }}
      </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right">{{'shared.Importo' | translate}}</th>
      <td mat-cell *matCellDef="let row" class="text-right">
        <strong>{{ row.amount | customCurrency }}&nbsp;€</strong><br />
        {{ row.totalActivities | customCurrency }}&nbsp;€ {{'shared.Attività' | translate}} + {{ row.totalExpenses |
        customCurrency }}&nbsp;€
        {{'shared.Spese' | translate}}
      </td>
    </ng-container>

    <ng-container matColumnDef="activities">
      <th mat-header-cell *matHeaderCellDef class="text-right">{{'shared.Attività' | translate}}</th>
      <td mat-cell *matCellDef="let row" class="text-right">
        {{ row.totalActivities | customCurrency }}&nbsp;€
      </td>
    </ng-container>

    <ng-container matColumnDef="expenses">
      <th mat-header-cell *matHeaderCellDef class="text-right">{{'shared.Spese' | translate}}</th>
      <td mat-cell *matCellDef="let row" class="text-right">
        {{ row.totalExpenses | customCurrency }}&nbsp;€
      </td>
    </ng-container>

    <ng-container matColumnDef="client">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'shared.Cliente' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        {{ row.client?.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="generated">
      <th mat-header-cell *matHeaderCellDef>{{'invoices.Fattura Elettronica' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        <span *ngIf="row.generated" class="badge badge--success">{{'invoices.Generata' | translate}}</span>
        <span *ngIf="!row.generated && row.fattura24Details" class="badge badge--danger"
          matTooltip="{{'invoices.La generazione della fattura elettronica su Fattura24 è andata in errore. Prima di procedere con una nuova generazione verificare su Fattura24' | translate}}">In
          Errore</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="paid">
      <th mat-header-cell *matHeaderCellDef>{{'shared.Pagata' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        <span *ngIf="row.paymentDate" [matTooltip]="row.paymentNotes">
          {{ row.paymentDate | date : "dd/MM/yyyy" }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <button *ngIf="canAdd" mat-icon-button mat-raised-button color="accent"
          (click)="$event.stopPropagation(); exportInvoices()">
          <mat-icon>file_download</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="canEdit">
          <button mat-icon-button [mat-menu-trigger-for]="actionMenu" (click)="$event.stopPropagation()">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
            <ng-container *ngIf="!row.paymentDate">
              <button mat-menu-item (click)="paymentInvoice(row)">
                <mat-icon>paid</mat-icon>
                {{'shared.Pagata' | translate}}
              </button>
              <ng-container *ngIf="!row.feId">
                <button mat-menu-item (click)="generateInvoice(row)">
                  <mat-icon>request_quote</mat-icon>
                  {{'shared.Genera Fattura' | translate}}
                </button>
              </ng-container>
              <button mat-menu-item (click)="cancelInvoice(row)">
                <mat-icon>delete</mat-icon>
                {{'shared.Annulla' | translate}}
              </button>
            </ng-container>
            <ng-container *ngIf="row.paymentDate">
              <button mat-menu-item (click)="cancelPaymentInvoice(row)">
                <mat-icon>money_off</mat-icon>
                {{'invoices.Non Pagata' | translate}}
              </button>
            </ng-container>
            <ng-container>
              <button mat-menu-item (click)="generatePdfExpenses(row)">
                <mat-icon>print</mat-icon>
                {{'invoices.Genera pdf Spese' | translate}}
              </button>
            </ng-container>
            <ng-container>
              <button mat-menu-item (click)="generatePdfActivities(row)">
                <mat-icon>print</mat-icon>
                {{'invoices.Genera pdf Attività' | translate}}
              </button>
            </ng-container>
            <button mat-menu-item (click)="editInvoice(row)">
              <mat-icon>create</mat-icon>
              {{'shared.Modifica' | translate}}
            </button>
          </mat-menu>
        </ng-container>
        <button *ngIf="canSelect" mat-raised-button color="primary" (click)="selectInvoice(row)">
          {{'shared.Seleziona' | translate}}
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="element-detail">
          <div class="element-info">
            <h4 *ngIf="element.paymentDate">
              {{'invoices.Pagata il' | translate}} {{ element.paymentDate | date : "dd/MM/yyyy"
              }}<span *ngIf="element.paymentNotes">: {{ element.paymentNotes }}</span>
            </h4>
            <h4 style="margin-bottom: 16px">{{'shared.Righe di fatturazione' | translate}} : </h4>
            <div *ngIf="element.billingLines">
              <table class="invoice-details">
                <tr>
                  <th>{{'shared.Data' | translate}}</th>
                  <th>{{'shared.Tipologia' | translate}}</th>
                  <th>{{'shared.Importo' | translate}}</th>
                  <th class="full-width">{{'shared.Descrizione' | translate}}</th>
                </tr>
                <tr *ngFor="let billingLine of element.billingLines">
                  <td>{{billingLine.date | date : "dd/MM/yyyy"}}</td>
                  <td>{{billingLine.type}}</td>
                  <td class="text-right">{{billingLine.amount | customCurrency}} &nbsp;€</td>
                  <td class="full-width">{{billingLine.description}}</td>
                </tr>
              </table>
              <div *ngFor="let billingLine of element.billingLines">
                <!-- <app-detail label="Data" [value]='billingLine.date | date : "dd/MM/yyyy"'></app-detail>
                <app-detail label="Tipologia" [value]='billingLine.type'></app-detail>
                <app-detail label="Importo" [value]="(billingLine.amount | customCurrency) + ' €'"></app-detail> -->
                <!-- <app-detail label="Professionista"
                  [value]="expense.professional?.user?.name + ' '+  expense.professional?.user?.lastname "></app-detail>
                <app-detail label="Note" [value]="expense.notes"></app-detail>
                <strong>{{ billingLine.amount | customCurrency }}&nbsp;€</strong><span
                  *ngIf="billingLine.description">&nbsp;{{ billingLine.description }}</span> -->
                <!-- <p *ngIf="billingLine.date">Data: {{billingLine.date |  date : "dd/MM/yyyy"}}</p>
                    <p *ngIf="billingLine.amount">Importo: {{billingLine.amount | customCurrency}}&nbsp;€</p>
                    <p *ngIf="billingLine.description">Descrizione: {{billingLine.description}}</p> -->
                <!--mrosetti: nascosta la funzionalità perché non prevista da analisi, e dovrebbe anche andare a modificare anche il totale della fattura. Da valutare-->
                <button *ngIf="false" mat-icon-button color="primary" (click)="detachBillingLine(billingLine)">
                  <mat-icon matTooltip="{{'shared.Sgancia riga di fatturazione' | translate}}">cancel</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns" class="element-row"
      (click)="expandedElement = expandedElement === element ? null : element"></tr>
    <tr mat-row *matRowDef="let element; columns: ['expandedDetail']"
      [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>
<!-- {{ invoices | async | json }} -->