<header mat-dialog-title class="u-flex-full">
  <span>
    <span *ngIf="bankAccount">{{'bank-accounts.Modifica conto corrente' | translate}} : {{bankAccount.name}}</span>
    <span *ngIf="!bankAccount">{{'bank-accounts.Nuovo conto corrente' | translate}}</span>
  </span>
  <span *ngIf="bankAccount">
    <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
      <button mat-menu-item (click)="deleteBankAccount()">
        <mat-icon>delete</mat-icon>
        {{'shared.Elimina' | translate}}
      </button>
    </mat-menu>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="bankAccountForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'shared.Nome' | translate}}" formControlName="name" type="text">
        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
        <mat-hint>{{'bank-accounts.Il nome deve corrispondere al campo Nome su Fattura24' | translate}}</mat-hint>
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'bank-accounts.IBAN' | translate}}" formControlName="iban" type="text">
        <mat-error>{{'bank-accounts.IBAN non valido' | translate}}</mat-error>
      </mat-form-field>
    </div>
  </form>
  <!-- {{bankAccountForm.value | json}} -->

</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()"
      [disabled]="bankAccountForm.pristine">{{'shared.Reset' | translate}}</button>
    <button mat-raised-button color="primary" (click)="save()"
      [disabled]="bankAccountForm.pristine || !bankAccountForm.valid">{{'shared.Salva' | translate}}</button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">{{'shared.Chiudi' | translate}}</button>
</mat-dialog-actions>