import { state, style, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { User } from 'src/app/commons/models/user.model';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

import { Expense, ExpenseFilters } from '../../../commons/models/expense.model';
import { PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';

export type MultiSelectionMode = 'account' | 'removeAccount' | 'validate' | 'validated';

export type ExpensesColumn =
  | 'multipleSelection'
  | 'id'
  | 'project'
  | 'client'
  | 'refundType'
  | 'user'
  | 'date'
  | 'type'
  | 'amount'
  | 'payer'
  | 'attachment'
  | 'billable'
  | 'payable'
  | 'validated'
  | 'notes'
  | 'actions';

@Component({
  selector: 'expense-list',
  templateUrl: './expense-list.component.html',
  styleUrls: ['./expense-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', display: 'none' })
      ),
      state('expanded', style({ height: '*' })),
    ]),
  ],
})
export class ExpenseListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  expenses: Expense[];

  @Input()
  defaultFilters: ExpenseFilters;

  pageSizeOptions = PAGE_SIZE_OPTIONS;
  expandedElement: Expense | null;
  baseUrl = environment.baseUrl;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: ExpensesColumn[] = [
    'multipleSelection',
    'project',
    'client',
    'refundType',
    'user',
    'date',
    'type',
    'amount',
    'payer',
    'attachment',
    'validated',
    'billable',
    'payable',
    'notes',
    'actions',
  ];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;
  @Input()
  canModify: boolean;
  @Input()
  canDuplicate: boolean;
  @Input()
  canBillable: boolean;
  @Input()
  canPayable: boolean;

  @Input()
  selectedExpenses: Expense[];
  @Input()
  selectedExpensesCurrentPage: Expense[];
  @Input()
  currentUser: User;

  @Input()
  selectionMode?: MultiSelectionMode;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<ExpenseFilters> =
    new EventEmitter<ExpenseFilters>();

  @Output()
  onAccount: EventEmitter<Expense> = new EventEmitter<Expense>();
  @Output()
  onRemoveAccount: EventEmitter<Expense> = new EventEmitter<Expense>();

  @Output()
  onSelectExpense: EventEmitter<Expense> = new EventEmitter<Expense>();
  @Output()
  onAddExpense: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditExpense: EventEmitter<Expense> = new EventEmitter<Expense>();
  @Output()
  onDuplicateExpense: EventEmitter<Expense> = new EventEmitter<Expense>();
  @Output()
  onChangeBillable: EventEmitter<Expense> = new EventEmitter<Expense>();
  @Output()
  onChangePayable: EventEmitter<Expense> = new EventEmitter<Expense>();

  @Output()
  onAddSelectedExpense: EventEmitter<Expense> = new EventEmitter<Expense>();
  @Output()
  onRemoveSelectedExpense: EventEmitter<Expense> = new EventEmitter<Expense>();
  @Output()
  onChangeMultipleBillable: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  onChangeMultiplePayable: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  onValidateExpense: EventEmitter<Expense> = new EventEmitter<Expense>();
  @Output()
  onUnvalidateExpense: EventEmitter<Expense> = new EventEmitter<Expense>();
  @Output()
  onMultipleValidate: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  onMultipleAccount: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private translateService: TranslateService) { }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  addExpense() {
    this.onAddExpense.emit();
  }

  editExpense(expense: Expense) {
    this.onEditExpense.emit(expense);
  }

  duplicateExpense(expense: Expense) {
    this.onDuplicateExpense.emit(expense);
  }

  selectExpense(expense: Expense) {
    this.onSelectExpense.emit(expense);
  }

  onFilter(filters: ExpenseFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }

  changeBillable(expense: Expense) {
    this.onChangeBillable.emit(expense);
  }
  validateExpense(expense: Expense) {
    this.onValidateExpense.emit(expense);
  }

  unvalidateExpense(expense: Expense) {
    this.onUnvalidateExpense.emit(expense);
  }

  changePayable(expense: Expense) {
    this.onChangePayable.emit(expense);
  }

  toggleSelectedExpense(expense: Expense): void {
    if (this.isSelected(expense)) {
      this.removeSelectedExpense(expense);
    } else {
      this.addSelectedExpense(expense);
    }
  }
  isSelected(expense: Expense): boolean {
    return this.selectedExpenses
      .map((selectedExpense) => selectedExpense.id)
      .includes(expense.id);
  }
  private removeSelectedExpense(expense: Expense): void {
    this.onRemoveSelectedExpense.emit(expense);
  }
  private addSelectedExpense(expense: Expense): void {
    if (this.isSelectable(expense)) {
      this.onAddSelectedExpense.emit(expense);
    }
  }

  isSelectable(expense: Expense): boolean {
    if (this.selectionMode === "account") {
      return expense?.accountable;
    }
    if (this.selectionMode === "removeAccount") {
      return expense?.accountableCancellable;
    }
    if (this.selectionMode === "validate") {
      return !expense?.validated;
    }
    if (this.selectionMode === "validated") {
      return expense?.validated;
    }
    return true
  }

  tooltip(expense: Expense): string {
    if (this.selectionMode === "removeAccount") {
      return expense?.getnotAccountableCancellableTooltip(this.translateService);
      }  }

  get isSelectedExpenses(): boolean {
    return this.selectedExpenses.length > 0;
  }

  changeMultipleBillable(billable: boolean) {
    this.onChangeMultipleBillable.emit(billable);
  }
  changeMultiplePayable(payable: boolean) {
    this.onChangeMultiplePayable.emit(payable);
  }
  multipleValidate() {
    this.onMultipleValidate.emit();
  }

  isAllSelected() {
    const numSelected = this.selectedExpensesCurrentPage.length;
    const numRows = this.expenses.filter(a => this.isSelectable(a)).length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selectedExpensesCurrentPage.forEach(a => this.removeSelectedExpense(a)) :
      this.expenses.forEach(a => this.addSelectedExpense(a));
  }
}
