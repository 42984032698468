<header mat-dialog-title class="u-flex-full">
  <span>
    {{'shared.Seleziona attività' | translate}}
  </span>
</header>
<mat-dialog-content>
  <expense-list [expenses]="expenses | async" [defaultFilters]="defaultFilters" [displayedColumns]="displayedColumns"
    [total]="total" [canSelect]="true" (onSortChange)="sortChange($event)" (onPageChange)="pageChange($event)"
    (onFilterChange)="filtersChange($event)" (onSelectExpense)="selectExpense($event)"></expense-list>
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">{{'shared.Chiudi' | translate}}</button>
</mat-dialog-actions>
