<app-billing-line-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters">
</app-billing-line-filters>
<mat-card class="u-center-text" *ngIf="!billingLines.length">
  <h4>Nessuna riga di fatturazione trovata</h4>
  <button *ngIf="canAdd" mat-raised-button (click)="addBillingLine()">
    Aggiungi
  </button>
</mat-card>
<div class="table-container" [hidden]="!billingLines.length">
  <table mat-table matSort [dataSource]="billingLines" multiTemplateDataRows matSortDisableClear matSortActive="date"
    matSortDirection="desc">
    <!-- <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> ID </th>
      <td mat-cell *matCellDef="let row">
        {{row.id}}
      </td>
    </ng-container> -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Data</th>
      <td mat-cell *matCellDef="let row">
        {{ row.date | dateFormat }}
      </td>
    </ng-container>
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Importo</th>
      <td mat-cell *matCellDef="let row" class="text-right no-wrap">
        {{ row.amount | customCurrency }}&nbsp;€
      </td>
    </ng-container>
    <ng-container matColumnDef="project">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Commessa</th>
      <td mat-cell *matCellDef="let row">
        {{ row.project?.identifier }} - {{ row.project?.description }}
      </td>
    </ng-container>
    <ng-container matColumnDef="client">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Cliente</th>
      <td mat-cell *matCellDef="let row">
        {{ row.project?.client?.name }}
      </td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Descrizione</th>
      <td mat-cell *matCellDef="let row">
        {{ row.description }}
      </td>
    </ng-container>
    <!-- <ng-container matColumnDef="activities">
      <th mat-header-cell *matHeaderCellDef> Attività </th>
      <td mat-cell *matCellDef="let row">
        {{row?.activities.join(",")}}
      </td>
    </ng-container>
    <ng-container matColumnDef="expenses">
      <th mat-header-cell *matHeaderCellDef> Spese </th>
      <td mat-cell *matCellDef="let row">
        {{row?.expenses.join(",")}}
      </td>
    </ng-container> -->
    <ng-container matColumnDef="invoiced">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Fattura</th>
      <td mat-cell *matCellDef="let row">
        <span *ngIf="row.invoice">
          {{ row.invoice?.invoiceIdentifier
          }}<mat-icon matTooltip="pagata" *ngIf="row.invoice.paid">done_outline</mat-icon>
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Tipologia</th>
      <td mat-cell *matCellDef="let row">
        {{ row?.type }}
      </td>
    </ng-container>

    <ng-container matColumnDef="selected">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
          [checked]="selectedBillingLines?.length > 0  && isAllSelected()"
          [indeterminate]="selectedBillingLines?.length > 0 && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container *showFor="['admin']">
          <ng-container *ngIf="!row.invoiceId">
            <mat-checkbox color="primary" (change)="toggleSelectedBillingLine(row)" [checked]="isSelected(row)"
              (click)="$event.stopPropagation()">
            </mat-checkbox>
          </ng-container>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <button *ngIf="canAdd" mat-icon-button mat-raised-button color="accent"
          (click)="$event.stopPropagation(); addBillingLine()">
          <mat-icon>playlist_add</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="canEdit">
          <button mat-icon-button (click)="$event.stopPropagation(); editBillingLine(row)">
            <mat-icon matTooltip="Modifica">create</mat-icon>
          </button>
        </ng-container>

        <button *ngIf="canSelect" mat-raised-button color="primary" (click)="selectBillingLine(row)">
          Seleziona
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="element-detail">
          <div class="element-info">
            <div *ngIf="element.activities">
              <table class="details">
                <tr>
                  <th>Data</th>
                  <th style="width: 50px">Ore</th>
                  <th style="width: 150px">Professionista</th>
                  <th style="width: 200px">Budget</th>
                  <th class="full-width">Note</th>
                </tr>
                <tr *ngFor="let activity of element.activities">
                  <td>{{activity.date | date : "dd/MM/yyyy"}}</td>
                  <td style="width: 50px">{{activity.hours}}</td>
                  <td style="width: 150px">{{activity.professional?.user?.name}}
                    {{activity.professional?.user?.lastname}}</td>
                  <td style="width: 200px">{{activity.budget?.name}}</td>
                  <td class="full-width">{{activity.notes}}</td>
                </tr>
              </table>
              <!-- <div *ngFor="let activity of element.activities">
                <app-detail label="Data" [value]='activity.date | date : "dd/MM/yyyy"'></app-detail>
                <app-detail label="Ore" [value]="activity.hours"></app-detail>
                <app-detail label="Professionista"
                  [value]="activity.professional?.user?.name + ' '+  activity.professional?.user?.lastname "></app-detail>
                <app-detail label="Budget" [value]="activity.budget?.name"></app-detail>
                <app-detail label="Note" [value]="activity.notes"></app-detail>
              </div> -->
            </div>
            <div *ngIf="element.expenses">
              <table class="details">
                <tr>
                  <th>Data</th>
                  <th>Tipologia</th>
                  <th style="width: 150px">Importo</th>
                  <th style="width: 150px">Professionista</th>
                  <th class="full-width">Note</th>
                </tr>
                <tr *ngFor="let expense of element.expenses">
                  <td>{{expense.date | date : "dd/MM/yyyy"}}</td>
                  <td>{{expense.type}}</td>
                  <td style="width: 150px" class="text-right no-wrap">{{expense.amount | customCurrency}}&nbsp;€</td>
                  <td style="width: 150px">{{expense.professional?.user?.name}} {{expense.professional?.user?.lastname}}
                  </td>
                  <td class="full-width">{{expense.notes}}</td>
                </tr>
              </table>

              <!-- <app-detail label="Data" [value]='expense.date | date : "dd/MM/yyyy"'></app-detail>
                <app-detail label="Tipologia" [value]="expense.type"></app-detail>
                <app-detail label="Importo" [value]="(expense.amount | customCurrency) + ' €'"></app-detail>
                <app-detail label="Professionista"
                  [value]="expense.professional?.user?.name + ' '+  expense.professional?.user?.lastname "></app-detail>
                <app-detail label="Note" [value]="expense.notes"></app-detail> -->
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns" class="element-row"
      (click)="expandedElement = expandedElement === element ? null : element"></tr>
    <tr mat-row *matRowDef="let element; columns: ['expandedDetail']"
      [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>

<mat-expansion-panel [expanded]="selectedItems" *showFor="['admin']">
  <div class="button-container">
    <button mat-raised-button color="primary" (click)="generateInvoice()">
      Genera fattura
    </button>
  </div>
</mat-expansion-panel>