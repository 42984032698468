<app-currency-filters
  (onFilter)="onFilter($event)"
  [defaultFilters]="defaultFilters"
>
</app-currency-filters>
<mat-card class="u-center-text" *ngIf="!(currencies | async).length">
  <h4>{{'currencies.Nessuna valuta trovata' | translate}}</h4>
  <button *ngIf="canAdd" mat-raised-button (click)="addCurrency()">
    {{'shared.Aggiungi' | translate}}
  </button>
</mat-card>
<div class="table-container" [hidden]="!(currencies | async).length">
  <table mat-table matSort [dataSource]="currencies">
    <ng-container matColumnDef="currencyCode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="currency_code">
        {{'shared.Codice valuta' | translate}}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.currencyCode }}
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'shared.Nome' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        {{ 'currencies.' + row.name  | translate}}
      </td>
    </ng-container>

    <!--
    <ng-container matColumnDef="exchangeRate">
      <th mat-header-cell *matHeaderCellDef> Tasso di cambio </th>
      <td mat-cell *matCellDef="let row">
        {{row.exchangeRate}}
      </td>
    </ng-container>
    -->

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <button
          *ngIf="canAdd"
          mat-icon-button
          mat-raised-button
          color="accent"
          (click)="$event.stopPropagation(); addCurrency()"
        >
          <mat-icon matTooltip="{{'shared.Aggiungi' | translate}}">playlist_add</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="canEdit">
          <button
            mat-menu-item
            (click)="$event.stopPropagation(); editCurrency(row)"
          >
            <mat-icon matTooltip="{{'shared.Modifica' | translate}}">create</mat-icon>
          </button>
        </ng-container>
        <button
          *ngIf="canSelect"
          mat-raised-button
          color="primary"
          (click)="selectCurrency(row)"
        >
          {{'shared.Seleziona' | translate}}
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageSizeOptions[0]"
    [length]="total | async"
    showFirstLastButtons
  >
  </mat-paginator>
</div>
