<mat-chip-list #chipList>
  <mat-chip color="primary" *ngFor="let salesBonus of value; let i = index;" [removable]="true" [selectable]="false"
    (removed)="removeSalesBonus(i)" (click)="editSalesBonus(i)">
    {{salesBonus?.user?.fullName}}: {{salesBonus?.percentage}}%
    <mat-icon matChipRemove>cancel</mat-icon>

  </mat-chip>
  <button type="button" style="margin: 5px" matTooltip="{{'shared.Aggiungi utente bonus sales' | translate}}" (click)="addSalesBonus()"
    mat-icon-button mat-raised-button>
    <mat-icon>add</mat-icon>
  </button>

</mat-chip-list>
<mat-expansion-panel class="mat-elevation-z0" [expanded]="openEdit">
  <app-sales-bonus-edit [salesBonus]="currentSalesBonus" (onClose)="close()" (onSave)="save($event)">
  </app-sales-bonus-edit>
</mat-expansion-panel>

<!-- {{ currentSalesBonus | json }} -->
