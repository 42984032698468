import { getProjectId } from './../selectors/project.selectors';

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
// import { ProfessionalEditComponent } from 'src/app/modules/shared/professional-edit/professional-edit.component';
import { AlertService } from '../../commons/services/alert.service';
import { LaravelProfessionalService } from '../../commons/services/backend/laravel-professional.service';
import * as ProfessionalActions from '../actions/professional.actions';
import * as ProjectActions from '../actions/project.actions';
import { AppState } from '../reducers';
import { getProfessionalDialogId, getProfessionalsTableState, getSelectionDialogId } from '../selectors/professional.selectors';
import { getCurrentProject } from '../selectors/project.selectors';
import { ProfessionalSelectionComponent } from 'src/app/modules/shared/professional-selection/professional-selection.component';
import { TranslateService } from '@ngx-translate/core';


@Injectable()
export class ProfessionalEffects {

  error$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfessionalActions.saveProfessionalFailed),
      tap(({ error }) => {
        if (error) {
          this.alertService.showErrorMessage(`${this.translateService.instant('shared.errore')}`, error);
        }
      })
    ), { dispatch: false }
  );

  loadProfessionals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfessionalActions.loadProfessionals),
      switchMap(({ page, perPage, order, direction, filters, includes }) => {
        return this.professionalService.list(page, perPage, order, direction, filters, includes)
          .pipe(
            map(result =>
              ProfessionalActions.loadProfessionalsCompleted({ professionals: result.data, currentPage: page, total: result.total, perPage, order, direction, filters, includes })
            ),
            catchError(error => {
              return of(ProfessionalActions.loadProfessionalsFailed({ error }))
            })
          )
      })
    )
  );

  changePage = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfessionalActions.changePage),
      withLatestFrom(this.store$.select(getProfessionalsTableState)),
      map(([{ page, size, filters }, { total, currentPage, perPage, direction, order, includes }]) => ProfessionalActions.loadProfessionals({ page: page, perPage: size, order, direction, filters, includes }))
    )
  );

  changeSort = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfessionalActions.changeSort),
      withLatestFrom(this.store$.select(getProfessionalsTableState)),
      map(([action, { total, currentPage, perPage, direction, order, filters, includes }]) => ProfessionalActions.loadProfessionals({ page: currentPage, perPage: perPage, order: action.order, direction: action.direction, filters, includes }))
    )
  );

  changeFilters = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfessionalActions.changeFilters),
      withLatestFrom(this.store$.select(getProfessionalsTableState)),
      map(([{ filters }, { total, currentPage, perPage, direction, order, includes }]) => ProfessionalActions.loadProfessionals({ page: currentPage, perPage: perPage, order, direction, filters, includes }))
    )
  );

  // editProfessional$ = createEffect(() => this.actions$.pipe(
  //   ofType(ProfessionalActions.editProfessional),
  //   map(({ professional }) => {
  //     let dialogRef = this.dialog.open(ProfessionalEditComponent, {
  //       data: {
  //         professional,
  //       },
  //     });
  //     return ProfessionalActions.professionalDialogOpened({ dialogId: dialogRef.id });
  //   }))
  // );

  // saveProfessional$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(ProfessionalActions.saveProfessional),
  //     mergeMap(({ professional }) =>
  //       this.professionalService.upsert(professional.toDTO())
  //         .pipe(
  //           map(result =>
  //             ProfessionalActions.saveProfessionalCompleted({ professional: result })
  //           ),
  //           catchError(error => of(ProfessionalActions.saveProfessionalFailed({ error })))
  //         )
  //     )
  //   )
  // );

  // onSaveCompleted$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(ProfessionalActions.saveProfessionalCompleted),
  //     map(action => action.professional),
  //     tap(professional => this.alertService.showConfirmMessage(`${professional.user?.name} salvato con successo`)),
  //     map(() => ProfessionalActions.closeProfessionalDialog())
  //   )
  // );

  // loadCurrentProjectAfterSave$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(ProfessionalActions.saveProfessionalCompleted),
  //     map(() => ProjectActions.loadCurrentProject())
  //   )
  // );

  // deleteProfessional$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(ProfessionalActions.deleteProfessional),
  //     switchMap(({ professional }) =>
  //       this.alertService.showConfirmDialog('Conferma eliminazione', `Sei sicuro di voler eliminare il professionista ${professional.user?.name}?`)
  //         .pipe(
  //           mergeMap((confirm) => {
  //             return confirm ?
  //               this.professionalService.delete(professional.id)
  //                 .pipe(
  //                   map(() => ProfessionalActions.deleteProfessionalCompleted({ professional })),
  //                   catchError(error => of(ProfessionalActions.deleteProfessionalFailed({ error })))
  //                 )

  //               : of(ProfessionalActions.deleteProfessionalCancelled());
  //           })
  //         )
  //     )
  //   )
  // );

  // onDeleteCompleted$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(ProfessionalActions.deleteProfessionalCompleted),
  //     tap(({ professional }) => this.alertService.showConfirmMessage(`Professional ${professional.user?.name} eliminato con successo`)),
  //     map(() => ProfessionalActions.closeProfessionalDialog())
  //   )
  // );


  // closeDialog = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(ProfessionalActions.closeProfessionalDialog),
  //     withLatestFrom(this.store$.select(getProfessionalDialogId)),
  //     tap(([_, dialogId]) => {
  //       if (dialogId) {
  //         this.dialog.getDialogById(dialogId)?.close();
  //       }
  //     })
  //   ), { dispatch: false }
  // );

  // reloadAfterSave = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(ProfessionalActions.saveProfessionalCompleted),
  //     withLatestFrom(this.store$.select(getProfessionalsTableState)),
  //     map(([_, { currentPage, perPage, direction, order, filters, includes }]) => ProfessionalActions.loadProfessionals({ page: currentPage, perPage, order, direction, filters, includes }))
  //   )
  // );

  // reloadAfterDelete = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(ProfessionalActions.deleteProfessionalCompleted),
  //     withLatestFrom(this.store$.select(getProfessionalsTableState)),
  //     map(([_, { currentPage, perPage, direction, order, filters, includes }]) => ProfessionalActions.loadProfessionals({ page: currentPage, perPage, order, direction, filters, includes }))
  //   )
  // );


  closeSelectionDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfessionalActions.closeSelectionDialog),
      withLatestFrom(this.store$.select(getSelectionDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }

      })
    ), { dispatch: false }
  );

  selectProfessional$ = createEffect(() => this.actions$.pipe(
    ofType(ProfessionalActions.selectProfessional),
    map(({ filters }) => {
      let dialogRef = this.dialog.open(ProfessionalSelectionComponent, {
        data: {
          defaultFilters: filters
        }
      });
      return ProfessionalActions.selectionDialogOpened({ selectionDialogId: dialogRef.id });
    }))
  );


  professionalSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfessionalActions.professionalSelected),
      map(() => ProfessionalActions.closeSelectionDialog())
    )
  )


  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private professionalService: LaravelProfessionalService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private translateService: TranslateService
  ) { }
}
