<app-risk-identification-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters">
</app-risk-identification-filters>
<mat-card class="u-center-text" *ngIf="!(riskIdentifications | async).length">
  <h4>{{'risks.Nessun indetificatore di rischio trovato' | translate}}</h4>
  <button *ngIf="canAdd" mat-raised-button (click)="addRiskIdentification()">{{'shared.Aggiungi' | translate}}</button>
</mat-card>
<div class="table-container" [hidden]="!(riskIdentifications | async).length">
  <table mat-table matSort [dataSource]="riskIdentifications">

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> {{'shared.ID' | translate}} </th>
      <td mat-cell *matCellDef="let row">
        {{row.id}}
      </td>
    </ng-container>
    <ng-container matColumnDef="riskId">
      <th mat-header-cell *matHeaderCellDef> {{'risks.Rischio' | translate}} </th>
      <td mat-cell *matCellDef="let row">
        {{row.risk.identifier}}
      </td>
    </ng-container>
    <ng-container matColumnDef="probability">
      <th mat-header-cell *matHeaderCellDef> {{'shared.Probablità' | translate}} </th>
      <td mat-cell *matCellDef="let row">
        {{row.probability}}%
      </td>
    </ng-container>
    <ng-container matColumnDef="impact">
      <th mat-header-cell *matHeaderCellDef> {{'risks.Impatto' | translate}} </th>
      <td mat-cell *matCellDef="let row">
        {{row.impact}}
      </td>
    </ng-container>
    <ng-container matColumnDef="riskOwnerId">
      <th mat-header-cell *matHeaderCellDef> {{'risks.Risk Owner' | translate}} </th>
      <td mat-cell *matCellDef="let row">
        {{row.riskOwner?.name}}
      </td>
    </ng-container>
    <ng-container matColumnDef="riskActioneeId">
      <th mat-header-cell *matHeaderCellDef> {{'risks.Risk Actionee' | translate}} </th>
      <td mat-cell *matCellDef="let row">
        {{row.riskActionee?.name}}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <button *ngIf="canAdd" mat-icon-button mat-raised-button color="accent"
          (click)="$event.stopPropagation();addRiskIdentification()">
          <mat-icon>playlist_add</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="canEdit">
          <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
            <button mat-icon-button (click)="editRiskIdentification(row)">
              <mat-icon>create</mat-icon>
              {{'shared.Modifica' | translate}}
            </button>
          </mat-menu>
        </ng-container>
        <button *ngIf="canSelect" mat-raised-button color="primary"
          (click)="selectRiskIdentification(row)">{{'shared.Seleziona' | translate}}</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>
