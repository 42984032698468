import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { LaravelBonusService } from 'src/app/commons/services/backend/laravel-bonus.service';
import { BonusLinesComponent } from 'src/app/modules/home/bonuses/bonus-lines/bonus-lines.component';
import { GenerateBonusComponent } from 'src/app/modules/home/bonuses/generate-bonus/generate-bonus.component';

import { AlertService } from '../../commons/services/alert.service';
import * as BonusActions from '../actions/bonus.actions';
import { AppState } from '../reducers';
import { getBonusDialogId, getBonusesTableState, getBonusLineDialogId } from '../selectors/bonus.selectors';
import { TranslateService } from '@ngx-translate/core';



@Injectable()
export class BonusEffects {

  error$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BonusActions.generateBonusFailed, BonusActions.cancelBonusFailed),
      tap(({ error }) => {
        if (error) {
          this.alertService.showErrorMessage(`${this.translateService.instant('shared.errore')}`, error);
        }
      })
    ), { dispatch: false }
  );

  loadBonuses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BonusActions.loadBonuses),
      switchMap(({ page, perPage, order, direction, filters, includes }) => {
        return this.bonusService.list(page, perPage, order, direction, filters, includes)
          .pipe(
            map(result =>
              BonusActions.loadBonusesCompleted({ bonuses: result.data, currentPage: page, total: result.total, perPage, order, direction, filters, includes })
            ),
            catchError(error => {
              return of(BonusActions.loadBonusesFailed({ error }))
            })
          )
      })
    )
  );

  changePage = createEffect(() =>
    this.actions$.pipe(
      ofType(BonusActions.changePage),
      withLatestFrom(this.store$.select(getBonusesTableState)),
      map(([{ page, size }, { total, currentPage, perPage, direction, order, filters, includes }]) => BonusActions.loadBonuses({ page: page, perPage: size, order, direction, filters, includes }))
    )
  );

  changeSort = createEffect(() =>
    this.actions$.pipe(
      ofType(BonusActions.changeSort),
      withLatestFrom(this.store$.select(getBonusesTableState)),
      map(([action, { total, currentPage, perPage, direction, order, filters, includes }]) => BonusActions.loadBonuses({ page: currentPage, perPage: perPage, order: action.order, direction: action.direction, filters, includes }))
    )
  );

  changeFilters = createEffect(() =>
    this.actions$.pipe(
      ofType(BonusActions.changeFilters),
      withLatestFrom(this.store$.select(getBonusesTableState)),
      map(([{ filters }, { total, currentPage, perPage, direction, order, includes }]) => BonusActions.loadBonuses({ page: currentPage, perPage: perPage, order, direction, filters, includes }))
    )
  );
  generateBonus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BonusActions.generateBonus),
      mergeMap(({ year, bonus }) =>
        this.bonusService.generateBonus(year, bonus)
          .pipe(
            map(result =>
              BonusActions.generateBonusCompleted({ bonusLines: result })
            ),
            catchError(error => of(BonusActions.generateBonusFailed({ error })))
          )
      )
    )
  )
  onGenerazioneCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BonusActions.generateBonusCompleted),
      map(() => this.alertService.showConfirmMessage(`${this.translateService.instant('bonuses.bonus generato con successo')}`)),
      map(() => BonusActions.closeBonusDialog())
    )
  );
  bonusDialog$ = createEffect(() => this.actions$.pipe(
    ofType(BonusActions.bonusDialog),
    map(() => {
      let dialogRef = this.dialog.open(GenerateBonusComponent, {

      });
      return BonusActions.bonusDialogOpened({ dialogId: dialogRef.id });
    }))
  );
  closeBonusDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(BonusActions.closeBonusDialog),
      withLatestFrom(this.store$.select(getBonusDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }

      })
    ), { dispatch: false }
  );

  bonusLinesDialog$ = createEffect(() => this.actions$.pipe(
    ofType(BonusActions.openBonusLinesDialog),
    map((bonus) => {
      let dialogRef = this.dialog.open(BonusLinesComponent, {
        data: {
          bonus
        },
        width: "80%"
      });
      return BonusActions.bonusLinesDialogOpened({ bonusLineDialogId: dialogRef.id });
    }))
  );
  closeBonusLinesDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(BonusActions.closeBonusLinesDialog),
      withLatestFrom(this.store$.select(getBonusLineDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }

      })
    ), { dispatch: false }
  );


  cancelBonus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BonusActions.cancelBonus),
      switchMap(({ year }) => this.alertService.showConfirmDialog(`${this.translateService.instant('bonuses.Undo R&D Generation')}`, `${this.translateService.instant('bank-accounts.Delete all generated R&D for year')} <strong>${year}</strong>?`).pipe(
        filter(result => !!result),
        switchMap(() => this.bonusService.cancelBonus(year).pipe(
          map(result =>
            BonusActions.cancelBonusCompleted({ costLines: result })
          ),
          catchError(error => of(BonusActions.cancelBonusFailed({ error })))
        ))
      ))
    )
  )
  onCancelCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BonusActions.cancelBonusCompleted),
      tap(() => this.alertService.showConfirmMessage(`${this.translateService.instant('bonuses.Annullamento generazione bonus effettuato con successo')}`)),
      map(() => BonusActions.closeBonusLinesDialog())
    )
  );

  reloadAfterGenerateOrDelete = createEffect(() =>
    this.actions$.pipe(
      ofType(BonusActions.generateBonusCompleted, BonusActions.cancelBonusCompleted),
      withLatestFrom(this.store$.select(getBonusesTableState)),
      map(([_, { currentPage, perPage, direction, order, filters, includes }]) => BonusActions.loadBonuses({ page: currentPage, perPage, order, direction, filters, includes }))
    )
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private bonusService: LaravelBonusService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private translateService: TranslateService
  ) { }
}
