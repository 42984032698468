import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"]
})
export class ConfirmDialogComponent {
  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>, public translateService: TranslateService) { }

  public confirmMessage: string;
  public title: string;
  public okButtonLabel: string = `${this.translateService.instant('shared.Conferma')}`;
  public cancelButtonLabel: string = `${this.translateService.instant('shared.Annulla')}`;
  public showCancelButton: boolean = true;
}
