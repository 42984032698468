<form [formGroup]="clientBonusForm" novalidate>
  <div class="u-flex-full">
    <mat-form-field class="u-full-width">
      <mat-label>{{'shared.Utente' | translate}}</mat-label>
      <app-user-field formControlName="user">
      </app-user-field>
    </mat-form-field>
    <mat-form-field class="u-full-width">
      <input matInput placeholder="{{'shared.Percentuale' | translate}}" formControlName="percentage" type="number" min="0" max="100" forceNumber >
      <mat-icon matSuffix>percent</mat-icon>
      <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
    </mat-form-field>
  </div>

</form>
<span class="button-bar u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()"
    [disabled]="clientBonusForm.pristine">{{'shared.Reset' | translate}}</button>
    <button mat-raised-button color="primary" (click)="save()"
    [disabled]="clientBonusForm.pristine || !clientBonusForm.valid">{{'shared.Conferma' | translate}}</button>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">{{'shared.Chiudi' | translate}}</button>
</span>
