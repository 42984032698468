<header mat-dialog-title class="u-flex-full">
  <span>
    <span *ngIf="contact">{{'contacts.Modifica contatto' | translate}} {{contact.name}} : {{contact.lastname}}</span>
    <span *ngIf="!contact">{{'contacts.Nuovo contatto' | translate}}</span>
  </span>
  <span *ngIf="contact">
    <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
      <button mat-menu-item (click)="deleteContact()">
        <mat-icon>delete</mat-icon>
        {{'shared.Elimina' | translate}}
      </button>
    </mat-menu>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="contactForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'shared.Nome' | translate}}" formControlName="name" type="text">
        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'shared.Cognome' | translate}}" formControlName="lastname" type="text">
        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'shared.Email' | translate}}" formControlName="email" type="email">
        <mat-error>{{'shared.Email non valida' | translate}}</mat-error>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'contacts.Telefono' | translate}}" formControlName="phone" type="text">
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'shared.Cellulare' | translate}}" formControlName="mobile" type="text">
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'contacts.Fax' | translate}}" formControlName="fax" type="text">
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <app-user-field placeholder="{{'contacts.Internal User' | translate}}" formControlName="internalUser">
        </app-user-field>
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <textarea matInput formControlName="note" placeholder="{{'shared.Note' | translate}}" rows="3"></textarea>
      </mat-form-field>
    </div>

  </form>
  <!-- {{contactForm.value | json}} -->

</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()"
      [disabled]="contactForm.pristine">{{'shared.Reset' | translate}}</button>
    <button mat-raised-button color="primary" (click)="save()"
      [disabled]="contactForm.pristine || !contactForm.valid">{{'shared.Salva' | translate}}</button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">{{'shared.Chiudi' | translate}}</button>
</mat-dialog-actions>