<header mat-dialog-title class="u-flex-full">
  <span>
    {{'shared.Seleziona attività' | translate}}
  </span>
</header>
<mat-dialog-content>
  <action-list [actions]="actions" [defaultFilters]="defaultFilters" [displayedColumns]="displayedColumns"
    [total]="total" [canSelect]="true" (onSortChange)="sortChange($event)" (onPageChange)="pageChange($event)"
    (onFilterChange)="filtersChange($event)" (onSelectAction)="selectAction($event)">
  </action-list>
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">{{'shared.Chiudi' | translate}}</button>
</mat-dialog-actions>