import { AbstractControl } from '@angular/forms';
import { Base, BaseDTO } from './base.model';

export interface PaymentFilters {
  search?: string;
}

export type PaymentStart = 'Data fattura' | 'Fine mese';
export const PaymentStarts = ['Data fattura', 'Fine mese'];

export interface PaymentDTO extends BaseDTO {
  code: string;
  description?: string;
  start: PaymentStart;
  lead: number;
}

export class Payment extends Base {
  code: string;
  description?: string;
  start: PaymentStart;
  lead: number;

  constructor(source: PaymentDTO) {
    super(source);
    if (source) {
      this.code = source.code;
      this.description = source.description;
      this.start = source.start;
      this.lead = source.lead;
    }
  }

  toDTO(): PaymentDTO {
    let result: PaymentDTO = <PaymentDTO>super.toDTO();
    result.code = this.code;
    result.description = this.description;
    result.start = this.start;
    result.lead = this.lead;
    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: Payment) {
    const formModel = formGroup.value;
    let payment: Payment = new Payment(null);
    payment.code = formModel.code;
    payment.description = formModel.description;
    payment.start = formModel.start;
    payment.lead = formModel.lead;

    if (original) {
      payment.id = original.id;
    }
    return payment;
  }

}
