
  <mat-chip-list #chipList>
    <mat-chip color="primary" *ngFor="let user of value; let i = index;" [removable]="true"
      [selectable]="false" (removed)="removeUser(i)">
      {{user?.name}} {{user?.lastname}}
      <mat-icon matChipRemove>cancel</mat-icon>

    </mat-chip>
    <button type="button" style="margin: 5px" matTooltip="{{'shared.Seleziona utente' | translate}}" (click)="selectUser()" mat-icon-button
    mat-raised-button>
  <mat-icon>add</mat-icon>
  </button>



