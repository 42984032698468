<header mat-dialog-title class="u-flex-full">
  <span>
    <span *ngIf="payment">{{'payments.Modifica pagamento' | translate}} :{{payment.code}}</span>
    <span *ngIf="!payment">{{'payments.Nuovo pagamento' | translate}}</span>
  </span>
  <span *ngIf="payment">
    <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
      <button mat-menu-item (click)="deletePayment()">
        <mat-icon>delete</mat-icon>
        {{'shared.Elimina' | translate}}
      </button>
    </mat-menu>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="paymentForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'shared.Codice' | translate}}" formControlName="code" type="text">
        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
      </mat-form-field>
    </div>

    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <textarea matInput placeholder="{{'shared.Descrizione' | translate}}" formControlName="description" type="text"
          rows="2"></textarea>
      </mat-form-field>
    </div>

    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <mat-label>{{'shared.Inizio' | translate}}</mat-label>

        <mat-select formControlName="start">
          <mat-option *ngFor="let label of starts" [value]="label">
            {{'shared.' + label | translate}}
          </mat-option>
        </mat-select>

        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
      </mat-form-field>

      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'payments.Lead' | translate}}" formControlName="lead" type="number" min="0"
          forceNumber>
        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
      </mat-form-field>
    </div>
  </form>
  <!-- {{paymentForm.value | json}} -->

</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()"
      [disabled]="paymentForm.pristine">{{'shared.Reset' | translate}}</button>
    <button mat-raised-button color="primary" (click)="save()"
      [disabled]="paymentForm.pristine || !paymentForm.valid">{{'shared.Salva' | translate}}</button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">{{'shared.Chiudi' | translate}}</button>
</mat-dialog-actions>