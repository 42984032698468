
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { UserDTO } from 'src/app/commons/models/user.model';

import * as AuthSelectors from 'src/app/store/selectors/auth.selectors';
import * as UserSelectors from 'src/app/store/selectors/user.selectors';

import { AlertService } from '../../commons/services/alert.service';
import { LaravelUserService } from '../../commons/services/backend/laravel-user.service';

import * as UserActions from '../actions/user.actions';
import * as RouterActions from '../actions/router.actions';
import { AppState } from '../reducers';
import { getSelectedUser, getSelectionDialogId, getUserDialogId, getUsersTableState } from '../selectors/user.selectors';
import { PasswordChangeComponent } from 'src/app/modules/home/users/user-edit/password-change/password-change.component';
import { UserEditComponent } from 'src/app/modules/home/users/user-edit/user-edit.component';
import { UserSelectionComponent } from 'src/app/modules/shared/user-selection/user-selection.component';
import { TranslateService } from '@ngx-translate/core';


@Injectable()
export class UserEffects {

  error$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.saveUserFailed),
      tap(({ error }) => {
        if (error) {
          this.alertService.showErrorMessage(`${this.translateService.instant('shared.errore')}`, error);
        }
      })
    ), { dispatch: false }
  );

  loadUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadUsers),
      switchMap(({ page, perPage, order, direction, filters, includes }) => {
        return this.userService.list(page, perPage, order, direction, filters, includes)
          .pipe(
            map(result =>
              UserActions.loadUsersCompleted({ users: result.data, currentPage: page, total: result.total, perPage, order, direction, filters, includes })
            ),
            catchError(error => {
              return of(UserActions.loadUsersFailed({ error }))
            })
          )
      })
    )
  );

  changePage = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.changePage),
      withLatestFrom(this.store$.select(getUsersTableState)),
      map(([{ page, size }, { total, currentPage, perPage, direction, order, filters, includes }]) => UserActions.loadUsers({ page: page, perPage: size, order, direction, filters, includes }))
    )
  );

  changeSort = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.changeSort),
      withLatestFrom(this.store$.select(getUsersTableState)),
      map(([action, { total, currentPage, perPage, direction, order, filters, includes }]) => UserActions.loadUsers({ page: currentPage, perPage: perPage, order: action.order, direction: action.direction, filters, includes }))
    )
  );

  changeFilters = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.changeFilters),
      withLatestFrom(this.store$.select(getUsersTableState)),
      map(([{ filters }, { total, currentPage, perPage, direction, order, includes }]) => UserActions.loadUsers({ page: currentPage, perPage: perPage, order, direction, filters, includes }))
    )
  );

  editUser$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.editUser),
    map(({ user }) => {
      let dialogRef = this.dialog.open(UserEditComponent, {
        data: {
          user
        }
      });
      return UserActions.userDialogOpened({ dialogId: dialogRef.id });
    }))
  );

  saveUser$ = createEffect(() =>
  this.actions$.pipe(
    ofType(UserActions.saveUser),
    mergeMap(({ user }) =>
      this.userService.upsert(user.toDTO())
        .pipe(
          map(result =>
            UserActions.saveUserCompleted({ user: result })
          ),
          catchError(error => of(UserActions.saveUserFailed({ error })))
        )
    )
  )
);

onSaveCompleted$ = createEffect(() =>
  this.actions$.pipe(
    ofType(UserActions.saveUserCompleted),
    map(action => action.user),
    tap(user => this.alertService.showConfirmMessage(`${user.name} ${this.translateService.instant('shared.salvata con successo')}`)),
    map(() => UserActions.closeUserDialog())
  )
);

  changeUserPassword$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.changeUserPassword),
    map(({ user }) => {
      let dialogRef = this.dialog.open(PasswordChangeComponent, {
        data: {
          user
        }
      });
      return UserActions.changePasswordDialogOpen({ dialogId: dialogRef.id });
    }))
  );

  updatePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updatePassword),
      flatMap(({ newPassword, user }) => {
        if (user) {
          return of({ newPassword, user });
        } else {
          return this.store$.select(AuthSelectors.getCurrentUser).pipe(take(1), map(user => {
            return { newPassword, user }
          }));
        }
      }),
      switchMap(({ newPassword, user }) =>
        this.userService.changePassword(user.id, newPassword).pipe(
          map(user =>
            UserActions.updatePasswordCompleted({ user })
          ),
          catchError(error => {
            return of(UserActions.updatePasswordFailed({ error }))
          })
        )
      )
    )
  );

  onUpdatePasswordCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updatePasswordCompleted),
      map(action => action.user),
      tap(user => this.alertService.showConfirmMessage(`${this.translateService.instant('users.Password per', {total:user.email})}`)),
      map(() => UserActions.closeChangePasswordDialog())));

  closeChangePasswordDialog = createEffect(() =>
  this.actions$.pipe(
    ofType(UserActions.closeChangePasswordDialog),
    withLatestFrom(this.store$.select(UserSelectors.getChangePasswordDialogId)),
    tap(([_, dialogId]) => {
      if (dialogId) {
        this.dialog.getDialogById(dialogId).close();
      }
    })
  ), { dispatch: false }
);    

  deleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.deleteUser),
      switchMap(({ user }) =>
        this.alertService.showConfirmDialog(`${this.translateService.instant('shared.Conferma eliminazione')}`, `${this.translateService.instant('users.Sei sicuro di voler eliminare utente')} ${user.email}?`)
          .pipe(
            mergeMap((confirm) => {
              return confirm ?
                this.userService.delete(user.id)
                  .pipe(
                    map(() => UserActions.deleteUserCompleted({ user })),
                    catchError(error => of(UserActions.deleteUserFailed({ error })))
                  )

                : of(UserActions.deleteUserCancelled());
            })
          )
      )
    )
  );

  onDeleteCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.deleteUserCompleted),
      tap(({ user }) => this.alertService.showConfirmMessage(`${this.translateService.instant('users.Utente eliminato', {total:user.name})}`)),
      map(() => UserActions.closeUserDialog())
    )
  );


  closeDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.closeUserDialog),
      withLatestFrom(this.store$.select(getUserDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }
      })
    ), { dispatch: false }
  );

  reloadAfterSave = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.saveUserCompleted),
      withLatestFrom(this.store$.select(getUsersTableState)),
      map(([_, { currentPage, perPage, direction, order, filters, includes }]) => UserActions.loadUsers({ page: currentPage, perPage, order, direction, filters, includes }))
    )
  );

  reloadAfterDelete = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.deleteUserCompleted),
      withLatestFrom(this.store$.select(getUsersTableState)),
      map(([_, { currentPage, perPage, direction, order, filters, includes }]) => UserActions.loadUsers({ page: currentPage, perPage, order, direction, filters, includes }))
    )
  );

  selectUser$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.selectUser),
    map(({ filters }) => {
      let dialogRef = this.dialog.open(UserSelectionComponent, {
        data: {
          defaultFilters: filters
        }
      });
      return UserActions.selectionDialogOpened({ selectionDialogId: dialogRef.id });
    }))
  );
  closeSelectionDialog = createEffect(() =>
  this.actions$.pipe(
    ofType(UserActions.closeSelectionDialog),
    withLatestFrom(this.store$.select(getSelectionDialogId)),
    tap(([_, dialogId]) => {
      if (dialogId) {
        this.dialog.getDialogById(dialogId).close();
      }

    })
  ), { dispatch: false }
);

  usersSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.userSelected),
      map(() => UserActions.closeSelectionDialog())
    ))


  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private userService: LaravelUserService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private translateService: TranslateService
  ) { }
}
