<mat-expansion-panel style="margin-bottom: 16px;">
  <mat-expansion-panel-header>
    {{'shared.Filtra' | translate}}
  </mat-expansion-panel-header>
  <form [formGroup]="filtersForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>{{'shared.Titolo' | translate}}</mat-label>
        <input type="text" matInput formControlName="title">
      </mat-form-field>
    </div>

    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>{{'shared.Data' | translate}}</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="{{'shared.Inizio' | translate}}" formControlName="start">
          <input matEndDate placeholder="{{'shared.Fine' | translate}}" formControlName="end">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>{{'offers.Version' | translate}}</mat-label>
        <input type="number" matInput formControlName="version" forceNumber>
      </mat-form-field>
    </div>

    <div class="u-flex-full" *ngIf="!businessOpportunityId">
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>{{'shared.Business Opportunity' | translate}}</mat-label>
        <app-business-opportunity-field formControlName="businessOpportunity"></app-business-opportunity-field>
      </mat-form-field>
    </div>

    <span class="u-flex-full">
      <button class="filter" mat-raised-button [disabled]="filtersForm.pristine" color="primary"
        (click)="applyFilters()">{{'shared.Filtra' | translate}}</button>
      <button mat-icon-button *ngIf="!filtersForm.pristine" (click)="resetFilters()" matTooltip="{{'shared.Reset' | translate}}">
        <mat-icon>replay</mat-icon>
      </button>
    </span>
  </form>
</mat-expansion-panel>
