import { PaymentStarts } from './../../../../commons/models/payment.model';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { Payment } from 'src/app/commons/models/payment.model';
import { AlertService } from 'src/app/commons/services/alert.service';
import * as PaymentActions from 'src/app/store/actions/payment.actions';
import { AppState } from 'src/app/store/reducers';

@Component({
  selector: 'app-payment-edit',
  templateUrl: './payment-edit.component.html',
  styleUrls: ['./payment-edit.component.scss']
})
export class PaymentEditComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  private _payment: Payment;

  currentPayment: Payment;
  starts = PaymentStarts;

  paymentForm: FormGroup;
  constructor(private store$: Store<AppState>, private alertService: AlertService, private fb: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private translateService: TranslateService) {
  }

  ngOnInit() {
    if (this.data) {
      this.payment = this.data.payment;
    }
    this._createForm();
    this.ngOnChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private _createForm() {
    let group = {
      code: ['', Validators.required],
      description: [''],
      start: ['', Validators.required],
      lead: ['', Validators.required],
    }
    this.paymentForm = this.fb.group(group);
  }

  private ngOnChanges() {
    if (this.paymentForm) {
      this.paymentForm.reset();
      if (this.payment) {
        this._initFormValues(this.payment);
      }
    }
  }

  private _initFormValues(payment: Payment) {
    if (payment) {
      this.paymentForm.patchValue({
        code: payment.code,
        description: payment.description,
        start: payment.start,
        lead: payment.lead,
      });
    }
  }

  get payment(): Payment {
    return this._payment;
  }

  set payment(payment: Payment) {
    this._payment = payment;
    this.ngOnChanges();
  }

  private _prepareSavePayment(): Payment {
    let savingPayment: Payment = Payment.fromFormGroup(this.paymentForm, this.payment);
    return savingPayment;
  }

  save() {
    let unsavedEntity = this._prepareSavePayment();
    this.store$.dispatch(PaymentActions.savePayment({ payment: unsavedEntity }));
  }

  close() {
    if (this.paymentForm.pristine) {
      this.store$.dispatch(PaymentActions.closePaymentDialog())
    } else {
      this.alertService
        .showConfirmDialog(
          `${this.translateService.instant('shared.Chiudi')}`,
          `${this.translateService.instant('shared.Ci sono modifiche non salvate. Sei sicuro di voler chiudere?')}`
        )
        .subscribe(result => {
          if (result) {
            this.store$.dispatch(PaymentActions.closePaymentDialog())
          }
        });
    }
  }

  deletePayment() {
    if (this.payment) {
      this.store$.dispatch(PaymentActions.deletePayment({ payment: this.payment.toDTO() }))
    }
  }

  revert() {
    this.ngOnChanges();
  }
}
