<header mat-dialog-title class="u-flex-full">
  <span>
    <span>{{'offers.Duplica offerta' | translate}} : {{offer?.version}}</span>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="offerForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input matInput [matDatepicker]="fromDatePicker1" formControlName="date" placeholder="{{'shared.Data' | translate}}"  />
        <mat-datepicker-toggle matSuffix [for]="fromDatePicker1"></mat-datepicker-toggle>
        <mat-datepicker #fromDatePicker1></mat-datepicker>
        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
      </mat-form-field>
    </div>
  </form>
  <!-- {{offerForm.value | json}} -->
</mat-dialog-content>

<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()"
      [disabled]="offerForm.pristine">{{'shared.Reset' | translate}}</button>
    <button mat-raised-button color="primary" (click)="save()"
      [disabled]="offerForm.pristine || !offerForm.valid">{{'shared.Salva' | translate}}</button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">{{'shared.Chiudi' | translate}}</button>
</mat-dialog-actions>
