<mat-expansion-panel style="margin-bottom: 16px;">
  <mat-expansion-panel-header>
    {{'shared.Filtra' | translate}}
  </mat-expansion-panel-header>
  <form [formGroup]="filtersForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>{{'shared.Identificativo' | translate}}</mat-label>
        <input type="number" matInput formControlName="identifier" forceNumber>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>{{'shared.Data' | translate}}</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="{{'shared.Inizio' | translate}}" formControlName="start">
          <input matEndDate placeholder="{{'shared.Fine' | translate}}" formControlName="end">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>

    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>{{'shared.Cliente' | translate}}</mat-label>
        <app-client-field formControlName="client"></app-client-field>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>{{'shared.Commessa' | translate}}</mat-label>
        <app-project-field formControlName="project" [canSelectClosed]="true"></app-project-field>
      </mat-form-field>

      <mat-checkbox matInput color="primary" formControlName="paid">
        <mat-label>{{'shared.Pagata' | translate}}</mat-label>
      </mat-checkbox>
    </div>

    <span class="u-flex-full">
      <button class="filter" mat-raised-button [disabled]="filtersForm.pristine" color="primary"
        (click)="applyFilters()">{{'shared.Filtra' | translate}}</button>
      <button mat-icon-button *ngIf="!filtersForm.pristine" (click)="resetFilters()"
        matTooltip="{{'shared.Reset' | translate}}">
        <mat-icon>replay</mat-icon>
      </button>
    </span>
  </form>
  <!-- {{ filtersForm.value | json}} -->
</mat-expansion-panel>