<mat-expansion-panel style="margin-bottom: 16px;">
  <mat-expansion-panel-header>
    {{'shared.Filtra' | translate}}
  </mat-expansion-panel-header>
  <form [formGroup]="filtersForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>{{'shared.Ricerca libera' | translate}}</mat-label>
        <input type="text" matInput formControlName="search">
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <mat-select formControlName="roles" multiple>
          <mat-select-trigger>
            <span *ngIf="filtersForm.value.roles?.length == roles.length">{{'shared.Tutti' | translate}}</span>
            <span *ngIf="filtersForm.value.roles?.length < roles.length">
              {{(filtersForm.value.roles ? filtersForm.value.roles[0] : '') | userRole}}
              <span *ngIf="filtersForm.value.roles?.length > 1" class="additional-selection">
                (+{{filtersForm.value.roles.length - 1}}
                {{filtersForm.value.roles?.length === 2 ? 'altro' : 'altri'}})
              </span>
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let role of roles" [value]="role" [ngClass]="role">
            {{('shared.' + (role | userRole)) | translate}}</mat-option>
        </mat-select>
        <mat-hint>{{'users.Ruolo' | translate}}</mat-hint>
      </mat-form-field>
      <mat-form-field class="u-full-width" *ngIf="showActiveFilter">
        <mat-label>{{'users.Attivo' | translate}}</mat-label>
        <mat-select formControlName="active">
          <mat-option value="">{{'shared.-- Tutti --' | translate}}</mat-option>
          <mat-option [value]="true">{{'shared.Sì' | translate}}</mat-option>
          <mat-option [value]="false">{{'shared.No' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
      <span class="u-flex-full">
        <button class="filter" mat-raised-button [disabled]="filtersForm.pristine" color="primary"
          (click)="applyFilters()">{{'shared.Filtra' | translate}}</button>
        <button mat-icon-button *ngIf="!filtersForm.pristine" (click)="resetFilters()" matTooltip="{{'shared.Reset' | translate}}">
          <mat-icon>replay</mat-icon>
        </button>
      </span>
    </div>
  </form>
</mat-expansion-panel>
