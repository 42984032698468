<header mat-dialog-title class="u-flex-full">
  <span>
    {{'projects.Seleziona budget' | translate}}
  </span>
</header>
<mat-dialog-content>
  <budget-list [budgets]="budgets" [defaultFilters]="defaultFilters" [displayedColumns]="displayedColumns"
    [total]="total" [canSelect]="true" (onSortChange)="sortChange($event)" (onPageChange)="pageChange($event)"
    (onFilterChange)="filtersChange($event)" (onSelectBudget)="selectBudget($event)"></budget-list>
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">{{'shared.Chiudi' | translate}}</button>
</mat-dialog-actions>