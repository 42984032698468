<header mat-dialog-title class="u-flex-full">
  <h1 mat-dialog-title>{{'projects.Conferma sblocco pagamento bonus' | translate}}</h1>
  <button *ngIf="(bonusLines | async).length" mat-icon-button mat-raised-button color="primary"
    (click)="exportBonusLines()">
    <mat-icon matTooltip="{{'projects.Scarica CSV' | translate}}">file_download</mat-icon>
  </button>
</header>
<div mat-dialog-content>
  <ng-container *ngIf="(bonusLines | async).length; else no_lines">
    <p>{{'projects.Verrà generata una riga di costo per ognuna di queste righe di bonus. Seleziona la data della riga di costo' | translate}}</p>
    <mat-form-field floatLabel="auto">
      <mat-label>{{'shared.Data' | translate}}</mat-label>
      <input matInput [matDatepicker]="datePicker" [formControl]="dateCtrl" />
      <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
      <mat-datepicker #datePicker></mat-datepicker>
    </mat-form-field>
    <div style="margin: 0 15px;">
      <bonus-line-list [bonusLines]="bonusLines | async" [total]="total" [defaultFilters]="defaultFilters"
        (onFilterChange)="filtersChange($event)" (onSortChange)="sortChange($event)" (onPageChange)="pageChange($event)"
        (onExportBonusLines)="exportBonusLines()" [showProjectColumn]="showProjectColumn"
        [showCostLineColumn]="showCostLineColumn"></bonus-line-list>
    </div>
  </ng-container>
  <ng-template #no_lines>
    <h4 class="u-center-text">{{'shared.Nessuna riga di bonus trovata' | translate}}</h4>
  </ng-template>
</div>
<div mat-dialog-actions class="u-flex-full">
  <button mat-raised-button color="primary" (click)="confirm()">{{'shared.Conferma' | translate}}</button>
  <button mat-raised-button (click)="close()">{{'shared.Annulla' | translate}}</button>
</div>