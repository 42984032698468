<header mat-dialog-title class="u-flex-full">
  <span>
    {{'iva-typologies.Seleziona regime IVA' | translate}}
  </span>
</header>
<mat-dialog-content>
  <iva-typology-list [ivaTypologies]="ivaTypologies" [defaultFilters]="defaultFilters" [displayedColumns]="displayedColumns"
    [total]="total" [canSelect]="true" (onSortChange)="sortChange($event)" (onPageChange)="pageChange($event)"
    (onFilterChange)="filtersChange($event)" (onSelectIvaTypology)="selectIvaTypology($event)"></iva-typology-list>
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">{{'shared.Chiudi' | translate}}</button>
</mat-dialog-actions>
