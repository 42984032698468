<app-iva-typology-filters
  (onFilter)="onFilter($event)"
  [defaultFilters]="defaultFilters"
>
</app-iva-typology-filters>
<mat-card class="u-center-text" *ngIf="!(ivaTypologies | async).length">
  <h4>{{'iva-typologies.Nessun regime IVA trovato' | translate}}</h4>
  <button *ngIf="canAdd" mat-raised-button (click)="addIvaTypology()">
    {{'shared.Aggiungi' | translate}}
  </button>
</mat-card>
<div class="table-container" [hidden]="!(ivaTypologies | async).length">
  <table mat-table matSort [dataSource]="ivaTypologies">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>{{'shared.ID' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        {{ row.id }}
      </td>
    </ng-container>
    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'shared.Codice' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        {{ row.code }}
      </td>
    </ng-container>
    <ng-container matColumnDef="percentage">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'shared.Percentuale' | translate}}</th>
      <td mat-cell *matCellDef="let row">{{ row.percentage }}%</td>
    </ng-container>

    <ng-container matColumnDef="nature">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'iva-typologies.Natura' | translate}}</th>
      <td mat-cell *matCellDef="let row">{{ row.nature }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <button
          *ngIf="canAdd"
          mat-icon-button
          mat-raised-button
          color="accent"
          (click)="$event.stopPropagation(); addIvaTypology()"
        >
          <mat-icon matTooltip="{{'shared.Aggiungi' | translate}}">playlist_add</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="canEdit">
          <button
            mat-icon-button
            (click)="$event.stopPropagation(); editIvaTypology(row)"
          >
            <mat-icon matTooltip="{{'shared.Modifica' | translate}}">{{'iva-typologies.create' | translate}}</mat-icon>
          </button>
        </ng-container>
        <button
          *ngIf="canSelect"
          mat-raised-button
          color="primary"
          (click)="selectIvaTypology(row)"
        >
          {{'shared.Seleziona' | translate}}
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageSizeOptions[0]"
    [length]="total | async"
    showFirstLastButtons
  >
  </mat-paginator>
</div>
