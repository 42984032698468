<header mat-dialog-title class="u-flex-full">
  <span>
    <span *ngIf="ivaTypology">{{'iva-typologies.Modifica regime IVA' | translate}} : {{ivaTypology.code}}</span>
    <span *ngIf="!ivaTypology">{{'iva-typologies.Nuovo regime IVA' | translate}}</span>
  </span>
  <span *ngIf="ivaTypology">
    <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
      <button mat-menu-item (click)="deleteIvaTypology()">
        <mat-icon>delete</mat-icon>
        {{'shared.Elimina' | translate}}
      </button>
    </mat-menu>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="ivaTypologyForm" novalidate>
    <!-- <div class="u-flex-full"> -->
      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'shared.Codice' | translate}}" formControlName="code" type="text" >
        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'shared.Percentuale' | translate}}" formControlName="percentage" type="number" min="0" max="100"  forceNumber>
        <mat-error>{{'iva-typologies.Percentuale non valida' | translate}}</mat-error>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'iva-typologies.Natura' | translate}}" formControlName="nature" type="text">
      </mat-form-field>
    <!-- </div> -->
  </form>
  <!-- {{ivaTypologyForm.value | json}} -->

</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()"
      [disabled]="ivaTypologyForm.pristine">{{'shared.Reset' | translate}}</button>
    <button mat-raised-button color="primary" (click)="save()"
      [disabled]="ivaTypologyForm.pristine || !ivaTypologyForm.valid">{{'shared.Salva' | translate}}</button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">{{'shared.Chiudi' | translate}}</button>
</mat-dialog-actions>
