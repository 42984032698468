<mat-expansion-panel style="margin-bottom: 16px;">
  <mat-expansion-panel-header>
    {{'shared.Filtra' | translate}}
  </mat-expansion-panel-header>
  <form [formGroup]="filtersForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>{{'shared.Ricerca libera' | translate}}</mat-label>
        <input type="text" matInput formControlName="search">
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width" floatLabel="always">
        <mat-label>{{'projects.Data inizio commessa' | translate}}</mat-label>
        <input matInput [matDatepicker]="fromDatePicker1" formControlName="start" />
        <mat-datepicker-toggle matSuffix [for]="fromDatePicker1"></mat-datepicker-toggle>
        <mat-datepicker #fromDatePicker1></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="u-full-width" floatLabel="always">
        <mat-label>{{'projects.Data fine commessa' | translate}} </mat-label>
        <input matInput [matDatepicker]="fromDatePicker2" formControlName="end" />
        <mat-datepicker-toggle matSuffix [for]="fromDatePicker2"></mat-datepicker-toggle>
        <mat-datepicker #fromDatePicker2></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="u-full-width" *ngIf="showPromoFilter">
        <mat-label>{{'shared.Promozione' | translate}}</mat-label>
        <mat-select formControlName="promotion">
          <mat-option value="">{{'shared.-- Tutti --' | translate}}</mat-option>
          <mat-option [value]="true">{{'shared.Sì' | translate}}</mat-option>
          <mat-option [value]="false">{{'shared.No' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="u-full-width" *ngIf="showClientFilter">
        <mat-label>{{'shared.Cliente' | translate}}</mat-label>
        <app-client-field formControlName="client"></app-client-field>
      </mat-form-field>
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>{{'shared.Professionista' | translate}}</mat-label>
        <app-user-field formControlName="professional"></app-user-field>
      </mat-form-field>
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>{{'projects.Archiviate' | translate}}</mat-label>
        <mat-select formControlName="trashed">
          <mat-option value="">{{'shared.-- Tutti --' | translate}}</mat-option>
          <mat-option [value]="true">{{'shared.Sì' | translate}}</mat-option>
          <mat-option [value]="false">{{'shared.No' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>{{'projects.Utente che ha creato la commessa' | translate}}</mat-label>
        <app-user-field formControlName="creator"></app-user-field>
      </mat-form-field>
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>{{'projects.Utente che per ultimo ha modificato la commessa' | translate}}</mat-label>
        <app-user-field formControlName="modifier"></app-user-field>
      </mat-form-field>
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>{{'projects.Chiuse' | translate}}</mat-label>
        <mat-select formControlName="closed">
          <mat-option value="">{{'shared.-- Tutti --' | translate}}</mat-option>
          <mat-option [value]="true">{{'shared.Sì' | translate}}</mat-option>
          <mat-option [value]="false">{{'shared.No' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <span class="u-flex-full">
      <button class="filter" mat-raised-button [disabled]="filtersForm.pristine" color="primary"
        (click)="applyFilters()">{{'shared.Filtra' | translate}}</button>
      <button mat-icon-button *ngIf="!filtersForm.pristine" (click)="resetFilters()"
        matTooltip="{{'shared.Reset' | translate}}">
        <mat-icon>replay</mat-icon>
      </button>
    </span>
  </form>
</mat-expansion-panel>