import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { DefaultValues } from 'src/app/commons/models/default-values.model';
import { AlertService } from 'src/app/commons/services/alert.service';
import * as DefaultValuesActions from 'src/app/store/actions/default-values.actions';
import { AppState } from 'src/app/store/reducers';

@Component({
  selector: 'app-default-values-edit',
  templateUrl: './default-values-edit.component.html',
  styleUrls: ['./default-values-edit.component.scss']
})
export class DefaultValuesEditComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

  @Input()
  defaultValues: DefaultValues;


  // currentDefaultValues: DefaultValues;

  defaultValuesForm: FormGroup;
  constructor(private store$: Store<AppState>, private alertService: AlertService, private fb: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private translateService: TranslateService) { }

  ngOnInit() {
    // if (this.data) {
    //   this.defaultValues = this.data.defaultValues;
    // }

    this._createForm();
    this.ngOnChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // add percentage validators
  private _createForm() {
    let group = {
      bonusClient: ["", Validators.required],
      bonusSales: ["", Validators.required],
      bonusProject: ["", Validators.required],
      advisoryFee: ["", Validators.required],
      kmCost: ["", Validators.required],
      currency: ["", Validators.required],
      ivaTypology: ["", Validators.required],
    }
    this.defaultValuesForm = this.fb.group(group);

    this.defaultValuesForm.get("bonusClient").valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      filter(bonusClient => !!bonusClient)
    ).subscribe((bonusClient) => {
      if((bonusClient + this.defaultValuesForm.get('bonusSales').value + this.defaultValuesForm.get('bonusProject').value) != 100) {
        this.defaultValuesForm.get("bonusClient").setErrors({'incorrect': true})
      } else {
        this.defaultValuesForm.get("bonusClient").setErrors(null);
        this.defaultValuesForm.get("bonusProject").setErrors(null);
        this.defaultValuesForm.get("bonusSales").setErrors(null);
      }
    });
    this.defaultValuesForm.get("bonusSales").valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      filter(bonusSales => !!bonusSales)
    ).subscribe((bonusSales) => {
      if((bonusSales + this.defaultValuesForm.get('bonusClient').value + this.defaultValuesForm.get('bonusProject').value) != 100) {
        this.defaultValuesForm.get("bonusSales").setErrors({'incorrect': true})
      } else {
        this.defaultValuesForm.get("bonusSales").setErrors(null);
        this.defaultValuesForm.get("bonusClient").setErrors(null);
        this.defaultValuesForm.get("bonusProject").setErrors(null);
      }
    });
    this.defaultValuesForm.get("bonusProject").valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      filter(bonusProject => !!bonusProject)
    ).subscribe((bonusProject) => {
      if((bonusProject + this.defaultValuesForm.get('bonusClient').value + this.defaultValuesForm.get('bonusSales').value) != 100) {
        this.defaultValuesForm.get("bonusProject").setErrors({'incorrect': true})
      } else {
        this.defaultValuesForm.get("bonusProject").setErrors(null);
        this.defaultValuesForm.get("bonusSales").setErrors(null);
        this.defaultValuesForm.get("bonusClient").setErrors(null);
      }
    });
  }

  get totalBonuses(): number {
    return (this.defaultValuesForm.get('bonusClient').value + this.defaultValuesForm.get('bonusSales').value + this.defaultValuesForm.get('bonusProject').value);
  }

  private ngOnChanges() {
    if (this.defaultValuesForm) {
      this.defaultValuesForm.reset();
      if (this.defaultValues) {
        this._initFormValues(this.defaultValues);
      }
    }
  }

  private _initFormValues(defaultValues: DefaultValues) {
    if (defaultValues) {
      this.defaultValuesForm.patchValue({
        bonusClient: defaultValues.bonusClient,
        bonusSales: defaultValues.bonusSales,
        bonusProject: defaultValues.bonusProject,
        advisoryFee: defaultValues.advisoryFee,
        kmCost: defaultValues.kmCost,
        currency: defaultValues.currency,
        ivaTypology: defaultValues.ivaTypology
      });
    }
  }


  private _prepareSaveDefaultValues(): DefaultValues {
    let savingDefaultValues: DefaultValues = DefaultValues.fromFormGroup(this.defaultValuesForm, this.defaultValues);
    return savingDefaultValues;
  }

  save() {
    let unsavedEntity = this._prepareSaveDefaultValues();
    this.store$.dispatch(DefaultValuesActions.saveDefaultValues({ defaultValues: unsavedEntity }));
  }

  close() {
    if (this.defaultValuesForm.pristine) {
      this.store$.dispatch(DefaultValuesActions.closeDefaultValuesDialog())
    } else {
      this.alertService
        .showConfirmDialog(
          `${this.translateService.instant('shared.Chiudi')}`,
          `${this.translateService.instant('shared.Ci sono modifiche non salvate. Sei sicuro di voler chiudere?')}`
        )
        .subscribe(result => {
          if (result) {
            this.store$.dispatch(DefaultValuesActions.closeDefaultValuesDialog())
          }
        });
    }
  }

  deleteDefaultValues() {
    if (this.defaultValues) {
      this.store$.dispatch(DefaultValuesActions.deleteDefaultValues({ defaultValues: this.defaultValues.toDTO() }))
    }
  }

  revert() {
    this.ngOnChanges();
  }
}
