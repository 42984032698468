<mat-expansion-panel style="margin-bottom: 16px;">
  <mat-expansion-panel-header>
    {{'shared.Filtra' | translate}}
  </mat-expansion-panel-header>
  <form [formGroup]="filtersForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>{{'shared.Data' | translate}}</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="{{'shared.Inizio' | translate}}" formControlName="start">
          <input matEndDate placeholder="{{'shared.Fine' | translate}}" formControlName="end">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>{{'shared.Cliente' | translate}}</mat-label>
        <app-client-field formControlName="client"></app-client-field>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>{{'shared.Commessa' | translate}}</mat-label>
        <app-project-field formControlName="project" [canSelectClosed]="true"></app-project-field>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>{{'shared.Professionista' | translate}}</mat-label>
        <app-user-field formControlName="user"></app-user-field>
      </mat-form-field>

      <mat-form-field class="u-full-width">
        <mat-label>{{'shared.Promozione' | translate}}</mat-label>
        <mat-select formControlName="promo">
          <mat-option value="">{{'shared.-- Tutte --' | translate}}</mat-option>
          <mat-option [value]="true">{{'shared.Sì' | translate}}</mat-option>
          <mat-option [value]="false">{{'shared.No' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="u-full-width">
        <mat-label>{{'shared.Tipologia' | translate}}</mat-label>
        <mat-select formControlName="types" [multiple]="true">
          <mat-option value="Attività">{{'shared.Attività' | translate}}</mat-option>
          <mat-option value="Spesa">{{'shared.Spesa' | translate}}</mat-option>
          <mat-option value="Bonus">{{'shared.R&D' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <mat-label>{{'shared.Pagabile' | translate}}</mat-label>
        <mat-select formControlName="accountable">
          <mat-option value="">{{'shared.-- Tutte --' | translate}}</mat-option>
          <mat-option [value]="true">{{'shared.Sì' | translate}}</mat-option>
          <mat-option [value]="false">{{'shared.No' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="u-full-width">
        <mat-label>{{'cost-lines.Fatturato' | translate}}</mat-label>
        <mat-select formControlName="invoiced">
          <mat-option value="">{{'shared.-- Tutte --' | translate}}</mat-option>
          <mat-option [value]="true">{{'shared.Sì' | translate}}</mat-option>
          <mat-option [value]="false">{{'shared.No' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="u-full-width">
        <mat-label>{{'cost-lines.Pagato' | translate}}</mat-label>
        <mat-select formControlName="paid">
          <mat-option value="">{{'shared.-- Tutte --' | translate}}</mat-option>
          <mat-option [value]="true">{{'shared.Sì' | translate}}</mat-option>
          <mat-option [value]="false">{{'shared.No' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>{{'shared.Numero fattura' | translate}}</mat-label>
        <input matInput formControlName="invoiceNumber" type="text">
      </mat-form-field>

      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>{{'shared.Data fattura' | translate}}</mat-label>
        <mat-date-range-input [rangePicker]="invoiceDate">
          <input matStartDate placeholder="{{'shared.Inizio' | translate}}" formControlName="invoiceDateStart">
          <input matEndDate placeholder="{{'shared.Fine' | translate}}" formControlName="invoiceDateEnd">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="invoiceDate"></mat-datepicker-toggle>
        <mat-date-range-picker #invoiceDate></mat-date-range-picker>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>{{'cost-lines.Data pagamento fattura professionista' | translate}}</mat-label>
        <mat-date-range-input [rangePicker]="invoicePaymentDate">
          <input matStartDate placeholder="{{'shared.Inizio' | translate}}" formControlName="invoicePaymentDateStart">
          <input matEndDate placeholder="{{'shared.Fine' | translate}}" formControlName="invoicePaymentDateEnd">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="invoicePaymentDate"></mat-datepicker-toggle>
        <mat-date-range-picker #invoicePaymentDate></mat-date-range-picker>
      </mat-form-field>
    </div>

    <div class="u-flex-full">
      <!-- <mat-form-field floatLabel="always" class="u-full-width">
        <input matInput [matDatepicker]="fromDatePicker1" formControlName="invoiceDate" placeholder="Data fattura" />
        <mat-datepicker-toggle matSuffix [for]="fromDatePicker1"></mat-datepicker-toggle>
        <mat-datepicker #fromDatePicker1></mat-datepicker>
      </mat-form-field> -->

      <!-- <mat-form-field floatLabel="always" class="u-full-width">
        <input matInput [matDatepicker]="fromDatePicker2" formControlName="invoicePaymentDate"
          placeholder="Data pagamento fattura" />
        <mat-datepicker-toggle matSuffix [for]="fromDatePicker2"></mat-datepicker-toggle>
        <mat-datepicker #fromDatePicker2></mat-datepicker>
      </mat-form-field> -->


    </div>

    <span class="u-flex-full">
      <button class="filter" mat-raised-button [disabled]="filtersForm.pristine" color="primary"
        (click)="applyFilters()">{{'shared.Filtra' | translate}}</button>
      <button mat-icon-button *ngIf="!filtersForm.pristine" (click)="resetFilters()"
        matTooltip="{{'shared.Reset' | translate}}">
        <mat-icon>replay</mat-icon>
      </button>
    </span>
  </form>
</mat-expansion-panel>