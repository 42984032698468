<header mat-dialog-title class="u-flex-full">
  <span> {{'risks.Seleziona rischio' | translate}} </span>
</header>
<mat-dialog-content>
  <risk-list
    class="sticky-header-in-dialog"
    [risks]="risks"
    [defaultFilters]="defaultFilters"
    [displayedColumns]="displayedColumns"
    [total]="total"
    [canSelect]="true"
    (onSortChange)="sortChange($event)"
    (onPageChange)="pageChange($event)"
    (onFilterChange)="filtersChange($event)"
    (onSelectRisk)="selectRisk($event)"
  ></risk-list>
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span> </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">
    {{'shared.Chiudi' | translate}}
  </button>
</mat-dialog-actions>
