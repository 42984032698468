<header mat-dialog-title class="u-flex-full">
  <span>
    <span *ngIf="user">{{'users.Modifica utente' | translate}} : {{user.fullName}}</span>
    <span *ngIf="!user">{{'users.Nuovo utente' | translate}}</span>
  </span>
  <span *ngIf="user">
    <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
      <button mat-menu-item (click)="changePassword()">
        <mat-icon>vpn_key</mat-icon>
        {{'shared.Cambia password' | translate}}
      </button>
      <button mat-menu-item (click)="deleteUser()">
        <mat-icon>delete</mat-icon>
        {{'shared.Elimina' | translate}}
      </button>
    </mat-menu>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="userForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'shared.Nome' | translate}}" formControlName="name" type="text">
        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'shared.Cognome' | translate}}" formControlName="lastname" type="text">
        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'shared.Email' | translate}}" formControlName="email" type="email">
        <mat-error>{{'shared.Email non valida' | translate}}</mat-error>
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <mat-label>{{'users.Ruolo' | translate}}</mat-label>
        <mat-select formControlName="role">
          <mat-option value="admin">{{('shared.' + ("admin" | userRole)) | translate}}</mat-option>
          <mat-option value="professional">{{('shared.' + ("professional" | userRole)) | translate}}</mat-option>
          <mat-option value="super_user">{{('shared.' + ("super_user" | userRole)) | translate}}</mat-option>
        </mat-select>
        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'users.Ragione sociale' | translate}}" formControlName="companyName" type="text">
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'shared.PEC' | translate}}" formControlName="PEC" type="email">
        <mat-error>{{'users.PEC non valida' | translate}}</mat-error>
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'shared.P.IVA' | translate}}" formControlName="VAT" type="text">
        <mat-error>{{'shared.P.IVA non valida' | translate}}</mat-error>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'users.SDI' | translate}}" formControlName="SDI" type="text">
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'shared.Codice fiscale' | translate}}" formControlName="identifier" type="text">
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <mat-label>{{'users.Seniority' | translate}}</mat-label>
        <mat-select formControlName="seniority">
          <mat-option *ngFor="let seniority of seniorities" [value]="seniority">
            {{'users.'+ seniority | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'shared.Indirizzo' | translate}}" formControlName="address" type="text">
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'shared.CAP' | translate}}" formControlName="CAP" type="text">
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'shared.Città' | translate}}" formControlName="city" type="text">
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'shared.Provincia' | translate}}" formControlName="province" type="text">
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'shared.Nazione' | translate}}" formControlName="country" type="text">
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'shared.Cellulare' | translate}}" formControlName="phone" type="text">
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'shared.Costo giornaliero' | translate}}" formControlName="price" type="number">
        <mat-icon matSuffix *ngIf="userForm?.get('pricePercentage').value">percent</mat-icon>
      </mat-form-field>
      <mat-checkbox matInput color="primary" formControlName="pricePercentage">
        <mat-label>{{'shared.Percentuale' | translate}}</mat-label>
      </mat-checkbox>
    </div>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <mat-label>{{'shared.Valuta compensi' | translate}}</mat-label>
        <app-currency-field formControlName="currency">
        </app-currency-field>
        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <mat-label>{{'users.Separatore per esportazioni' | translate}}</mat-label>
        <mat-select formControlName="exportSeparator">
          <mat-option *ngFor="let separator of separators" [value]="separator">
            {{separator}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-checkbox matInput color="primary" formControlName="active">
        <mat-label>{{'users.Attivo' | translate}}</mat-label>
      </mat-checkbox>
    </div>

    <div *ngIf="!user" class="u-full-width">
      <span class="u-flex-full">
        <mat-form-field class="u-full-width">
          <input type="password" placeholder="{{'shared.Password' | translate}}" matInput formControlName="password">
          <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field class="u-full-width">
          <input type="password" placeholder="{{'users.Conferma password' | translate}}" matInput formControlName="confirmPass">
          <mat-error *ngIf="userForm.controls['confirmPass'].errors?.notEquivalent">{{'users.Le password non coincidono' | translate}}</mat-error>
        </mat-form-field>
      </span>

    </div>
  </form>
  <!-- {{userForm.value | json}}  -->

</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()"
      [disabled]="userForm.pristine">{{'shared.Reset' | translate}}</button>
    <button mat-raised-button color="primary" (click)="save()"
      [disabled]="userForm.pristine || !userForm.valid">{{'shared.Salva' | translate}}</button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">{{'shared.Chiudi' | translate}}</button>
</mat-dialog-actions>