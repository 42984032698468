<ng-container [formGroup]="form">
  <ng-container *ngFor="let control of form.controls; let index = index" [formGroupName]="index">
    <div class="u-full-width action">
      <button mat-icon-button mat-raised-button color="accent"
      (click)="removeAction(index)" matTooltip="{{'shared.Rimuovi attività' | translate}}">
        <mat-icon>delete</mat-icon>
        </button>
        <mat-form-field>
          <input matInput [matDatepicker]="fromDatePicker1" formControlName="date" placeholder="{{'shared.Data' | translate}}" />
          <mat-datepicker-toggle matSuffix [for]="fromDatePicker1"></mat-datepicker-toggle>
          <mat-datepicker #fromDatePicker1></mat-datepicker>
          <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
        </mat-form-field>

        <!-- <mat-form-field>
          <mat-label>Business Opportunity</mat-label>
          <app-business-opportunity-field formControlName="businessOpportunity"></app-business-opportunity-field>
          <mat-error>Campo obbligatorio</mat-error>
        </mat-form-field> -->

        <mat-form-field>
          <input matInput placeholder="{{'shared.Descrizione attività' | translate}}" formControlName="description" type="text" />
          <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{'shared.Offerta' | translate}}</mat-label>
          <app-offer-field formControlName="offer" [offerFilters]="offerFilters"></app-offer-field>
        </mat-form-field>


      <!-- <mat-accordion multi="true">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            Allegati
          </mat-expansion-panel-header>
          <app-action-attachments [actionId]="control?.value?.id">
          </app-action-attachments>
        </mat-expansion-panel>
      </mat-accordion> -->
      <button *ngIf="control?.value?.id" class="delete" mat-raised-button type="button" color="accent" (click)="openAttachments(control)" >
        <span>{{'shared.Allegati' | translate}}</span>
      </button>
    </div>


</ng-container>
</ng-container>
<div class="u-center-text">
  <button mat-raised-button type="button" color="accent" [disabled]="form.invalid" (click)="addAction()">
    <span>{{'actions.Aggiungi attività' | translate}}</span>
  </button>
</div>
