import { Action, createReducer, on } from '@ngrx/store';
import { setLanguage } from '../actions/language.actions';


export interface LanguageState {
  currentLanguage: string;
}

export const initialState: LanguageState = {
  currentLanguage: ''
};

export const languageReducer = createReducer(
  initialState,
  on(setLanguage, (state, { language }) => ({
    ...state,
    currentLanguage: language
  }))
);

export function reducer(state: LanguageState | undefined, action: Action) {
  return languageReducer(state, action);
}
