import { Action, createReducer, on } from '@ngrx/store';

import { ProjectDTO } from '../../commons/models/project.model';
import * as ProjectActions from '../actions/project.actions';
import { ProjectFilters } from './../../commons/models/project.model';

export interface ProjectState {
  list: ProjectDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: ProjectFilters,
  dialogId: string,
  selectionDialogId: string,
  currentProject: ProjectDTO,
  projectId: number,
  currentFormControlName: string,
  bonusPaymentDialogId: string,
};

const initialState: ProjectState = {
  list: [],
  total: 0,
  includes: [
    'currency',
    'payment',
    'risk_identifications',
    'bank_account',
    'client',
    'budgets',
    'iva_typology',
    'professionals',
    'attachments',
    'comments',
    'project_creator',
    'project_modifier',
    'selected_offer',
    'sales_bonuses',
    'project_bonuses',
    'bonus_lines',
    'bonus_lines.user',
    'activities',
    'advisor',
  ],
  currentPage: 1,
  perPage: 50,
  order: null,
  direction: null,
  filters: {
    search: '',
    trashed: false,
  },
  dialogId: null,
  selectionDialogId: null,
  projectId: null,
  currentProject: null,
  currentFormControlName: null,
  bonusPaymentDialogId: null,
};

const projectReducer = createReducer(
  initialState,
  on(ProjectActions.loadProjectsCompleted, (state, { projects, currentPage, total, perPage, order, direction, filters, includes }) => {
    return { ...state, list: projects, currentPage, total, perPage, order, direction, filters, includes };
  }),
  on(ProjectActions.projectDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(ProjectActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(ProjectActions.selectionDialogOpened, (state, { selectionDialogId }) => {
    return { ...state, selectionDialogId };
  }),
  on(ProjectActions.bonusPaymentDialogOpened, (state, { bonusPaymentDialogId }) => {
    return { ...state, bonusPaymentDialogId };
  }),
  on(ProjectActions.showProject, (state, { currentProject }) => {
    return { ...state, currentProject };
  }),
  on(ProjectActions.bonusPayment, (state, { projectId }) => {
    return { ...state, projectId };
  }),
  on(ProjectActions.addProject, (state) => {
    return { ...state, currentProject: null };
  }),
  on(ProjectActions.loadCurrentProjectCompleted, (state, { currentProject }) => {
    return { ...state, currentProject };
  }),
  on(ProjectActions.loadProjectCompleted, (state, { project }) => {
    return { ...state, currentProject: project };
  }),
  on(ProjectActions.selectProject, (state, { filters, currentFormControlName }) => {
    return { ...state, currentPage: 1, filters, currentFormControlName };
  }),
  on(ProjectActions.archiveProjectCompleted, (state, { project }) => {
    return {
      ...state,
      list: state.list.map((p) =>
        p.id === project.id ? { ...p, deleted_at: project.deleted_at } : p
      ),
      currentProject:
        state.currentProject && state.currentProject.id === project.id
          ? { ...state.currentProject, deleted_at: project.deleted_at }
          : state.currentProject,
    };
  }),

  on(ProjectActions.restoreProjectCompleted, (state, { project }) => {
    return {
      ...state,
      list: state.list.map((p) =>
        p.id === project.id ? { ...p, deleted_at: project.deleted_at } : p
      ),
      currentProject:
        state.currentProject && state.currentProject.id === project.id
          ? { ...state.currentProject, deleted_at: project.deleted_at }
          : state.currentProject,
    };
  }),
);

export function reducer(state: ProjectState | undefined, action: Action) {
  return projectReducer(state, action);
}

