<header mat-dialog-title class="u-flex-full">
  <span>
    <span *ngIf="currency">{{'currencies.Modifica valuta' | translate}} : {{currency.name}}</span>
    <span *ngIf="!currency">{{'currencies.Nuova valuta' | translate}}</span>
  </span>
  <span *ngIf="currency">
    <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
      <button mat-menu-item (click)="deleteCurrency()">
        <mat-icon>delete</mat-icon>
        {{'shared.Elimina' | translate}}
      </button>
    </mat-menu>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="currencyForm" novalidate>
    <div class="u-flex-full">
    <mat-form-field class="u-full-width">
      <input matInput placeholder="{{'shared.Codice valuta' | translate}}" formControlName="currencyCode" type="text">
      <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
    </mat-form-field>
    <mat-form-field class="u-full-width">
      <input matInput placeholder="{{'shared.Nome' | translate}}" formControlName="name" type="text">
      <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
    </mat-form-field>
    <!--
    <mat-form-field class="u-full-width">
      <input matInput placeholder="Tasso di cambio" formControlName="exchangeRate" type="number" min="0" forceNumber>
      <mat-error>Campo obbligatorio</mat-error>
    </mat-form-field>
    -->
    </div>
    <!--
    <p class="u-center-text" *ngIf="currencyForm?.value?.currencyCode">
      Tasso di cambio: 1 {{currencyForm.value.currencyCode}} = {{currencyForm.value.exchangeRate}} Euro
    </p>
    -->
  </form>
    <!-- {{currencyForm.value | json}} -->
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()"
      [disabled]="currencyForm.pristine">{{'shared.Reset' | translate}}</button>
    <button mat-raised-button color="primary" (click)="save()"
      [disabled]="currencyForm.pristine || !currencyForm.valid">{{'shared.Salva' | translate}}</button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">{{'shared.Chiudi' | translate}}</button>
</mat-dialog-actions>
