<header mat-dialog-title class="u-flex-full">
  <h2 class="u-flex-full">
  <span>
    <mat-icon>attachment</mat-icon>{{'shared.Allegati' | translate}}
  </span>
</h2>
</header>
<mat-dialog-content>
  <div>
    <action-attachment-list [actionAttachments]="actionAttachments" [defaultFilters]="defaultFilters" [total]="total"
      [canAdd]="true" [canEdit]="true" (onSortChange)="sortChange($event)" (onPageChange)="pageChange($event)"
      (onFilterChange)="filtersChange($event)" (onAddActionAttachment)="addActionAttachment()"
      (onEditActionAttachment)="editActionAttachment($event)">
    </action-attachment-list>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">{{'shared.Chiudi' | translate}}</button>
</mat-dialog-actions>
