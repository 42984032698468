
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { OfferDuplicateComponent } from 'src/app/modules/home/offers/offer-duplicate/offer-duplicate.component';
import { OfferComponent } from 'src/app/modules/home/offers/offer/offer.component';
import { OfferSelectionComponent } from 'src/app/modules/shared/offer-selection/offer-selection.component';
import { AlertService } from '../../commons/services/alert.service';
import { LaravelOfferService } from '../../commons/services/backend/laravel-offer.service';
import * as OfferActions from '../actions/offer.actions';
import * as RouterActions from '../actions/router.actions';
import { AppState } from '../reducers';
import { getOfferDialogId, getOffersTableState, getSelectionDialogId } from '../selectors/offer.selectors';
import { getOfferId } from '../selectors/offer.selectors';
import { TranslateService } from '@ngx-translate/core';


@Injectable()
export class OfferEffects {

  error$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OfferActions.saveOfferFailed),
      tap(({ error }) => {
        if (error) {
          this.alertService.showErrorMessage(`${this.translateService.instant('shared.errore')}`, error);
        }
      })
    ), { dispatch: false }
  );

  loadOffers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OfferActions.loadOffers),
      switchMap(({ page, perPage, order, direction, filters, includes }) => {
        return this.offerService.list(page, perPage, order, direction, filters, includes)
          .pipe(
            map(result =>
              OfferActions.loadOffersCompleted({ offers: result.data, currentPage: page, total: result.total, perPage, order, direction, filters, includes })
            ),
            catchError(error => {
              return of(OfferActions.loadOffersFailed({ error }))
            })
          )
      })
    )
  );
  loadSelectionOffers$ = createEffect(() =>
  this.actions$.pipe(
    ofType(OfferActions.loadSelectionOffers),
    switchMap(({ page, perPage, order, direction, filters, includes }) => {
      return this.offerService.list(page, perPage, order, direction, filters, includes)
        .pipe(
          map(result =>
            OfferActions.loadSelectionOffersCompleted({ offers: result.data, currentPage: page, total: result.total, perPage, order, direction, filters, includes })
          ),
          catchError(error => {
            return of(OfferActions.loadSelectionOffersFailed({ error }))
          })
        )
    })
  )
);

  changePage = createEffect(() =>
    this.actions$.pipe(
      ofType(OfferActions.changePage),
      withLatestFrom(this.store$.select(getOffersTableState)),
      map(([{ page, size }, { total, currentPage, perPage, direction, order, filters, includes }]) => OfferActions.loadOffers({ page: page, perPage: size, order, direction, filters, includes }))
    )
  );

  changeSort = createEffect(() =>
    this.actions$.pipe(
      ofType(OfferActions.changeSort),
      withLatestFrom(this.store$.select(getOffersTableState)),
      map(([action, { total, currentPage, perPage, direction, order, filters, includes }]) => OfferActions.loadOffers({ page: currentPage, perPage: perPage, order: action.order, direction: action.direction, filters, includes }))
    )
  );

  changeFilters = createEffect(() =>
    this.actions$.pipe(
      ofType(OfferActions.changeFilters),
      withLatestFrom(this.store$.select(getOffersTableState)),
      map(([{ filters }, { total, currentPage, perPage, direction, order, includes }]) => OfferActions.loadOffers({ page: currentPage, perPage: perPage, order, direction, filters, includes }))
    )
  );
  changeSelectionFilters = createEffect(() =>
  this.actions$.pipe(
    ofType(OfferActions.changeSelectionFilters),
    withLatestFrom(this.store$.select(getOffersTableState)),
    map(([{ filters }, { total, currentPage, perPage, direction, order, includes }]) => OfferActions.loadSelectionOffers({ page: currentPage, perPage: perPage, order, direction, filters, includes }))
  )
);
  
  
  editOffer$ = createEffect(() => this.actions$.pipe(
    ofType(OfferActions.editOffer),
    map(({ offer }) => {
      let dialogRef = this.dialog.open(OfferComponent, {
        data: {
          offer
        },
        width: '100%',
      });
      return OfferActions.offerDialogOpened({ dialogId: dialogRef.id });
    }))
  );

  showOffer = createEffect(() =>
    this.actions$.pipe(
      ofType(OfferActions.showOffer),
      map(({ offer }) => RouterActions.routerGo({ path: ['/offers/', offer.id] })),
    )
  );

  addOffer = createEffect(() =>
    this.actions$.pipe(
      ofType(OfferActions.addOffer),
      map(() => RouterActions.routerGo({ path: ['/offers/new'] })),
    )
  );

  loadOffer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OfferActions.loadOffer),
      switchMap(({ id }) => {
        return this.offerService.show(id)
          .pipe(
            map(result =>
              OfferActions.loadOfferCompleted({ offer: result })
            ),
            catchError(error => {
              return of(OfferActions.loadOfferFailed({ error }))
            })
          )
      })
    )
  );

  loadOfferCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OfferActions.loadOfferCompleted),
      map((offer) => OfferActions.showOffer(offer))
    )
  );


  selectOffer$ = createEffect(() => this.actions$.pipe(
    ofType(OfferActions.selectOffer),
    map(({ filters }) => {
      let dialogRef = this.dialog.open(OfferSelectionComponent, {
        data: {
          defaultFilters: filters
        }
      });
      return OfferActions.selectionDialogOpened({ selectionDialogId: dialogRef.id });
    }))
  );

  saveOffer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OfferActions.saveOffer),
      mergeMap(({ offer }) =>
        this.offerService.upsert(offer.toDTO())
          .pipe(
            map(result =>
              OfferActions.saveOfferCompleted({ offer: result })
            ),
            catchError(error => of(OfferActions.saveOfferFailed({ error })))
          )
      )
    )
  );

  duplicateOffer$ = createEffect(() => this.actions$.pipe(
    ofType(OfferActions.duplicateOffer),
    map(({ offer }) => {
      let dialogRef = this.dialog.open(OfferDuplicateComponent, {
        data: {
          offer
        },
        width: '400px',
      });
      return OfferActions.offerDialogOpened({ dialogId: dialogRef.id });
    }))
  );


  onSaveCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OfferActions.saveOfferCompleted),
      map(action => action.offer),
      tap(offer => this.alertService.showConfirmMessage(`${this.translateService.instant('offers.Offerta salvata', {total:offer.title})}`)),
      // tap(offer => this.store$.dispatch(RouterActions.routerGo({ path: ['/offers'] }))),
      map(() => OfferActions.closeOfferDialog())
    )
  );


  deleteOffer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OfferActions.deleteOffer),
      switchMap(({ offer }) =>
        this.alertService.showConfirmDialog(`${this.translateService.instant('shared.Conferma eliminazione')}`, `${this.translateService.instant('offers.Sei sicuro di voler eliminare offerta')} ${offer.title}?`)
          .pipe(
            mergeMap((confirm) => {
              return confirm ?
                this.offerService.delete(offer.id)
                  .pipe(
                    map(() => OfferActions.deleteOfferCompleted({ offer })),
                    catchError(error => of(OfferActions.deleteOfferFailed({ error })))
                  )

                : of(OfferActions.deleteOfferCancelled());
            })
          )
      )
    )
  );

  onDeleteCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OfferActions.deleteOfferCompleted),
      tap(({ offer }) => this.alertService.showConfirmMessage(`${this.translateService.instant('offers.Offerta eliminata', {total:offer.title})}`)),
      map(() => OfferActions.closeOfferDialog())
    )
  );


  closeDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(OfferActions.closeOfferDialog),
      withLatestFrom(this.store$.select(getOfferDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId)?.close();
        }
      })
    ), { dispatch: false }
  );

  reloadAfterSave = createEffect(() =>
    this.actions$.pipe(
      ofType(OfferActions.saveOfferCompleted),
      withLatestFrom(this.store$.select(getOffersTableState)),
      map(([_, { currentPage, perPage, direction, order, filters, includes }]) => OfferActions.loadOffers({ page: currentPage, perPage, order, direction, filters, includes }))
    )
  );

  reloadAfterDelete = createEffect(() =>
    this.actions$.pipe(
      ofType(OfferActions.deleteOfferCompleted),
      withLatestFrom(this.store$.select(getOffersTableState)),
      map(([_, { currentPage, perPage, direction, order, filters, includes }]) => OfferActions.loadOffers({ page: currentPage, perPage, order, direction, filters, includes }))
    )
  );


  closeSelectionDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(OfferActions.closeSelectionDialog),
      withLatestFrom(this.store$.select(getSelectionDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }

      })
    ), { dispatch: false }
  );

  offersSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OfferActions.offerSelected),
      map(() => OfferActions.closeSelectionDialog())
    ));

    offerVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OfferActions.offerVersion),
      switchMap(({ id }) => {
        return this.offerService.version(id)
          .pipe(
            map(result =>
              OfferActions.offerVersionCompleted({ version: result })
            ),
            catchError(error => {
              return of(OfferActions.offerVersionFailed())
            })
          )
      })
    )
  ); 


  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private offerService: LaravelOfferService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private translateService: TranslateService
  ) { }
}
