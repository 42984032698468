import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { Observable } from 'rxjs';

import { Project } from '../../../commons/models/project.model';
import { PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';
import { ProjectFilters } from './../../../commons/models/project.model';

export type ProjectsColumn = "identifier" | "description" | "client" | "professionals" | "headProject" | "start" | "end" | "hours" | "actions";

@Component({
  selector: 'project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss'],
})
export class ProjectListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  pageSizeOptions = PAGE_SIZE_OPTIONS;

  @Input()
  projects: Observable<Project[]>;
  @Input()
  showPromoFilter: boolean = true;
  @Input()
  showClientFilter: boolean = true;

  @Input()
  defaultFilters: ProjectFilters;

  @Input()
  page: number = 1;

  @Input()
  pageSize: number = this.pageSizeOptions[0];

  @Input()
  order: string = 'identifier';

  @Input()
  direction: SortDirection = 'desc';

  expandedElement: Project | null;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: ProjectsColumn[] = ["identifier", "client", "description", "professionals", "headProject", "start", "end", "hours", "actions"];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;
  @Input()
  canSelectClosed: boolean = true;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<ProjectFilters> = new EventEmitter<ProjectFilters>();

  @Output()
  onSelectProject: EventEmitter<Project> = new EventEmitter<Project>();
  @Output()
  onAddProject: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditProject: EventEmitter<Project> = new EventEmitter<Project>();
  @Output()
  onShowProject: EventEmitter<Project> = new EventEmitter<Project>();

  constructor() { }


  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  addProject() {
    this.onAddProject.emit();
  }

  editProject(project: Project) {
    this.onEditProject.emit(project);
  }

  selectProject(project: Project) {
    this.onSelectProject.emit(project);
  }
  showProject(project: Project) {
    this.onShowProject.emit(project);
  }

  onFilter(filters: ProjectFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (this.paginator) {
      this.paginator.pageIndex = this.page - 1;
      this.paginator.pageSize = this.pageSize;
    }
    if (this.sort) {
      this.sort.active = this.order;
      this.sort.direction = this.direction;
    }
  }
}
