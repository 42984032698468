import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { ActionAttachmentEditComponent } from 'src/app/modules/home/actions/action-attachments/action-attachment-edit/action-attachment-edit.component';
import { ActionAttachmentSelectionComponent } from 'src/app/modules/shared/action-attachment-selection/action-attachment-selection.component';
import { AlertService } from '../../commons/services/alert.service';
import { LaravelActionAttachmentService } from '../../commons/services/backend/laravel-action-attachment.service';
import * as ActionAttachmentActions from '../actions/action-attachment.actions';
import { AppState } from '../reducers';
import { getActionAttachmentDialogId, getActionAttachmentsTableState, getAttachmentsDialogId, getSelectionDialogId } from '../selectors/action-attachment.selectors';
import * as ActivitiesActions from '../actions/action.actions';
import * as ActionSelectors from '../selectors/action.selectors';
import { ActionAttachmentsComponent } from 'src/app/modules/home/actions/action-attachments/action-attachments.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ActionAttachmentEffects {

  error$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionAttachmentActions.saveActionAttachmentFailed),
      tap(({ error }) => {
        if (error) {
          this.alertService.showErrorMessage(`${this.translateService.instant('shared.errore')}`, error);
        }
      })
    ), { dispatch: false }
  );

  loadActionAttachments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionAttachmentActions.loadActionAttachments),
      switchMap(({ page, perPage, order, direction, filters, includes }) => {
        return this.actionAttachmentService.list(page, perPage, order, direction, filters, includes)
          .pipe(
            map(result =>
              ActionAttachmentActions.loadActionAttachmentsCompleted({ actionAttachments: result.data, currentPage: page, total: result.total, perPage, order, direction, filters, includes })
            ),
            catchError(error => {
              return of(ActionAttachmentActions.loadActionAttachmentsFailed({ error }))
            })
          )
      })
    )
  );

  changePage = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionAttachmentActions.changePage),
      withLatestFrom(this.store$.select(getActionAttachmentsTableState)),
      map(([{ page, size }, { total, currentPage, perPage, direction, order, filters, includes }]) => ActionAttachmentActions.loadActionAttachments({ page: page, perPage: size, order, direction, filters, includes }))
    )
  );

  changeSort = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionAttachmentActions.changeSort),
      withLatestFrom(this.store$.select(getActionAttachmentsTableState)),
      map(([action, { total, currentPage, perPage, direction, order, filters, includes }]) => ActionAttachmentActions.loadActionAttachments({ page: currentPage, perPage: perPage, order: action.order, direction: action.direction, filters, includes }))
    )
  );

  changeFilters = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionAttachmentActions.changeFilters),
      withLatestFrom(this.store$.select(getActionAttachmentsTableState)),
      map(([{ filters }, { total, currentPage, perPage, direction, order, includes }]) => ActionAttachmentActions.loadActionAttachments({ page: currentPage, perPage: perPage, order, direction, filters, includes }))
    )
  );

  editActionAttachment$ = createEffect(() => this.actions$.pipe(
    ofType(ActionAttachmentActions.editActionAttachment),
    map(({ actionAttachment, actionId }) => {
      let dialogRef = this.dialog.open(ActionAttachmentEditComponent, {
        data: {
          actionAttachment,
          actionId
        },
        width: '400px',
      });
      return ActionAttachmentActions.actionAttachmentDialogOpened({ dialogId: dialogRef.id });
    }))
  );

  openActionAttachment$ = createEffect(() => this.actions$.pipe(
    ofType(ActionAttachmentActions.openActionAttachment),
    map(({ actionId }) => {
      let dialogRef = this.dialog.open(ActionAttachmentsComponent, {
        data: {
          actionId
        },
        width: "80%"
      });
      return ActionAttachmentActions.actionAttachmentsDialogOpened({ attachmentsDialogId: dialogRef.id });
    }))
  );
  closeAttachmentsDialog = createEffect(() =>
  this.actions$.pipe(
    ofType(ActionAttachmentActions.closeActionAttachmentsDialog),
    withLatestFrom(this.store$.select(getAttachmentsDialogId)),
    tap(([_, attachmentsDialogId]) => {
      if (attachmentsDialogId) {
        this.dialog.getDialogById(attachmentsDialogId).close();
      }
    })
  ), { dispatch: false }
);

  saveActionAttachment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionAttachmentActions.saveActionAttachment),
      mergeMap(({ actionAttachment }) =>
        this.actionAttachmentService.upsert(actionAttachment.toDTO())
          .pipe(
            map(result =>
              ActionAttachmentActions.saveActionAttachmentCompleted({ actionAttachment: result })
            ),
            catchError(error => of(ActionAttachmentActions.saveActionAttachmentFailed({ error })))
          )
      )
    )
  );

  // loadCurrentActivitiesAfterSave$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(ActionAttachmentActions.saveActionAttachmentCompleted, ActionAttachmentActions.deleteActionAttachmentCompleted),
  //     withLatestFrom(this.store$.select(ActionSelectors.getSelectedAction)),
  //     map(([_, action]) => ActivitiesActions.loadAction({ id: action.id }))
  //   )
  // );

  onSaveCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionAttachmentActions.saveActionAttachmentCompleted),
      map(action => action.actionAttachment),
      tap(actionAttachment => this.alertService.showConfirmMessage(`${this.translateService.instant('shared.Attività salvata', {total:actionAttachment.id})}`)),
      map(() => ActionAttachmentActions.closeActionAttachmentDialog())
    )
  );


  deleteActionAttachment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionAttachmentActions.deleteActionAttachment),
      switchMap(({ actionAttachment }) =>
        this.alertService.showConfirmDialog(`${this.translateService.instant('shared.Conferma eliminazione')}`, `${this.translateService.instant('actions.Sei sicuro di voler eliminare attività')} ${actionAttachment.id}?`)
          .pipe(
            mergeMap((confirm) => {
              return confirm ?
                this.actionAttachmentService.delete(actionAttachment.id)
                  .pipe(
                    map(() => ActionAttachmentActions.deleteActionAttachmentCompleted({ actionAttachment })),
                    catchError(error => of(ActionAttachmentActions.deleteActionAttachmentFailed({ error })))
                  )

                : of(ActionAttachmentActions.deleteActionAttachmentCancelled());
            })
          )
      )
    )
  );

  onDeleteCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionAttachmentActions.deleteActionAttachmentCompleted),
      tap(({ actionAttachment }) => this.alertService.showConfirmMessage(`${this.translateService.instant('shared.Attività eliminata', {total: actionAttachment.id})}`)),
      map(() => ActionAttachmentActions.closeActionAttachmentDialog())
    )
  );


  closeDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionAttachmentActions.closeActionAttachmentDialog),
      withLatestFrom(this.store$.select(getActionAttachmentDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }
      })
    ), { dispatch: false }
  );

  reloadAfterSave = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionAttachmentActions.saveActionAttachmentCompleted),
      withLatestFrom(this.store$.select(getActionAttachmentsTableState)),
      map(([_, { currentPage, perPage, direction, order, filters, includes }]) => ActionAttachmentActions.loadActionAttachments({ page: currentPage, perPage, order, direction, filters, includes }))
    )
  );

  reloadAfterDelete = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionAttachmentActions.deleteActionAttachmentCompleted),
      withLatestFrom(this.store$.select(getActionAttachmentsTableState)),
      map(([_, { currentPage, perPage, direction, order, filters, includes }]) => ActionAttachmentActions.loadActionAttachments({ page: currentPage, perPage, order, direction, filters, includes }))
    )
  );


  selectActionAttachment$ = createEffect(() => this.actions$.pipe(
    ofType(ActionAttachmentActions.selectActionAttachment),
    map(({ filters }) => {
      let dialogRef = this.dialog.open(ActionAttachmentSelectionComponent, {
        data: {
          defaultFilters: filters
        }
      });
      return ActionAttachmentActions.selectionDialogOpened({ selectionDialogId: dialogRef.id });
    }))
  );

  closeSelectionDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionAttachmentActions.closeSelectionDialog),
      withLatestFrom(this.store$.select(getSelectionDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }

      })
    ), { dispatch: false }
  );

  actionAttachmentsSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionAttachmentActions.actionAttachmentSelected),
      map(() => ActionAttachmentActions.closeSelectionDialog())
    ))


  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private actionAttachmentService: LaravelActionAttachmentService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private translateService: TranslateService
  ) { }
}
