
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { IvaTypologyEditComponent } from 'src/app/modules/home/iva-typologies/iva-typology-edit/iva-typology-edit.component';
import { AlertService } from '../../commons/services/alert.service';
import { LaravelIvaTypologyService } from 'src/app/commons/services/backend/laravel-iva-typology.service'
import * as IvaTypologyActions from '../actions/iva-typology.actions';
import { AppState } from '../reducers';
import { getIvaTypologyDialogId, getIvaTypologiesTableState, getSelectionDialogId } from '../selectors/iva-typology.selectors';
import { IvaTypologySelectionComponent } from 'src/app/modules/shared/iva-typology-selection/iva-typology-selection.component';
import { TranslateService } from '@ngx-translate/core';


@Injectable()
export class IvaTypologyEffects {

  error$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IvaTypologyActions.saveIvaTypologyFailed),
      tap(({ error }) => {
        if (error) {
          this.alertService.showErrorMessage(`${this.translateService.instant('shared.errore')}`, error);
        }
      })
    ), { dispatch: false }
  );

  loadIvaTypologies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IvaTypologyActions.loadIvaTypologies),
      switchMap(({ page, perPage, order, direction, filters, includes }) => {
        return this.ivaTypologyService.list(page, perPage, order, direction, filters, includes)
          .pipe(
            map(result =>
              IvaTypologyActions.loadIvaTypologiesCompleted({ ivaTypologies: result.data, currentPage: page, total: result.total, perPage, order, direction, filters, includes })
            ),
            catchError(error => {
              return of(IvaTypologyActions.loadIvaTypologiesFailed({ error }))
            })
          )
      })
    )
  );

  changePage = createEffect(() =>
    this.actions$.pipe(
      ofType(IvaTypologyActions.changePage),
      withLatestFrom(this.store$.select(getIvaTypologiesTableState)),
      map(([{ page, size }, { total, currentPage, perPage, direction, order, filters, includes }]) => IvaTypologyActions.loadIvaTypologies({ page: page, perPage: size, order, direction, filters, includes }))
    )
  );

  changeSort = createEffect(() =>
    this.actions$.pipe(
      ofType(IvaTypologyActions.changeSort),
      withLatestFrom(this.store$.select(getIvaTypologiesTableState)),
      map(([action, { total, currentPage, perPage, direction, order, filters, includes }]) => IvaTypologyActions.loadIvaTypologies({ page: currentPage, perPage: perPage, order: action.order, direction: action.direction, filters, includes }))
    )
  );

  changeFilters = createEffect(() =>
    this.actions$.pipe(
      ofType(IvaTypologyActions.changeFilters),
      withLatestFrom(this.store$.select(getIvaTypologiesTableState)),
      map(([{ filters }, { total, currentPage, perPage, direction, order, includes }]) => IvaTypologyActions.loadIvaTypologies({ page: currentPage, perPage: perPage, order, direction, filters, includes }))
    )
  );

  editIvaTypology$ = createEffect(() => this.actions$.pipe(
    ofType(IvaTypologyActions.editIvaTypology),
    map(({ ivaTypology }) => {
      let dialogRef = this.dialog.open(IvaTypologyEditComponent, {
        data: {
          ivaTypology
        },
        width: '600px',
      });
      return IvaTypologyActions.ivaTypologyDialogOpened({ dialogId: dialogRef.id });
    }))
  );

  saveIvaTypology$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IvaTypologyActions.saveIvaTypology),
      mergeMap(({ ivaTypology }) =>
        this.ivaTypologyService.upsert(ivaTypology.toDTO())
          .pipe(
            map(result =>
              IvaTypologyActions.saveIvaTypologyCompleted({ ivaTypology: result })
            ),
            catchError(error => of(IvaTypologyActions.saveIvaTypologyFailed({ error })))
          )
      )
    )
  );

  onSaveCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IvaTypologyActions.saveIvaTypologyCompleted),
      map(action => action.ivaTypology),
      tap(ivaTypology => this.alertService.showConfirmMessage(`${this.translateService.instant('shared.Tipologia')} ${ivaTypology.code} ${this.translateService.instant('shared.salvata con successo')}`)),
      map(() => IvaTypologyActions.closeIvaTypologyDialog())
    )
  );


  deleteIvaTypology$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IvaTypologyActions.deleteIvaTypology),
      switchMap(({ ivaTypology }) =>
        this.alertService.showConfirmDialog(`${this.translateService.instant('shared.Conferma eliminazione')}`, `${this.translateService.instant('iva-typologies.Sei sicuro di voler eliminare la tipologia')} ${ivaTypology.code}?`)
          .pipe(
            mergeMap((confirm) => {
              return confirm ?
                this.ivaTypologyService.delete(ivaTypology.id)
                  .pipe(
                    map(() => IvaTypologyActions.deleteIvaTypologyCompleted({ ivaTypology })),
                    catchError(error => of(IvaTypologyActions.deleteIvaTypologyFailed({ error })))
                  )

                : of(IvaTypologyActions.deleteIvaTypologyCancelled());
            })
          )
      )
    )
  );

  onDeleteCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IvaTypologyActions.deleteIvaTypologyCompleted),
      tap(({ ivaTypology }) => this.alertService.showConfirmMessage(`${this.translateService.instant('iva-typologies.Tipologia eliminata', {total:ivaTypology.code})}`)),
      map(() => IvaTypologyActions.closeIvaTypologyDialog())
    )
  );


  closeDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(IvaTypologyActions.closeIvaTypologyDialog),
      withLatestFrom(this.store$.select(getIvaTypologyDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }
      })
    ), { dispatch: false }
  );

  reloadAfterSave = createEffect(() =>
    this.actions$.pipe(
      ofType(IvaTypologyActions.saveIvaTypologyCompleted),
      withLatestFrom(this.store$.select(getIvaTypologiesTableState)),
      map(([_, { currentPage, perPage, direction, order, filters, includes }]) => IvaTypologyActions.loadIvaTypologies({ page: currentPage, perPage, order, direction, filters, includes }))
    )
  );

  reloadAfterDelete = createEffect(() =>
    this.actions$.pipe(
      ofType(IvaTypologyActions.deleteIvaTypologyCompleted),
      withLatestFrom(this.store$.select(getIvaTypologiesTableState)),
      map(([_, { currentPage, perPage, direction, order, filters, includes }]) => IvaTypologyActions.loadIvaTypologies({ page: currentPage, perPage, order, direction, filters, includes }))
    )
  );

  selectIvaTypology$ = createEffect(() => this.actions$.pipe(
    ofType(IvaTypologyActions.selectIvaTypology),
    map(({ filters }) => {
      let dialogRef = this.dialog.open(IvaTypologySelectionComponent, {
        data: {
          defaultFilters: filters
        }
      });
      return IvaTypologyActions.selectionDialogOpened({ selectionDialogId: dialogRef.id });
    }))
  );


  closeSelectionDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(IvaTypologyActions.closeSelectionDialog),
      withLatestFrom(this.store$.select(getSelectionDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }

      })
    ), { dispatch: false }
  );

  ivaTypologiesSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IvaTypologyActions.ivaTypologySelected),
      map(() => IvaTypologyActions.closeSelectionDialog())
    ))


  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private ivaTypologyService: LaravelIvaTypologyService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private translateService: TranslateService
  ) { }
}
