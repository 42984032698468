<!-- <header mat-dialog-title class="u-flex-full">
  <span>
    <span *ngIf="defaultValues">Modifica valori predefiniti</span>
    <span *ngIf="!defaultValues">Nuovi valori predifiniti</span>
  </span>
  <span *ngIf="defaultValues">
    <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
      <button mat-menu-item (click)="deleteDefaultValues()">
        <mat-icon>delete</mat-icon>
        Elimina
      </button>
    </mat-menu>
  </span>
</header> -->
<mat-dialog-content>
  <form [formGroup]="defaultValuesForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input
          matInput
          placeholder="{{'shared.Bonus Cliente' | translate}}"
          formControlName="bonusClient"
          type="number"
          min="0"
          max="100"
          forceNumber
        />
        <mat-icon matSuffix>percent</mat-icon>
        <mat-error
          *ngIf="defaultValuesForm.controls['bonusClient'].errors?.incorrect"
          >{{'shared.La somma dei bonus è uguale a' | translate:  {total: totalBonuses} }}</mat-error
        >
      </mat-form-field>

      <mat-form-field class="u-full-width">
        <input
          matInput
          placeholder="{{'default-values.Bonus Vendite' | translate}}"
          formControlName="bonusSales"
          type="number"
          min="0"
          max="100"
          forceNumber
        />
        <mat-icon matSuffix>percent</mat-icon>
        <mat-error
          *ngIf="defaultValuesForm.controls['bonusSales'].errors?.incorrect"
          >{{'shared.La somma dei bonus è uguale a' | translate: { total: totalBonuses } }}</mat-error
        >
      </mat-form-field>

      <mat-form-field class="u-full-width">
        <input
          matInput
          placeholder="{{'default-values.Bonus Progetto' | translate}}"
          formControlName="bonusProject"
          type="number"
          min="0"
          max="100"
          forceNumber
        />
        <mat-icon matSuffix>percent</mat-icon>
        <mat-error
          *ngIf="defaultValuesForm.controls['bonusProject'].errors?.incorrect"
          >{{'shared.La somma dei bonus è uguale a' | translate: { total: totalBonuses} }}</mat-error
        >
      </mat-form-field>
    </div>

    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input
          matInput
          placeholder="{{'default-values.Advisory fee' | translate}}"
          formControlName="advisoryFee"
          type="number"
          min="0"
          max="100"
          forceNumber
        />
        <mat-icon matSuffix>percent</mat-icon>
        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input
          matInput
          placeholder="{{'shared.Costo € / km' | translate}}"
          formControlName="kmCost"
          type="number"
          forceNumber
        />
        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
      </mat-form-field>

      <mat-form-field class="u-full-width">
        <app-currency-field
          placeholder="{{'shared.Valuta' | translate}}"
          [formControl]="defaultValuesForm.get('currency')"
          label="'shared.Valuta' | translate"
          [required]="true"
        >
        </app-currency-field>
        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
      </mat-form-field>

      <mat-form-field class="u-full-width">
        <app-iva-typology-field
          placeholder="{{'shared.Regime IVA' | translate}}"
          [formControl]="defaultValuesForm.get('ivaTypology')"
          label="'shared.Regime IVA' | translate"
          [required]="true"
        >
        </app-iva-typology-field>
        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
      </mat-form-field>
    </div>
  </form>
  <!-- {{defaultValuesForm.value | json}} -->
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button
      mat-raised-button
      color="basic"
      type="reset"
      (click)="revert()"
      [disabled]="defaultValuesForm.pristine"
    >
      {{'shared.Reset' | translate}}
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="save()"
      [disabled]="defaultValuesForm.pristine || !defaultValuesForm.valid"
    >
    {{'shared.Salva' | translate}}
    </button>
    <span class="fill-remaining"></span>
  </span>
</mat-dialog-actions>
