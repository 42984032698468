<ng-container [formGroup]="form">
  {{form.controls.values | json}}
  <ng-container *ngFor="let control of form.controls; let index = index" [formGroupName]="index">
    <div class="u-flex-full">
      <!-- <mat-form-field>
        <mat-label>Importo</mat-label>
        <input matInput placeholder="Importo" formControlName="amount">
        <mat-icon matSuffix>euro</mat-icon>
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field> -->
      <strong style="white-space: nowrap;">{{control.value.amount | customCurrency}}&nbsp;€</strong>
      <mat-form-field class="u-full-width">
      <textarea matInput rows="1" placeholder="{{'shared.Descrizione'  | translate}}" formControlName="description"></textarea>
      </mat-form-field>
    </div>
    <!-- <button mat-raised-button (click)="removeProfessional(index)">REMOVE</button> -->
  </ng-container>
</ng-container>