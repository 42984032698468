<header mat-dialog-title class="u-flex-full">
  <span>
    {{'bonuses.R&D Generation' | translate}}
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="bonusForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <mat-label>{{'bonuses.Year' | translate}}</mat-label>
        <mat-select formControlName="year">
          <mat-option *ngFor="let year of years" [value]="year">{{year}}</mat-option>
        </mat-select>
        <mat-error>{{'bonuses.Mandatory field' | translate}}</mat-error>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'bonuses.Available for R&D' | translate}}" formControlName="bonus" type="text" forceNumber />
        <mat-error>{{'bonuses.Mandatory field' | translate}}</mat-error>
      </mat-form-field>
    </div>
  </form>

</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()"
      [disabled]="bonusForm.pristine">{{'shared.Reset' | translate}}</button>
    <button mat-raised-button color="primary" (click)="generateBonus()"
      [disabled]="bonusForm.pristine || !bonusForm.valid">{{'bonuses.Confirm' | translate}}</button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">{{'bonuses.Close' | translate}}</button>
</mat-dialog-actions>