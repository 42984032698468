<header mat-dialog-title class="u-flex-full">

  <span *ngIf="bonusLines?.length > 0">{{'bonuses.Singoli bonus relativi ad anno' | translate}} <strong>{{bonus?.year}}</strong> -
    <strong>{{bonus.amount | customCurrency}} €</strong></span>
  <span>
    <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
      <button mat-menu-item *ngIf="bonusLines?.length > 0" (click)="exportBonusLines()">
        <mat-icon>file_download</mat-icon>
        {{'bonuses.Export CSV' | translate}}
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="cancelBonusDialog()">
        <mat-icon>delete</mat-icon>
        {{'bonuses.Undo R&D Generation' | translate}}
      </button>
    </mat-menu>
  </span>
</header>
<mat-dialog-content>

  <div style="margin: 0 15px;">
    <bonus-line-list [bonusLines]="bonusLines" [total]="total" [defaultFilters]="defaultFilters"
      (onFilterChange)="filtersChange($event)" (onSortChange)="sortChange($event)" (onPageChange)="pageChange($event)"
      (onExportBonusLines)="exportBonusLines()" [showProjectColumn]="showProjectColumn"
      [showCostLineColumn]="showCostLineColumn"></bonus-line-list>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <button mat-raised-button color="basic" type="button" (click)="close()">{{'shared.Chiudi' | translate}}</button>
</mat-dialog-actions>