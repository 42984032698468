<mat-expansion-panel style="margin-bottom: 16px;">
  <mat-expansion-panel-header>
    {{'shared.Filtra' | translate}}
  </mat-expansion-panel-header>
  <form [formGroup]="filtersForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-select formControlName="year" placeholder="{{'shared.Anno' | translate}}" >
          <mat-option *ngFor="let year of years" [value]="year">
            {{year}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <span class="u-flex-full">
        <button class="filter" mat-raised-button [disabled]="filtersForm.pristine" color="primary"
          (click)="applyFilters()">{{'shared.Filtra' | translate}}</button>
        <button mat-icon-button *ngIf="!filtersForm.pristine" (click)="resetFilters()" matTooltip="{{'shared.Reset' | translate}}">
          <mat-icon>replay</mat-icon>
        </button>
      </span>
    </div>
  </form>
</mat-expansion-panel>
