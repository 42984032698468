
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { RiskCategoryEditComponent } from 'src/app/modules/home/risks/risk-categories/risk-category-edit/risk-category-edit.component';
import { RiskCategorySelectionComponent } from 'src/app/modules/shared/risk-category-selection/risk-category-selection.component';
import { AlertService } from '../../commons/services/alert.service';
import { LaravelRiskCategoryService } from '../../commons/services/backend/laravel-risk-category.service';
import * as RiskCategoryActions from '../actions/risk-category.actions';
import { AppState } from '../reducers';
import { getRiskCategoryDialogId, getRiskCategorysTableState, getSelectionDialogId } from '../selectors/risk-category.selectors';
import { TranslateService } from '@ngx-translate/core';



@Injectable()
export class RiskCategoryEffects {

  error$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RiskCategoryActions.saveRiskCategoryFailed, RiskCategoryActions.deleteRiskCategoryFailed),
      tap(({ error }) => {
        if (error) {
          this.alertService.showErrorMessage(`${this.translateService.instant('shared.errore')}`, error);
        }
      })
    ), { dispatch: false }
  );

  loadRiskCategorys$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RiskCategoryActions.loadRiskCategories),
      switchMap(({ page, perPage, order, direction, filters, includes }) => {
        return this.riskCategoryService.list(page, perPage, order, direction, filters, includes)
          .pipe(
            map(result =>
              RiskCategoryActions.loadRiskCategoriesCompleted({ riskCategories: result.data, currentPage: page, total: result.total, perPage, order, direction, filters, includes })
            ),
            catchError(error => {
              return of(RiskCategoryActions.loadRiskCategoriesFailed({ error }))
            })
          )
      })
    )
  );

  changePage = createEffect(() =>
    this.actions$.pipe(
      ofType(RiskCategoryActions.changePage),
      withLatestFrom(this.store$.select(getRiskCategorysTableState)),
      map(([{ page, size }, { total, currentPage, perPage, direction, order, filters, includes }]) => RiskCategoryActions.loadRiskCategories({ page: page, perPage: size, order, direction, filters, includes }))
    )
  );

  changeSort = createEffect(() =>
    this.actions$.pipe(
      ofType(RiskCategoryActions.changeSort),
      withLatestFrom(this.store$.select(getRiskCategorysTableState)),
      map(([action, { total, currentPage, perPage, direction, order, filters, includes }]) => RiskCategoryActions.loadRiskCategories({ page: currentPage, perPage: perPage, order: action.order, direction: action.direction, filters, includes }))
    )
  );

  changeFilters = createEffect(() =>
    this.actions$.pipe(
      ofType(RiskCategoryActions.changeFilters),
      withLatestFrom(this.store$.select(getRiskCategorysTableState)),
      map(([{ filters }, { total, currentPage, perPage, direction, order, includes }]) => RiskCategoryActions.loadRiskCategories({ page: currentPage, perPage: perPage, order, direction, filters, includes }))
    )
  );

  editRiskCategory$ = createEffect(() => this.actions$.pipe(
    ofType(RiskCategoryActions.editRiskCategory),
    map(({ riskCategory }) => {
      let dialogRef = this.dialog.open(RiskCategoryEditComponent, {
        data: {
          riskCategory
        }
      });
      return RiskCategoryActions.riskCategoryDialogOpened({ dialogId: dialogRef.id });
    }))
  );

  saveRiskCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RiskCategoryActions.saveRiskCategory),
      mergeMap(({ riskCategory }) =>
        this.riskCategoryService.upsert(riskCategory.toDTO())
          .pipe(
            map(result =>
              RiskCategoryActions.saveRiskCategoryCompleted({ riskCategory: result })
            ),
            catchError(error => of(RiskCategoryActions.saveRiskCategoryFailed({ error })))
          )
      )
    )
  );

  onSaveCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RiskCategoryActions.saveRiskCategoryCompleted),
      map(action => action.riskCategory),
      tap(riskCategory => this.alertService.showConfirmMessage(`${riskCategory.name} ${this.translateService.instant('shared.salvato con successo')}`)),
      map(() => RiskCategoryActions.closeRiskCategoryDialog())
    )
  );


  deleteRiskCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RiskCategoryActions.deleteRiskCategory),
      switchMap(({ riskCategory }) =>
        this.alertService.showConfirmDialog(`${this.translateService.instant('shared.Conferma eliminazione')}`, `${this.translateService.instant('risks.Sei sicuro di voler eliminare aria di rischio')} ${riskCategory.name}?`)
          .pipe(
            mergeMap((confirm) => {
              return confirm ?
                this.riskCategoryService.delete(riskCategory.id)
                  .pipe(
                    map(() => RiskCategoryActions.deleteRiskCategoryCompleted({ riskCategory })),
                    catchError(error => of(RiskCategoryActions.deleteRiskCategoryFailed({ error })))
                  )

                : of(RiskCategoryActions.deleteRiskCategoryCancelled());
            })
          )
      )
    )
  );

  onDeleteCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RiskCategoryActions.deleteRiskCategoryCompleted),
      tap(({ riskCategory }) => this.alertService.showConfirmMessage(` ${riskCategory.name} ${this.translateService.instant('shared.eliminato con successo')}`)),
      map(() => RiskCategoryActions.closeRiskCategoryDialog())
    )
  );


  closeDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(RiskCategoryActions.closeRiskCategoryDialog),
      withLatestFrom(this.store$.select(getRiskCategoryDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }
      })
    ), { dispatch: false }
  );

  reloadAfterSave = createEffect(() =>
    this.actions$.pipe(
      ofType(RiskCategoryActions.saveRiskCategoryCompleted),
      withLatestFrom(this.store$.select(getRiskCategorysTableState)),
      map(([_, { currentPage, perPage, direction, order, filters, includes }]) => RiskCategoryActions.loadRiskCategories({ page: currentPage, perPage, order, direction, filters, includes }))
    )
  );

  reloadAfterDelete = createEffect(() =>
    this.actions$.pipe(
      ofType(RiskCategoryActions.deleteRiskCategoryCompleted),
      withLatestFrom(this.store$.select(getRiskCategorysTableState)),
      map(([_, { currentPage, perPage, direction, order, filters, includes }]) => RiskCategoryActions.loadRiskCategories({ page: currentPage, perPage, order, direction, filters, includes }))
    )
  );

  selectRiskCategory$ = createEffect(() => this.actions$.pipe(
    ofType(RiskCategoryActions.selectRiskCategory),
    map(({ filters }) => {
      let dialogRef = this.dialog.open(RiskCategorySelectionComponent, {
        data: {
          defaultFilters: filters
        }
      });
      return RiskCategoryActions.selectionDialogOpened({ selectionDialogId: dialogRef.id });
    }))
  );
  closeSelectionDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(RiskCategoryActions.closeSelectionDialog),
      withLatestFrom(this.store$.select(getSelectionDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }

      })
    ), { dispatch: false }
  );

  riskCategoriesSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RiskCategoryActions.riskCategorySelected),
      map(() => RiskCategoryActions.closeSelectionDialog())
    ))


  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private riskCategoryService: LaravelRiskCategoryService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private translateService: TranslateService
  ) { }
}
