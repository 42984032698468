<form [formGroup]="budgetForm" novalidate>
  <div class="u-flex-full">
    <mat-form-field class="u-full-width">
      <input matInput placeholder="{{'shared.Nome' | translate}}" formControlName="name" type="text" />
      <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        placeholder="{{'shared.Budget' | translate}}"
        formControlName="budget"
        type="number"
        min="0"
        forceNumber
      />
      <mat-icon matSuffix>euro</mat-icon>
      <mat-error>{{'projects.Budget non valido' | translate}}</mat-error>
    </mat-form-field>
    <mat-form-field class="u-full-width">
      <input
        matInput
        placeholder="{{'shared.Budget ore' | translate}}"
        formControlName="hours"
        type="number"
        required
      />
      <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        [matDatepicker]="fromDatePicker1"
        formControlName="start"
        placeholder="{{'shared.Inizio' | translate}}"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="fromDatePicker1"
      ></mat-datepicker-toggle>
      <mat-datepicker #fromDatePicker1></mat-datepicker>
      <mat-error>{{'projects.Data non valida' | translate}}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        [matDatepicker]="fromDatePicker2"
        formControlName="end"
        placeholder="{{'shared.Fine' | translate}}"
        [min]="budgetForm.controls['start'].value"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="fromDatePicker2"
      ></mat-datepicker-toggle>
      <mat-datepicker #fromDatePicker2></mat-datepicker>
      <mat-error>{{'projects.Data non valida' | translate}}</mat-error>
    </mat-form-field>
  </div>
  <div class="u-flex-full">
    <mat-form-field class="u-full-width">
      <textarea
        matInput
        placeholder="{{'shared.Note' | translate}}"
        formControlName="notes"
        rows="2"
      ></textarea>
    </mat-form-field>
  </div>
</form>
<!-- {{budgetForm.value | json}} -->

<div class="u-flex-full actions">
  <span>
    <button
      mat-raised-button
      color="basic"
      type="reset"
      (click)="revert()"
      [disabled]="budgetForm.pristine"
    >
    {{'shared.Reset' | translate}}
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="save()"
      [disabled]="budgetForm.pristine || !budgetForm.valid"
    >
    {{'shared.Conferma' | translate}}
    </button>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">
    {{'shared.Chiudi' | translate}}
  </button>
</div>
