<header mat-dialog-title class="u-flex-full">
  <span>
    <span *ngIf="client">{{'clients.Modifica cliente' | translate}} : {{ client.name }}</span>
    <span *ngIf="!client">{{'clients.Nuovo cliente' | translate}}</span>
  </span>
  <span *ngIf="client">
    <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
      <button mat-menu-item (click)="deleteClient()">
        <mat-icon>delete</mat-icon>
        {{'shared.Elimina' | translate}}
      </button>
    </mat-menu>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="clientForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field>
        <input matInput placeholder="{{'clients.Nome breve' | translate}}" formControlName="name" type="text" />
        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input
          matInput
          placeholder="{{'clients.Ragione sociale completa' | translate}}"
          formControlName="companyName"
          type="text"
        />
        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'shared.P.IVA' | translate}}" formControlName="VAT" type="text" />
        <mat-error>{{'shared.P.IVA non valida' | translate}}</mat-error>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input
          matInput
          placeholder="{{'shared.Codice fiscale' | translate}}"
          formControlName="identifier"
          type="text"
        />
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'shared.Email' | translate}}" formControlName="email" type="email" />
        <mat-error>{{'clients.Formato non valido' | translate}}</mat-error>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'clients.E-mail amministrazione' | translate}}" formControlName="adminEmail" type="email" />
        <mat-error>{{'clients.Formato non valido' | translate}}</mat-error>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'shared.PEC' | translate}}" formControlName="PEC" type="email" />
        <mat-error>{{'clients.Formato non valido' | translate}}</mat-error>
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input
          matInput
          placeholder="{{'shared.Indirizzo' | translate}}"
          formControlName="address"
          type="text"
        />
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input
          matInput
          placeholder="{{'shared.Città' | translate}}"
          formControlName="city"
          type="text"
        />
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input
          matInput
          placeholder="{{'shared.Provincia' | translate}}"
          formControlName="province"
          type="text"
        />
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <!-- <mat-form-field class="u-full-width">
        <input
          matInput
          placeholder="Regione"
          formControlName="region"
          type="text"
        />
      </mat-form-field> -->
      <mat-form-field class="u-full-width">
        <input type="text" placeholder="{{'shared.Regione' | translate}}" aria-label="Regione" matInput formControlName="region" [matAutocomplete]="regionAutocomplete">
        <mat-autocomplete #regionAutocomplete="matAutocomplete" autoActiveFirstOption>
          <mat-option *ngFor="let region of filteredRegions$ | async" [value]="region">
            {{region}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'shared.CAP' | translate}}" formControlName="CAP" type="text" />
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input
          type="text"
          placeholder="{{'shared.Nazione' | translate}}"
          aria-label="Nation"
          matInput
          formControlName="country"
          [matAutocomplete]="nation"
        />
        <mat-autocomplete autoActiveFirstOption #nation="matAutocomplete">
          <mat-option
            *ngFor="let nation of filteredNations | async"
            [value]="nation.name"
          >
            {{ nation.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input
          matInput
          placeholder="{{'shared.Codice nazione' | translate}}"
          formControlName="countryCode"
          type="text"
        />
        <mat-error *ngIf="clientForm.controls['countryCode'].errors">
          {{'clients.Formato non valido' | translate}}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input
          matInput
          placeholder="{{'shared.Bonus Cliente' | translate}}"
          formControlName="bonusClient"
          type="number"
          min="0"
          max="100"
          forceNumber
        />
        <mat-icon matSuffix>percent</mat-icon>
        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <mat-label>{{'clients.Tipologia di pagamento' | translate}}</mat-label>
        <app-payment-field formControlName="payment"> </app-payment-field>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <mat-label>{{'shared.Domiciliazione pagamento' | translate}}</mat-label>
        <app-bank-account-field formControlName="bankAccount">
        </app-bank-account-field>
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width" floatLabel="auto">
        <mat-label>{{'clients.Client leaders' | translate}} </mat-label>
        <app-client-bonuses-field
          formControlName="clientBonuses"
        ></app-client-bonuses-field>
        <mat-icon
          matSuffix
          matTooltip="{{'clients.Questa percentuale è la quota di Bonus Cliente assegnata ad un Utente' | translate}}"
          >help</mat-icon
        >
        <mat-error
          *ngIf="clientForm.controls['clientBonuses'].errors?.incorrect"
          >{{'shared.La somma delle percentuali è uguale a' | translate: {total: totalBonusClient} }}</mat-error
        >
      </mat-form-field>
    </div>
    <!-- <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <mat-label>Client leader</mat-label>
        <app-users-field formControlName="clientLeaders">
        </app-users-field>
      </mat-form-field>
    </div> -->
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <mat-label>{{'shared.Contatti' | translate}}</mat-label>
        <app-contacts-field formControlName="contacts"> </app-contacts-field>
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <mat-label>{{'shared.Valuta' | translate}}</mat-label>
        <app-currency-field formControlName="currency"> </app-currency-field>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input
          matInput
          placeholder="{{'shared.Codice destinatario' | translate}}"
          formControlName="recipientCode"
          type="text"
        />
        <mat-error *ngIf="clientForm.controls['recipientCode'].errors">
          {{'clients.Formato non valido' | translate}}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <mat-label>{{'shared.Regime IVA' | translate}}</mat-label>
        <app-iva-typology-field
          formControlName="ivaTypology"
        ></app-iva-typology-field>
        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
      </mat-form-field>
    </div>
    <mat-expansion-panel>
      <mat-expansion-panel-header> {{'clients.Lettera intenti' | translate}} </mat-expansion-panel-header>
      <div class="u-flex-full">
        <mat-form-field class="u-full-width">
          <input
            matInput
            placeholder="{{'shared.Riferimento' | translate}}"
            formControlName="reference"
            type="text"
          />
        <mat-error *ngIf="clientForm.controls['reference'].errors">
          {{'clients.Formato non valido - Previsto nella forma XXXXXXXXXXX-YYYYYYY' | translate}}
        </mat-error>
        </mat-form-field>
        <mat-form-field class="u-full-width">
          <input matInput [matDatepicker]="referenceDatePicker" formControlName="referenceDate" placeholder="{{'shared.Data' | translate}}" />
          <mat-datepicker-toggle matSuffix [for]="referenceDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #referenceDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
    </mat-expansion-panel>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'shared.Note' | translate}}" formControlName="note" type="text" />
      </mat-form-field>
    </div>
  </form>
  <!-- {{clientForm.value | json}}  -->
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button
      mat-raised-button
      color="basic"
      type="reset"
      (click)="revert()"
      [disabled]="clientForm.pristine"
    >
      {{'shared.Reset' | translate}}
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="save()"
      [disabled]="clientForm.pristine || !clientForm.valid"
    >
    {{'shared.Salva' | translate}}
    </button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">
    {{'shared.Chiudi' | translate}}
  </button>
</mat-dialog-actions>
