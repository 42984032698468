<form [formGroup]="riskIdentificationForm" novalidate>
  <div class="u-flex-full">
    <mat-form-field class="u-full-width">
      <mat-label>{{'risks.Rischio' | translate}}</mat-label>
      <app-risk-field formControlName="risk"></app-risk-field>
      <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'shared.Probabilità' | translate}}</mat-label>
      <input
        matInput
        placeholder="{{'shared.Probabilità' | translate}}"
        formControlName="probability"
        type="number"
        min="0"
        max="100"
        forceNumber
      />
      <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        placeholder="{{'risks.Impatto' | translate}}"
        formControlName="impact"
        type="number"
        forceNumber
      />
      <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
    </mat-form-field>
    <mat-form-field class="u-full-width">
      <mat-label>{{'risks.Risk Owner' | translate}}</mat-label>
      <app-user-field formControlName="riskOwner"></app-user-field>
      <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
    </mat-form-field>
    <mat-form-field class="u-full-width">
      <mat-label>{{'risks.Risk Actionee' | translate}}</mat-label>
      <app-user-field formControlName="riskActionee"></app-user-field>
      <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
    </mat-form-field>
  </div>
</form>

<div class="u-flex-full actions">
  <span>
    <button
      mat-raised-button
      color="basic"
      type="reset"
      (click)="revert()"
      [disabled]="riskIdentificationForm.pristine"
    >
      {{'shared.Reset' | translate}}
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="save()"
      [disabled]="
        riskIdentificationForm.pristine || !riskIdentificationForm.valid
      "
    >
      {{'shared.Conferma' | translate}}
    </button>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">
    {{'shared.Chiudi' | translate}}
  </button>
</div>
