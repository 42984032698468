<div class="attachment-upload u-flex-full">
  <a *ngIf="attachment && attachment == value" style="overflow: scroll;" [href]="href" target="_blank"
    matTooltip="{{'shared.Apri file' | translate}}" (click)="$event.stopImmediatePropagation();">
    {{attachmentName || attachment}}
  </a>
  <ng-container *ngIf="value">
    <span *ngIf="value">
      {{value.name}}
    </span>
    <span>
      <button mat-icon-button type="button" [matMenuTriggerFor]="attachmentType"
        (click)="$event.stopImmediatePropagation()" matTooltip="{{'shared.Modifica' | translate}}">
        <mat-icon class="sidenav-icon">edit</mat-icon>
      </button>
      <button mat-icon-button type="button" (click)="$event.stopImmediatePropagation();clear()" matTooltip="{{'shared.Svuota' | translate}}">
        <mat-icon class="sidenav-icon">clear</mat-icon>
      </button>
    </span>
  </ng-container>
</div>
<span style="height: 1px; width: 1px; position: fixed" [matMenuTriggerFor]="attachmentType">
</span>
<mat-menu #attachmentType="matMenu">
  <button mat-menu-item (click)="attachmentInput.click()">
    {{'shared.File' | translate}}
  </button>
  <button mat-menu-item (click)="insertUrl()">
    {{'attachment.URL esterno' | translate}}
  </button>
</mat-menu>
<input #attachmentInput type="file" [accept]="accept" style="display: none" (change)="onFileChange($event)">