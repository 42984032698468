<app-business-opportunity-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters">
</app-business-opportunity-filters>
<mat-card class="u-center-text" *ngIf="!(businessOpportunities | async).length">
  <h4>{{ 'business-opportunities.Nessuna business opportunity trovata' | translate }}</h4>
  <button *ngIf="canAdd" mat-raised-button (click)="addBusinessOpportunity()"> {{ 'shared.Aggiungi' | translate}}</button>
</mat-card>
<div class="table-container" [hidden]="!(businessOpportunities | async).length">
  <table mat-table matSort matSortDisableClear matSortActive="client" matSortDirection="asc"
    [dataSource]="businessOpportunities">
    <ng-container matColumnDef="client">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'shared.Cliente' | translate }} </th>
      <td mat-cell *matCellDef="let row">
        {{ row.client?.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="contact">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'business-opportunities.Contatto' | translate }} </th>
      <td mat-cell *matCellDef="let row">
        {{row.contact?.name}} {{row.contact?.lastname}}
      </td>
    </ng-container>

    <ng-container matColumnDef="salesLeader">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'business-opportunities.Sales Leader' | translate }} </th>
      <td mat-cell *matCellDef="let row">
        {{row.salesLeader?.name}} {{row.salesLeader?.lastname}}
      </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'shared.Data' | translate }} </th>
      <td mat-cell *matCellDef="let row">
        {{row.date | dateFormat}}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'business-opportunities.Stato' | translate }} </th>
      <td mat-cell *matCellDef="let row">
        {{'business-opportunities.' + row.status | translate}}
      </td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'shared.Titolo' | translate }} </th>
      <td mat-cell *matCellDef="let row">
        {{row.title}}
      </td>
    </ng-container>

    <ng-container matColumnDef="promo">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'business-opportunities.Promo' | translate }} </th>
      <td mat-cell *matCellDef="let row">
        <span *ngIf="row.promo">
          {{ row.promo.identifier }} - {{row.promo.description}}
        </span>
      </td>
    </ng-container>


    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <button *ngIf="canAdd" mat-icon-button mat-raised-button color="accent"
          (click)="$event.stopPropagation();addBusinessOpportunity()">
          <mat-icon matTooltip="{{ 'shared.Aggiungi' | translate}}">playlist_add</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let row">
        <!-- <ng-container *ngIf="canEdit">
          <button mat-icon-button [mat-menu-trigger-for]="actionMenu" (click)="$event.stopPropagation()">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu x-position=" before" overlapTrigger="false" #actionMenu="matMenu">
            <button mat-menu-item (click)="$event.stopPropagation();editBusinessOpportunity(row)">
              <mat-icon>create</mat-icon>
              Modifica
            </button>
          </mat-menu>
        </ng-container> -->

        <button *ngIf="canSelect" mat-raised-button color="primary"
          (click)="selectBusinessOpportunity(row)">{{ 'shared.Seleziona' | translate }}</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="showBusinessOpportunity(row)"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>
