import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ContactEditComponent } from 'src/app/modules/home/contacts/contact-edit/contact-edit.component';
import { ContactSelectionComponent } from 'src/app/modules/shared/contact-selection/contact-selection.component';

import { AlertService } from '../../commons/services/alert.service';
import { LaravelContactService } from '../../commons/services/backend/laravel-contact.service';
import * as ContactActions from '../actions/contact.actions';
import { AppState } from '../reducers';
import { getContactDialogId, getContactsTableState, getSelectionDialogId } from '../selectors/contact.selectors';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ContactEffects {
  error$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ContactActions.saveContactFailed),
        tap(({ error }) => {
          if (error) {
            this.alertService.showErrorMessage(`${this.translateService.instant('shared.errore')}`, error);
          }
        })
      ),
    { dispatch: false }
  );

  loadContacts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactActions.loadContacts),
      switchMap(({ page, perPage, order, direction, filters, includes }) => {
        return this.contactService
          .list(page, perPage, order, direction, filters, includes)
          .pipe(
            map((result) =>
              ContactActions.loadContactsCompleted({
                contacts: result.data,
                currentPage: page,
                total: result.total,
                perPage,
                order,
                direction,
                filters,
                includes,
              })
            ),
            catchError((error) => {
              return of(ContactActions.loadContactsFailed({ error }));
            })
          );
      })
    )
  );

  changePage = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactActions.changePage),
      withLatestFrom(this.store$.select(getContactsTableState)),
      map(
        ([
          { page, size },
          { total, currentPage, perPage, direction, order, filters, includes },
        ]) =>
          ContactActions.loadContacts({
            page: page,
            perPage: size,
            order,
            direction,
            filters,
            includes,
          })
      )
    )
  );

  changeSort = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactActions.changeSort),
      withLatestFrom(this.store$.select(getContactsTableState)),
      map(
        ([
          action,
          { total, currentPage, perPage, direction, order, filters, includes },
        ]) =>
          ContactActions.loadContacts({
            page: currentPage,
            perPage: perPage,
            order: action.order,
            direction: action.direction,
            filters,
            includes,
          })
      )
    )
  );

  changeFilters = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactActions.changeFilters),
      withLatestFrom(this.store$.select(getContactsTableState)),
      map(
        ([
          { filters },
          { total, currentPage, perPage, direction, order, includes },
        ]) =>
          ContactActions.loadContacts({
            page: currentPage,
            perPage: perPage,
            order,
            direction,
            filters,
            includes,
          })
      )
    )
  );

  editContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactActions.editContact),
      map(({ contact }) => {
        let dialogRef = this.dialog.open(ContactEditComponent, {
          data: {
            contact,
          },
        });
        return ContactActions.contactDialogOpened({ dialogId: dialogRef.id });
      })
    )
  );

  saveContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactActions.saveContact),
      mergeMap(({ contact }) =>
        this.contactService.upsert(contact.toDTO()).pipe(
          map((result) =>
            ContactActions.saveContactCompleted({ contact: result })
          ),
          catchError((error) => of(ContactActions.saveContactFailed({ error })))
        )
      )
    )
  );

  onSaveCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactActions.saveContactCompleted),
      map((action) => action.contact),
      tap((contact) =>
        this.alertService.showConfirmMessage(
          `${contact.name} ${this.translateService.instant('shared.salvato con successo')}`
        )
      ),
      map(() => ContactActions.closeContactDialog())
    )
  );

  deleteContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactActions.deleteContact),
      switchMap(({ contact }) =>
        this.alertService
          .showConfirmDialog(
            `${this.translateService.instant('shared.Conferma eliminazione')}`,
            `${this.translateService.instant('contacts.Sei sicuro di voler eliminare il contatto')} ${contact.name}?`
          )
          .pipe(
            mergeMap((confirm) => {
              return confirm
                ? this.contactService.delete(contact.id).pipe(
                    map(() =>
                      ContactActions.deleteContactCompleted({ contact })
                    ),
                    catchError((error) =>
                      of(ContactActions.deleteContactFailed({ error }))
                    )
                  )
                : of(ContactActions.deleteContactCancelled());
            })
          )
      )
    )
  );

  onDeleteCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactActions.deleteContactCompleted),
      tap(({ contact }) =>
        this.alertService.showConfirmMessage(
          ` ${contact.name} ${this.translateService.instant('shared.eliminato con successo')}`
        )
      ),
      map(() => ContactActions.closeContactDialog())
    )
  );

  closeDialog = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ContactActions.closeContactDialog),
        withLatestFrom(this.store$.select(getContactDialogId)),
        tap(([_, dialogId]) => {
          if (dialogId) {
            this.dialog.getDialogById(dialogId).close();
          }
        })
      ),
    { dispatch: false }
  );

  reloadAfterSave = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactActions.saveContactCompleted),
      withLatestFrom(this.store$.select(getContactsTableState)),
      map(
        ([_, { currentPage, perPage, direction, order, filters, includes }]) =>
          ContactActions.loadContacts({
            page: currentPage,
            perPage,
            order,
            direction,
            filters,
            includes,
          })
      )
    )
  );

  reloadAfterDelete = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactActions.deleteContactCompleted),
      withLatestFrom(this.store$.select(getContactsTableState)),
      map(
        ([_, { currentPage, perPage, direction, order, filters, includes }]) =>
          ContactActions.loadContacts({
            page: currentPage,
            perPage,
            order,
            direction,
            filters,
            includes,
          })
      )
    )
  );

  selectContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactActions.selectContact),
      map(({ filters, currentFormControlName, canAdd }) => {
        let dialogRef = this.dialog.open(ContactSelectionComponent, {
          data: {
            defaultFilters: filters,
            currentFormControlName,
            canAdd,
          },
        });
        return ContactActions.selectionDialogOpened({
          selectionDialogId: dialogRef.id,
        });
      })
    )
  );
  closeSelectionDialog = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ContactActions.closeSelectionDialog),
        withLatestFrom(this.store$.select(getSelectionDialogId)),
        tap(([_, dialogId]) => {
          if (dialogId) {
            this.dialog.getDialogById(dialogId).close();
          }
        })
      ),
    { dispatch: false }
  );

  contactsSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactActions.contactSelected),
      map(() => ContactActions.closeSelectionDialog())
    )
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private contactService: LaravelContactService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private translateService: TranslateService
  ) {}
}
