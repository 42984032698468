import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ClientEditComponent } from 'src/app/modules/home/clients/client-edit/client-edit.component';
import { ClientSelectionComponent } from 'src/app/modules/shared/client-selection/client-selection.component';

import { AlertService } from '../../commons/services/alert.service';
import { LaravelClientService } from '../../commons/services/backend/laravel-client.service';
import * as ClientActions from '../actions/client.actions';
import { AppState } from '../reducers';
import { getClientDialogId, getClientsTableState, getSelectionDialogId } from '../selectors/client.selectors';
import { TranslateService } from '@ngx-translate/core';




@Injectable()
export class ClientEffects {

  error$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.saveClientFailed),
      tap(({ error }) => {
        if (error) {
          this.alertService.showErrorMessage(`${this.translateService.instant('shared.errore')}`, error);
        }
      })
    ), { dispatch: false }
  );

  loadClients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.loadClients),
      switchMap(({ page, perPage, order, direction, filters, includes }) => {
        return this.clientService.list(page, perPage, order, direction, filters, includes)
          .pipe(
            map(result =>
              ClientActions.loadClientsCompleted({ clients: result.data, currentPage: page, total: result.total, perPage, order, direction, filters, includes })
            ),
            catchError(error => {
              return of(ClientActions.loadClientsFailed({ error }))
            })
          )
      })
    )
  );

  changePage = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.changePage),
      withLatestFrom(this.store$.select(getClientsTableState)),
      map(([{ page, size }, { total, currentPage, perPage, direction, order, filters, includes }]) => ClientActions.loadClients({ page: page, perPage: size, order, direction, filters, includes }))
    )
  );

  changeSort = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.changeSort),
      withLatestFrom(this.store$.select(getClientsTableState)),
      map(([action, { total, currentPage, perPage, direction, order, filters, includes }]) => ClientActions.loadClients({ page: currentPage, perPage: perPage, order: action.order, direction: action.direction, filters, includes }))
    )
  );

  changeFilters = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.changeFilters),
      withLatestFrom(this.store$.select(getClientsTableState)),
      map(([{ filters }, { total, currentPage, perPage, direction, order, includes }]) => ClientActions.loadClients({ page: currentPage, perPage: perPage, order, direction, filters, includes }))
    )
  );

  editClient$ = createEffect(() => this.actions$.pipe(
    ofType(ClientActions.editClient),
    map(({ client }) => {
      let dialogRef = this.dialog.open(ClientEditComponent, {
        data: {
          client
        },
        width: '100%',
      });
      return ClientActions.clientDialogOpened({ dialogId: dialogRef.id });
    }))
  );

  saveClient$ = createEffect(() =>
  this.actions$.pipe(
    ofType(ClientActions.saveClient),
    mergeMap(({ client }) =>
      this.clientService.upsert(client.toDTO())
        .pipe(
          map(result =>
            ClientActions.saveClientCompleted({ client: result })
          ),
          catchError(error => of(ClientActions.saveClientFailed({ error })))
        )
    )
  )
);

onSaveCompleted$ = createEffect(() =>
  this.actions$.pipe(
    ofType(ClientActions.saveClientCompleted),
    map(action => action.client),
    tap(client => this.alertService.showConfirmMessage(`${client.name} ${this.translateService.instant('shared.salvato con successo')}`)),
    map(() => ClientActions.closeClientDialog())
  )
);


  deleteClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.deleteClient),
      switchMap(({ client }) =>
        this.alertService.showConfirmDialog(`${this.translateService.instant('shared.Conferma eliminazione')}`, `${this.translateService.instant('clients.Sei sicuro di voler eliminare il cliente')} ${client.name}?`)
          .pipe(
            mergeMap((confirm) => {
              return confirm ?
                this.clientService.delete(client.id)
                  .pipe(
                    map(() => ClientActions.deleteClientCompleted({ client })),
                    catchError(error => of(ClientActions.deleteClientFailed({ error })))
                  )

                : of(ClientActions.deleteClientCancelled());
            })
          )
      )
    )
  );

  onDeleteCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.deleteClientCompleted),
      tap(({ client }) => this.alertService.showConfirmMessage(`${client.name} ${this.translateService.instant('shared.eliminato con successo')}`)),
      map(() => ClientActions.closeClientDialog())
    )
  );


  closeDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.closeClientDialog),
      withLatestFrom(this.store$.select(getClientDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }
      })
    ), { dispatch: false }
  );

  reloadAfterSave = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.saveClientCompleted),
      withLatestFrom(this.store$.select(getClientsTableState)),
      map(([_, { currentPage, perPage, direction, order, filters, includes }]) => ClientActions.loadClients({ page: currentPage, perPage, order, direction, filters, includes }))
    )
  );

  reloadAfterDelete = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.deleteClientCompleted),
      withLatestFrom(this.store$.select(getClientsTableState)),
      map(([_, { currentPage, perPage, direction, order, filters, includes }]) => ClientActions.loadClients({ page: currentPage, perPage, order, direction, filters, includes }))
    )
  );


  selectClient$ = createEffect(() => this.actions$.pipe(
    ofType(ClientActions.selectClient),
    map(({ filters, canAdd }) => {
      let dialogRef = this.dialog.open(ClientSelectionComponent, {
        data: {
          defaultFilters: filters,
          canAdd
        }
      });
      return ClientActions.selectionDialogOpened({ selectionDialogId: dialogRef.id });
    }))
  );
  closeSelectionDialog = createEffect(() =>
  this.actions$.pipe(
    ofType(ClientActions.closeSelectionDialog),
    withLatestFrom(this.store$.select(getSelectionDialogId)),
    tap(([_, dialogId]) => {
      if (dialogId) {
        this.dialog.getDialogById(dialogId).close();
      }

    })
  ), { dispatch: false }
);

  clientsSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.clientSelected),
      map(() => ClientActions.closeSelectionDialog())
    ))


  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private clientService: LaravelClientService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private translateService: TranslateService
  ) { }
}
