import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ListResultDTO } from 'src/app/helpers/listResult.interface';
import { formatDateForBackend } from 'src/app/helpers/time.utils';
import { environment } from 'src/environments/environment';

import { ActionDTO, ActionFilters } from '../../models/action.model';
import { BonusLineDTO, BonusLineFilters } from '../../models/bonus_line.model';

@Injectable({
  providedIn: "root"
})
export class LaravelBonusLineService {
  constructor(private httpClient: HttpClient) { }

  private get ROUTES() {
    return {
      list: environment.baseUrl + "/api/bonus_lines",
      export: environment.baseUrl + "/api/bonus_lines/records"

    };
  }

  public list(page: number,
    per_page: number,
    order: string,
    direction: string,
    filters: BonusLineFilters,
    includes?: string[]
  ): Observable<ListResultDTO<BonusLineDTO>> {
    let params = {};
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (includes) params["includes[]"] = includes;
    if (filters) {
      if (filters.bonus) params["bonus_id"] = "" + filters.bonus.id;
      if (filters.project) params["project_id"] = "" + filters.project.id;
    }
    if (per_page) {
      params["per_page"] = "" + per_page;
      if (page) params["page"] = "" + page;
      return this.httpClient.get<ListResultDTO<BonusLineDTO>>(this.ROUTES.list, {
        params: new HttpParams({ fromObject: params })
      });
    } else {
      return this.httpClient.get<BonusLineDTO[]>(this.ROUTES.list, {
        params: new HttpParams({ fromObject: params })
      }).pipe(
        map(results => {
          return {
            data: results,
            total: results.length
          };
        })
      );
    }
  }


  public exportBonusLines(filters: BonusLineFilters): Observable<{ data: string }> {
    let params = {};
    if (filters) {
      if (filters.bonus)
        params["bonus_id"] = filters.bonus.id;
      if (filters.project)
        params["project_id"] = filters.project.id;
    }

    return this.httpClient.get<{ data: string }>(this.ROUTES.export, {
      params: new HttpParams({ fromObject: params, }),
    });
  }


}
