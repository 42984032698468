import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { map, Subject, takeUntil } from 'rxjs';
import { BusinessOpportunity } from 'src/app/commons/models/business-opportunity.model';
import { Offer } from 'src/app/commons/models/offer.model';
import { AlertService } from 'src/app/commons/services/alert.service';
import * as ExpenseActions from 'src/app/store/actions/offer.actions';
import { AppState } from 'src/app/store/reducers';
import * as BusinessOpportunitySelectors from 'src/app/store/selectors/business-opportunity.selectors';

@Component({
  selector: 'app-offer-duplicate',
  templateUrl: './offer-duplicate.component.html',
  styleUrls: ['./offer-duplicate.component.scss']
})
export class OfferDuplicateComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  private _offer: Offer;

  businessOpportunity: BusinessOpportunity;

  offerForm: FormGroup;
  constructor(private store$: Store<AppState>, private alertService: AlertService, private fb: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private translateService: TranslateService) {
    this.store$.pipe(select(BusinessOpportunitySelectors.getBusinessOpportunity), takeUntil(this.unsubscribe$), map(dto => new BusinessOpportunity(dto))).subscribe(
      bo => {
        this.businessOpportunity = bo; 
      }
    )
  }

  ngOnInit() {
    if (this.data) {
      this.offer = this.data.offer;
      console.log(this.offer.totalAmount);
      
    }
    this._createForm();
    this.ngOnChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private _createForm() {
    let group = {
      businessOpportunity: ['', Validators.required],
      date: ['', Validators.required],
      title: ['', Validators.required],
      totalAmount: ['', Validators.required],
      probability: ['', Validators.required],
      notes: [''],
      expenseType: ['', Validators.required],
      kmCost: [''],
      project: [''],
      daysDistributions: this.fb.array([]),
    }
    this.offerForm = this.fb.group(group);
  }

  private ngOnChanges() {
    if (this.offerForm) {
      this.offerForm.reset();
      this._initFormValues(this.offer);
    }
  }

  private _initFormValues(offer?: Offer) {
    if (offer) {
      this.offerForm.patchValue({
        businessOpportunity: offer.businessOpportunity,
        date: new Date(),
        title: offer.title,
        totalAmount: offer.totalAmount,
        probability: offer.probability,
        notes: offer.notes,
        expenseType: offer.expenseType,
        kmCost: offer.kmCost,
        project: offer.project,
      });
    }
    this.offerForm.markAsDirty();
  }

  get offer(): Offer {
    return this._offer;
  }

  set offer(offer: Offer) {
    this._offer = offer;
    this.ngOnChanges();
  }

  private _prepareSaveOffer(): Offer {
    let savingOffer: Offer = Offer.fromFormGroup(this.offerForm);
    return savingOffer;
  }

  save() {
    let unsavedEntity = this._prepareSaveOffer();
    this.store$.dispatch(ExpenseActions.saveOffer({ offer: unsavedEntity }));
  }

  close() {
    if (this.offerForm.pristine) {
      this.store$.dispatch(ExpenseActions.closeOfferDialog())
    } else {
      this.alertService
        .showConfirmDialog(
          `${this.translateService.instant('shared.Chiudi')}`,
          `${this.translateService.instant('shared.Ci sono modifiche non salvate. Sei sicuro di voler chiudere?')}`
        )
        .subscribe(result => {
          if (result) {
            this.store$.dispatch(ExpenseActions.closeOfferDialog())
          }
        });
    }
  }

  revert() {
    this.ngOnChanges();
  }
}
