<header mat-dialog-title class="u-flex-full">
  <span>
    {{'payments.Seleziona pagamento' | translate}}
  </span>
</header>
<mat-dialog-content>
  <payment-list [payments]="payments" [defaultFilters]="defaultFilters" [displayedColumns]="displayedColumns" [total]="total"
    [canSelect]="true" (onSortChange)="sortChange($event)" (onPageChange)="pageChange($event)"
    (onFilterChange)="filtersChange($event)" (onSelectPayment)="selectPayment($event)"></payment-list>
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
  </span>
<button mat-raised-button color="basic" type="button" (click)="close()">{{'shared.Chiudi' | translate}}</button>
</mat-dialog-actions>
