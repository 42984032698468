import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ValidatorService } from 'angular-iban';
import { BankAccount } from 'src/app/commons/models/bank-account.model';
import { AlertService } from 'src/app/commons/services/alert.service';
import * as BankAccountActions from 'src/app/store/actions/bank-account.actions';
import { AppState } from 'src/app/store/reducers';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-bank-account-edit',
  templateUrl: './bank-account-edit.component.html',
  styleUrls: ['./bank-account-edit.component.scss']
})
export class BankAccountEditComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  private _bankAccount: BankAccount;

  currentBankAccount: BankAccount;

  bankAccountForm: FormGroup;
  constructor(private store$: Store<AppState>, private alertService: AlertService, private fb: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private translateService:TranslateService) {
  }

  ngOnInit() {
    if (this.data) {
      this.bankAccount = this.data.bankAccount;
    }
    this._createForm();
    this.ngOnChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private _createForm() {
    let group = {
      name: ["", Validators.required],
      iban: [Validators.required, ValidatorService.validateIban],
    }
    this.bankAccountForm = this.fb.group(group);
  }

  private ngOnChanges() {
    if (this.bankAccountForm) {
      this.bankAccountForm.reset();
      if (this.bankAccount) {
        this._initFormValues(this.bankAccount);
      }
    }

  }

  private _initFormValues(bankAccount: BankAccount) {
    if (bankAccount) {
      this.bankAccountForm.patchValue({
        name: bankAccount.name,
        iban: bankAccount.iban
      });
    }
  }

  get bankAccount(): BankAccount {
    return this._bankAccount;
  }

  set bankAccount(bankAccount: BankAccount) {
    this._bankAccount = bankAccount;
    this.ngOnChanges();
  }

  private _prepareSaveBankAccount(): BankAccount {
    let savingBankAccount: BankAccount = BankAccount.fromFormGroup(this.bankAccountForm, this.bankAccount);
    return savingBankAccount;
  }

  save() {
    let unsavedEntity = this._prepareSaveBankAccount();
    this.store$.dispatch(BankAccountActions.saveBankAccount({ bankAccount: unsavedEntity }));
  }

  close() {
    if (this.bankAccountForm.pristine) {
      this.store$.dispatch(BankAccountActions.closeBankAccountDialog())
    } else {
      this.alertService
        .showConfirmDialog(
          `${this.translateService.instant('shared.Chiudi')}`,
          `${this.translateService.instant('shared.Ci sono modifiche non salvate. Sei sicuro di voler chiudere?')}`
        )
        .subscribe(result => {
          if (result) {
            this.store$.dispatch(BankAccountActions.closeBankAccountDialog())
          }
        });
    }
  }

  deleteBankAccount() {
    if (this.bankAccount) {
      this.store$.dispatch(BankAccountActions.deleteBankAccount({ bankAccount: this.bankAccount.toDTO() }))
    }
  }

  revert() {
    this.ngOnChanges();
  }
}
