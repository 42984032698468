<app-payment-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters">
</app-payment-filters>
<mat-card class="u-center-text" *ngIf="!(payments | async).length">
  <h4>{{'payments.Nessun pagamento trovato' | translate}}</h4>
  <button *ngIf="canAdd" mat-raised-button (click)="addPayment()">
    {{'shared.Aggiungi' | translate}}
  </button>
</mat-card>
<div class="table-container" [hidden]="!(payments | async).length">
  <table mat-table matSort [dataSource]="payments">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>{{'shared.ID' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        {{ row.id }}
      </td>
    </ng-container>
    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'shared.Codice' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        {{ row.code }}
      </td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>{{'shared.Descrizione' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        <mat-icon *ngIf="row.description" [matTooltip]="row.description">description</mat-icon>
      </td>
    </ng-container>
    <!-- <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef> Descrizione </th>
      <td mat-cell *matCellDef="let row">
        {{row.description}}
      </td>
    </ng-container> -->
    <ng-container matColumnDef="start">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'payments.Start' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        {{'shared.' + row.start | translate}}
      </td>
    </ng-container>
    <ng-container matColumnDef="lead">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'payments.Lead' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        {{ row.lead }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <button *ngIf="canAdd" mat-icon-button mat-raised-button color="accent"
          (click)="$event.stopPropagation(); addPayment()">
          <mat-icon matTooltip="{{'shared.Aggiungi' | translate}}">playlist_add</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="canEdit">
          <button mat-icon-button (click)="$event.stopPropagation(); editPayment(row)">
            <mat-icon matTooltip="{{'shared.Modifica' | translate}}">create</mat-icon>
          </button>
        </ng-container>
        <button *ngIf="canSelect" mat-raised-button color="primary" (click)="selectPayment(row)">
          {{'shared.Seleziona' | translate}}
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>