<activity-report-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters">
</activity-report-filters>
<mat-card class="u-center-text" *ngIf="!activitiesReport?.length">
  <h4>Nessuna attività trovata</h4>
</mat-card>
<div class="table-container" [hidden]="!activitiesReport?.length">
  <table mat-table matSort matSortDisableClear [dataSource]="activitiesReport">

    <ng-container matColumnDef="client">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Cliente </th>
      <td mat-cell *matCellDef="let row">
        {{ row.project?.client.name }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="project">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Commessa </th>
      <td mat-cell *matCellDef="let row">
        {{ row.project?.identifier }} - {{row.project?.description}}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="professional">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Professionista </th>
      <td mat-cell *matCellDef="let row">
        {{ row.professional?.user?.name }} {{row.professional?.user?.lastname}}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Mese </th>
      <td mat-cell *matCellDef="let row">
        {{row.date}}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="hours">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Ore </th>
      <td mat-cell *matCellDef="let row">
        {{row.hours}} (Giornate: {{row.hours / 8 | number: '.0-3'}})
      </td>
      <td mat-footer-cell *matFooterCellDef>
        <strong *ngIf="totalHours">
          Totale: {{totalHours}} (Giornate: {{totalHours / 8 | number: '.0-3'}})
        </strong>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <button *ngIf="canAdd" mat-icon-button mat-raised-button color="accent"
          (click)="$event.stopPropagation();exportActivityReport()">
          <mat-icon>file_download</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let row">
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total"
    showFirstLastButtons>
  </mat-paginator>
</div>