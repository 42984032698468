import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {RiskOwner } from 'src/app/commons/models/risk-owner.model';
import { AlertService } from 'src/app/commons/services/alert.service';
import * as RiskOwnerActions from 'src/app/store/actions/risk-owner.actions';
import { AppState } from 'src/app/store/reducers';

@Component({
  selector: 'app-risk-owner-edit',
  templateUrl: './risk-owner-edit.component.html',
  styleUrls: ['./risk-owner-edit.component.scss']
})
export class RiskOwnerEditComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  private _riskOwner: RiskOwner;

  currentRiskOwner: RiskOwner;

  riskOwnerForm: FormGroup;
  constructor(private store$: Store<AppState>, private alertService: AlertService, private fb: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private translateService: TranslateService) {
  }

  ngOnInit() {
    if (this.data) {
      this.riskOwner = this.data.riskOwner;
    }
    this._createForm();
    this.ngOnChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private _createForm() {
    let group = {
      name: ["", Validators.required],
    }
    this.riskOwnerForm = this.fb.group(group);
  }

  private ngOnChanges() {
    if (this.riskOwnerForm) {
      this.riskOwnerForm.reset();
      if (this.riskOwner) {
        this._initFormValues(this.riskOwner);
      }
    }

  }

  private _initFormValues(riskOwner: RiskOwner) {
    if (riskOwner) {
      this.riskOwnerForm.patchValue({
        name: riskOwner.name,
      });
    }
  }

  get riskOwner(): RiskOwner {
    return this._riskOwner;
  }

  set riskOwner(riskOwner: RiskOwner) {
    this._riskOwner = riskOwner;
    this.ngOnChanges();
  }

  private _prepareSaveRiskOwner(): RiskOwner {
    let savingRiskOwner: RiskOwner = RiskOwner.fromFormGroup(this.riskOwnerForm, this.riskOwner);
    return savingRiskOwner;
  }

  save() {
    let unsavedEntity = this._prepareSaveRiskOwner();
    this.store$.dispatch(RiskOwnerActions.saveRiskOwner({ riskOwner: unsavedEntity }));
  }

  close() {
    if (this.riskOwnerForm.pristine) {
      this.store$.dispatch(RiskOwnerActions.closeRiskOwnerDialog())
    } else {
      this.alertService
        .showConfirmDialog(
          `${this.translateService.instant('shared.Chiudi')}`,
          `${this.translateService.instant('shared.Ci sono modifiche non salvate. Sei sicuro di voler chiudere?')}`
        )
        .subscribe(result => {
          if (result) {
            this.store$.dispatch(RiskOwnerActions.closeRiskOwnerDialog())
          }
        });
    }
  }

  deleteRiskOwner() {
    if (this.riskOwner) {
      this.store$.dispatch(RiskOwnerActions.deleteRiskOwner({ riskOwner: this.riskOwner.toDTO() }))
    }
  }

  revert() {
    this.ngOnChanges();
  }
}
