import { AbstractControl, FormArray } from '@angular/forms';
import { formatDateForBackend } from 'src/app/helpers/time.utils';

import { Base, BaseDTO } from './base.model';
import { BillingLine, BillingLineDTO } from './billing-line.model';
import { Client, ClientDTO } from './client.model';

export const BILLING_LINE_GROUPS = ["Commessa", "Cliente"];

export interface InvoiceFilters {
  start?: string;
  end?: string;
  client_id?: number;
  project_id?: number;
  identifier?: string;
  paid?: boolean;
  canceled?: boolean;
  billing_line_id?: number;
}

export interface InvoiceDTO extends BaseDTO {
  date: string;
  expiration: string;
  amount: number;
  identifier: string;
  payment_date?: string;
  payment_notes?: string;
  canceled_date?: string;
  generated: boolean;
  fe_id?: string;

  client: ClientDTO;
  billing_lines: BillingLineDTO[];
  fattura24_details?: string;
}

export class Invoice extends Base {
  date: Date;
  expiration: Date;
  amount: number;
  paymentDate: Date;
  paymentNotes: string;
  canceledDate: Date;
  generated: boolean;
  feId?: string;
  identifier: string;
  client: Client;
  billingLines: BillingLine[];
  fattura24Details: string;

  constructor(source: InvoiceDTO) {
    super(source);
    if (source) {
      this.date = new Date(source.date);
      this.expiration = new Date(source.expiration);
      this.amount = source.amount;

      if (source?.payment_date) {
        this.paymentDate = new Date(source.payment_date);
      }
      this.paymentNotes = source.payment_notes;
      if (source.canceled_date) {
        this.canceledDate = new Date(source.canceled_date);
      }
      this.generated = source.generated;
      this.feId = source.fe_id;
      this.identifier = source.identifier;
      this.client = new Client(source.client);
      if (source.billing_lines) {
        this.billingLines = source.billing_lines.map(dto => new BillingLine(dto));
      }
      this.fattura24Details = source.fattura24_details;
    }
  }

  toDTO(): InvoiceDTO {
    let result: InvoiceDTO = <InvoiceDTO>super.toDTO();
    result.date = formatDateForBackend(this.date);
    result.expiration = formatDateForBackend(this.expiration);
    result.payment_date = formatDateForBackend(this.paymentDate);
    result.payment_notes = this.paymentNotes;
    result.identifier = this.identifier;
    result.canceled_date = formatDateForBackend(this.canceledDate);
    result.fattura24_details = this.fattura24Details;
    result.billing_lines = this.billingLines
      ? this.billingLines.map((billingLine) => billingLine.toDTO())
      : null;
    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: Invoice) {
    const formModel = formGroup.getRawValue();
    let invoice: Invoice = new Invoice(null);
    invoice.date = formModel.date;
    invoice.expiration = formModel.expiration;
    invoice.paymentDate = formModel.paymentDate;
    invoice.paymentNotes = formModel.paymentNotes;
    invoice.canceledDate = formModel.canceledDate;
    invoice.identifier = formModel.identifier;

    const billingLinesCtrl: FormArray = formGroup.get('billingLines') as FormArray;
    invoice.billingLines = billingLinesCtrl?.controls.map(model => BillingLine.fromFormGroup(model));

    if (original) {
      invoice.id = original.id;
    }
    return invoice;
  }

  get paid(): boolean {
    return !!this.paymentDate;
  }

  get invoiceIdentifier(): string {
    let year = this.date.getFullYear();
    if (this.generated) return `${this.identifier}/${year}`;

    return `${this.identifier}/${year}_DRAFT`;
  }

  get totalActivities(): number {
    return this.billingLines?.filter(line => line.type == 'Attività').map(line => line.amount).reduce((total, line) => total + line, 0);
  }

  get totalExpenses(): number {
    return this.billingLines?.filter(line => line.type == 'Spesa').map(line => line.amount).reduce((total, line) => total + line, 0);
  }

}
