import { AbstractControl, FormArray } from '@angular/forms';
import { User, UserDTO } from 'src/app/commons/models/user.model';
import { dateFromDTO, formatDateForBackend } from 'src/app/helpers/time.utils';

import { Activity, ActivityDTO } from './activity.model';
import { BankAccount, BankAccountDTO } from './bank-account.model';
import { Base, BaseDTO } from './base.model';
import { BonusLine, BonusLineDTO } from './bonus_line.model';
import { Budget, BudgetDTO } from './budget.model';
import { Client, ClientDTO } from './client.model';
import { Contact, ContactDTO } from './contact.model';
import { Currency, CurrencyDTO } from './currency.model';
import { IvaTypology, IvaTypologyDTO } from './iva-typology.model';
import { Offer, OfferDTO } from './offer.model';
import { Payment, PaymentDTO } from './payment.model';
import { Professional, ProfessionalDTO } from './professional.model';
import { ProjectAttachment, ProjectAttachmentDTO } from './project-attachment.model';
import { ProjectBonus, ProjectBonusDTO } from './project-bonus.model';
import { ProjectComment, ProjectCommentDTO } from './project-comment.model';
import { RiskIdentification, RiskIdentificationDTO } from './risk-identification.model';
import { SalesBonus, SalesBonusDTO } from './sales-bonus.model';

export type ExpenseType = 'Piè di lista' | 'Giornaliero' | 'Totale' | 'Nessuno';
export const EXPENSES: ExpenseType[] = [
  'Piè di lista',
  'Giornaliero',
  'Totale',
  'Nessuno',
];

export type RefundType = 'Piè di lista' | 'Giornaliero' | 'Totale' | 'Nessuno';
export const REFUNDS: RefundType[] = [
  'Piè di lista',
  'Giornaliero',
  'Totale',
  'Nessuno',
];

export type AdvisoryType = 'A ore' | 'A giornata' | 'Mensile' | 'Fisso totale';
export const ADVISORIES: AdvisoryType[] = ['A ore', 'Mensile', 'Fisso totale'];

export type PaymentType =
  | 'A ore'
  | 'A giornata'
  | 'Mensile'
  | 'Fisso totale'
  | 'Nessuno';
export const PAYMENTS: PaymentType[] = [
  'A ore',
  'Mensile',
  'Fisso totale',
  'Nessuno',
];

export interface ProjectFilters {
  search?: string;
  professional?: Professional;
  client?: Client;
  start?: Date;
  end?: Date;
  creator?: User;
  modifier?: User;
  promotion?: boolean;
  excludeWithOffer?: boolean;
  trashed?: boolean;
  closed?: boolean;
}

export interface ProjectDTO extends BaseDTO {
  identifier: string;
  client: ClientDTO;
  client_id: number;
  project_creator: UserDTO;
  project_creator_id: number;
  project_modifier: UserDTO;
  project_modifier_id: number;
  description: string;
  order_references: string;
  order_comments: string;
  order_date: string;
  promotion: boolean;
  currency: CurrencyDTO;
  currency_id: number;
  start: string;
  end: string;
  courtesy_copy: string;
  bank_account: BankAccountDTO;
  bank_account_id: number;
  budgets: BudgetDTO[];
  budget_id: number;
  total_budget: number;

  //Pagamento consulenza
  advisory_payment: PaymentType;

  //Rimborso spese
  expenses_refund_type: RefundType;
  refund_forfait: number;

  //Addebito consulenza
  advisory_debit_type: AdvisoryType;
  value: number;

  //Addebito spese
  expenses_debit_type: ExpenseType;
  expenses_forfait: number;

  km_revenue: number;
  biling_plan: string;
  success_fee_rules: string;
  iva_typology: IvaTypologyDTO;
  iva_typology_id: number;
  payment: PaymentDTO;
  professionals: ProfessionalDTO[];
  payment_id: number;
  invoice_discount: number;
  bonus_client: number;
  bonus_sales: number;
  bonus_project: number;
  // advisory_fee: number;
  head_project: string;
  head_project_id: number;
  km_cost: number;
  note: string;
  risk_identifications: RiskIdentificationDTO[];
  attachments: ProjectAttachmentDTO[];
  comments: ProjectCommentDTO[];
  selected_offer: OfferDTO;
  selected_offer_id: number;
  sales_bonuses: SalesBonusDTO[];
  project_bonuses: ProjectBonusDTO[];
  bonus_in_percentage: boolean;
  bonus_lines: BonusLineDTO[];
  activities: ActivityDTO[];
  advisor_id: number;
  advisor: ContactDTO;
  advisor_start: string;
  advisor_end: string;
  advisor_percentage: number;
  closed: boolean;

  activities_count: number;
  expenses_count: number;
}

export class Project extends Base {
  identifier: string;
  client: Client;
  projectCreator: User;
  projectModifier: User;
  description: string;
  orderReferences: string;
  orderComments: string;
  orderDate: Date;
  promotion: boolean;
  currency: Currency;
  start: Date;
  end: Date;
  courtesyCopy: string;
  bankAccount: BankAccount;
  budgets: Budget[];
  totalBudget: number;
  expensesDebitType: ExpenseType;
  expensesForfait: number;
  expensesRefundType: RefundType;
  professionals: Professional[];
  refundForfait: number;
  advisoryDebitType: AdvisoryType;
  value: number;
  advisoryPayment: PaymentType;
  kmRevenue: number;
  bilingPlan: string;
  successFeeRules: string;
  ivaTypology: IvaTypology;
  payment: Payment;
  invoiceDiscount: number;
  bonusClient: number;
  bonusSales: number;
  bonusProject: number;
  //advisoryFee: number;
  //headProject: string;
  kmCost: number;
  note: string;
  attachments: ProjectAttachment[];
  riskIdentifications: RiskIdentification[];
  comments: ProjectComment[];
  selectedOffer: Offer;
  salesBonuses: SalesBonus[];
  projectBonuses: ProjectBonus[];
  bonusInPercentage: boolean;
  bonusLines: BonusLine[];
  activities: Activity[];
  advisorId: number;
  advisor: Contact;
  advisorStart: Date;
  advisorEnd: Date;
  advisorPercentage: number;
  activitiesCount: number;
  expensesCount: number;
  closed: boolean;

  constructor(source: ProjectDTO) {
    super(source);
    if (source) {
      if (source.currency) {
        this.currency = new Currency(source.currency);
        this.addLoadedRelation('currency');
      }
      if (source.selected_offer) {
        this.selectedOffer = new Offer(source.selected_offer);
        this.addLoadedRelation('selected_offer');
      }
      if (source.bank_account) {
        this.bankAccount = new BankAccount(source.bank_account);
        this.addLoadedRelation('bank_account');
      }
      if (source.iva_typology) {
        this.ivaTypology = new IvaTypology(source.iva_typology);
        this.addLoadedRelation('iva_typology');
      }
      if (source.client) {
        this.client = new Client(source.client);
        this.addLoadedRelation('client');
      }
      if (source.project_creator) {
        this.projectCreator = new User(source.project_creator);
        this.addLoadedRelation('project_creator');
      }
      if (source.project_modifier) {
        this.projectModifier = new User(source.project_modifier);
        this.addLoadedRelation('project_modifier');
      }
      if (source.payment) {
        this.payment = new Payment(source.payment);
        this.addLoadedRelation('payment');
      }
      if (source.professionals) {
        this.professionals = source.professionals.map(
          (dto) => new Professional(dto)
        );
        this.addLoadedRelation('professionals');
      }
      if (source.activities) {
        this.activities = source.activities.map((dto) => new Activity(dto));
        this.addLoadedRelation('activities');
      }
      if (source.bonus_lines) {
        this.bonusLines = source.bonus_lines.map((dto) => new BonusLine(dto));
        this.addLoadedRelation('bonus_lines');
      }
      if (source.sales_bonuses) {
        this.salesBonuses = source.sales_bonuses.map(
          (dto) => new SalesBonus(dto)
        );
        this.addLoadedRelation('sales_bonuses');
      }
      if (source.project_bonuses) {
        this.projectBonuses = source.project_bonuses.map(
          (dto) => new ProjectBonus(dto)
        );
        this.addLoadedRelation('project_bonuses');
      }
      if (source.attachments) {
        this.attachments = source.attachments.map(
          (dto) => new ProjectAttachment(dto)
        );
        this.addLoadedRelation('attachments');
      }
      if (source.comments) {
        this.comments = source.comments.map((dto) => new ProjectComment(dto));
        this.addLoadedRelation('comments');
      }
      if (source.budgets) {
        this.budgets = source.budgets.map((dto) => new Budget(dto));
        this.addLoadedRelation('budgets');
      }
      if (source.risk_identifications) {
        this.riskIdentifications = source.risk_identifications.map(
          (dto) => new RiskIdentification(dto)
        );
        this.addLoadedRelation('risk_identifications');
      }
      this.identifier = source.identifier;
      this.description = source.description;
      this.orderReferences = source.order_references;
      this.orderComments = source.order_comments;
      this.orderDate = source.order_date ? dateFromDTO(source.order_date) : null;
      this.promotion = source.promotion;
      this.start = source.start ? dateFromDTO(source.start) : null;
      this.end = source.end ? dateFromDTO(source.end) : null;
      this.courtesyCopy = source.courtesy_copy;
      this.totalBudget = source.total_budget;
      this.expensesDebitType = source.expenses_debit_type;
      this.expensesForfait = source.expenses_forfait;
      this.expensesRefundType = source.expenses_refund_type;
      this.refundForfait = source.refund_forfait;
      this.advisoryDebitType = source.advisory_debit_type;
      this.value = source.value;
      this.advisoryPayment = source.advisory_payment;
      this.kmRevenue = source.km_revenue;
      this.bilingPlan = source.biling_plan;
      this.successFeeRules = source.success_fee_rules;
      this.invoiceDiscount = source.invoice_discount;
      this.bonusClient = source.bonus_client;
      this.bonusSales = source.bonus_sales;
      this.bonusProject = source.bonus_project;
      this.closed = source.closed;
      //this.advisoryFee = source.advisory_fee;
      this.kmCost = source.km_cost;
      this.note = source.note;
      //this.headProject = source.head_project;
      this.bonusInPercentage = source.bonus_in_percentage;
      this.advisorStart = source.advisor_start
        ? dateFromDTO(source.advisor_start)
        : null;
      this.advisorEnd = source.advisor_end
        ? dateFromDTO(source.advisor_end)
        : null;
      this.advisorPercentage = source.advisor_percentage;
      this.advisorId = source.advisor_id;
      if (source.advisor) {
        this.advisor = new Contact(source.advisor);
        this.addLoadedRelation('advisor');
      }
      this.activitiesCount = source.activities_count;
      this.expensesCount = source.expenses_count;
    }
  }

  toDTO(): ProjectDTO {
    let result: ProjectDTO = <ProjectDTO>super.toDTO();
    result.identifier = this.identifier;
    result.description = this.description;
    result.order_references = this.orderReferences;
    result.order_comments = this.orderComments;
    result.order_date = this.orderDate ? formatDateForBackend(this.orderDate) : null;
    result.promotion = this.promotion;
    result.total_budget = this.totalBudget;
    result.expenses_debit_type = this.expensesDebitType;
    result.expenses_forfait = this.expensesForfait;
    result.expenses_refund_type = this.expensesRefundType;
    result.refund_forfait = this.refundForfait;
    result.advisory_debit_type = this.advisoryDebitType;
    result.value = this.value;
    //result.head_project = this.headProject;
    result.advisory_payment = this.advisoryPayment;
    result.km_revenue = this.kmRevenue;
    result.biling_plan = this.bilingPlan;
    result.success_fee_rules = this.successFeeRules;
    result.invoice_discount = this.invoiceDiscount;
    result.bonus_client = this.bonusClient;
    result.bonus_sales = this.bonusSales;
    result.bonus_project = this.bonusProject;
    //result.advisory_fee = this.advisoryFee;
    result.km_cost = this.kmCost;
    result.note = this.note;
    result.start = this.start ? formatDateForBackend(this.start) : null;
    result.end = this.end ? formatDateForBackend(this.end) : null;
    result.courtesy_copy = this.courtesyCopy;
    result.note = this.note;
    result.iva_typology = this.ivaTypology ? this.ivaTypology.toDTO() : null;
    result.iva_typology_id = this.ivaTypology ? this.ivaTypology.id : null;
    result.bank_account = this.bankAccount ? this.bankAccount.toDTO() : null;
    result.bank_account_id = this.bankAccount ? this.bankAccount.id : null;
    result.currency = this.currency ? this.currency.toDTO() : null;
    result.selected_offer = this.selectedOffer
      ? this.selectedOffer.toDTO()
      : null;
    result.selected_offer_id = this.selectedOffer
      ? this.selectedOffer.id
      : null;
    result.currency_id = this.currency ? this.currency.id : null;
    result.payment = this.payment ? this.payment.toDTO() : null;
    result.payment_id = this.payment ? this.payment.id : null;
    result.client = this.client ? this.client.toDTO() : null;
    result.client_id = this.client ? this.client.id : null;
    result.project_creator = this.projectCreator
      ? this.projectCreator.toDTO()
      : null;
    result.project_creator_id = this.projectCreator
      ? this.projectCreator.id
      : null;
    result.project_modifier = this.projectModifier
      ? this.projectModifier.toDTO()
      : null;
    result.project_modifier_id = this.projectModifier
      ? this.projectModifier.id
      : null;
    result.professionals = this.professionals
      ? this.professionals.map((professional) => professional.toDTO())
      : null;
    result.activities = this.activities
      ? this.activities.map((activity) => activity.toDTO())
      : null;
    result.sales_bonuses = this.salesBonuses
      ? this.salesBonuses.map((salesBonus) => salesBonus.toDTO())
      : null;
    result.project_bonuses = this.projectBonuses
      ? this.projectBonuses.map((projectBonus) => projectBonus.toDTO())
      : null;
    result.attachments = this.attachments
      ? this.attachments.map((attachment) => attachment.toDTO())
      : null;
    result.comments = this.comments
      ? this.comments.map((comment) => comment.toDTO())
      : null;
    result.budgets = this.budgets
      ? this.budgets.map((budget) => budget.toDTO())
      : null;
    result.risk_identifications = this.riskIdentifications
      ? this.riskIdentifications.map((riskIdentification) =>
        riskIdentification.toDTO()
      )
      : null;
    result.bonus_lines = this.bonusLines
      ? this.bonusLines.map((bonusLine) => bonusLine.toDTO())
      : null;
    result.bonus_in_percentage = this.bonusInPercentage;
    result.advisor_start = this.advisorStart
      ? formatDateForBackend(this.advisorStart)
      : null;
    result.advisor_end = this.advisorEnd
      ? formatDateForBackend(this.advisorEnd)
      : null;
    result.advisor_percentage = this.advisorPercentage;
    result.advisor = this.advisor ? this.advisor.toDTO() : null;
    result.advisor_id = this.advisorId ? this.advisorId : null;
    result.activities_count = this.activitiesCount;
    result.expenses_count = this.expensesCount;
    result.closed = this.closed;
    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: Project) {
    const formModel = formGroup.value;
    let project: Project = new Project(null);
    project.identifier = formModel.identifier;
    project.client = formModel.client;
    //project.riskIdentifications = formModel.riskIdentifications;
    project.description = formModel.description;
    project.orderReferences = formModel.orderReferences;
    project.orderComments = formModel.orderComments;
    project.orderDate = formModel.orderDate;
    project.promotion = formModel.promotion;
    project.currency = formModel.currency;
    project.start = formModel.start;
    project.end = formModel.end;
    project.courtesyCopy = formModel.courtesyCopy;
    project.bankAccount = formModel.bankAccount;
    // project.budgets = formModel.budgets;
    project.totalBudget = formModel.totalBudget;
    project.expensesDebitType = formModel.expensesDebitType;
    project.expensesForfait = formModel.expensesForfait;
    project.expensesRefundType = formModel.expensesRefundType;
    project.refundForfait = formModel.refundForfait;
    project.advisoryDebitType = formModel.advisoryDebitType;
    project.value = formModel.value;
    project.advisoryPayment = formModel.advisoryPayment;
    project.kmRevenue = formModel.kmRevenue;
    project.bilingPlan = formModel.bilingPlan;
    project.successFeeRules = formModel.successFeeRules;
    project.ivaTypology = formModel.ivaTypology;
    project.payment = formModel.payment;
    project.invoiceDiscount = formModel.invoiceDiscount;
    project.bonusClient = formModel.bonusClient;
    project.bonusSales = formModel.bonusSales;
    project.bonusProject = formModel.bonusProject;
    //project.advisoryFee = formModel.advisoryFee;
    // project.headProject = formModel.headProject;
    project.kmCost = formModel.kmCost;
    project.note = formModel.note;
    project.attachments = formModel.attachments;
    project.comments = formModel.comments;
    project.selectedOffer = formModel.selectedOffer;
    project.salesBonuses = formModel.salesBonuses;
    project.projectBonuses = formModel.projectBonuses;
    project.bonusInPercentage = formModel.bonusInPercentage;
    project.advisorStart = formModel.advisorStart;
    project.advisorEnd = formModel.advisorEnd;
    project.advisorPercentage = formModel.advisorPercentage;
    project.advisor = formModel.advisor;
    project.advisorId = formModel.advisor?.id;
    project.closed = formModel.closed;

    const professionalCtrls: FormArray = formGroup.get(
      'professionals'
    ) as FormArray;
    project.professionals = professionalCtrls?.controls.map((model) =>
      Professional.fromFormGroup(model)
    );

    const riskCtrls: FormArray = formGroup.get(
      'riskIdentifications'
    ) as FormArray;
    project.riskIdentifications = riskCtrls?.controls.map((model) =>
      RiskIdentification.fromFormGroup(model)
    );

    const budgetCtrls: FormArray = formGroup.get('budgets') as FormArray;
    project.budgets = budgetCtrls?.controls.map((model) =>
      Budget.fromFormGroup(model)
    );
    if (original) {
      project.id = original.id;
    }
    return project;
  }

  get activityHours(): number {
    let hours = 0;
    this.activities?.forEach((activity: Activity) => {
      hours += activity.hours;
    });
    return hours;
  }

  get billedActivityHours(): number {
    let hours = 0;
    let billedActivities = this.activities?.filter((a) => !!a.billingLineId);
    billedActivities?.forEach((activity: Activity) => {
      hours += activity.hours;
    });
    return hours;
  }

  get totalBudgetHours(): number {
    let hours = 0;
    this.budgets?.forEach((budget: Budget) => {
      hours += budget.hours;
    });
    return hours;
  }

  get headProject(): Professional {
    return this.professionals?.find((p) => p.headProject);
  }
}
