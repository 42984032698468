import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Role } from 'src/app/commons/models/user.model';

@Pipe({
  name: 'offerProbability'
})
export class OfferProbabilityPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: number): unknown {
    switch (value) {
      case 10:
        return this.translateService.instant('offers.Bassa') + "10%";
      case 40:
        return this.translateService.instant('offers.Media') + "40%";
      case 70:
        return this.translateService.instant('offers.Alta') + "70%";
      case 90:
        return this.translateService.instant('offers.Molto Alta') + "90%";
      case 100:
        return this.translateService.instant('offers.Sicura') + "100%";
    }
  }

}
