<header mat-dialog-title class="u-flex-full">
  <span>
    {{'professional.Seleziona professionista' | translate}}
  </span>
</header>
<mat-dialog-content>
  <professional-list [professionals]="professionals" [defaultFilters]="defaultFilters"
    [displayedColumns]="displayedColumns" [total]="total" [canSelect]="true" (onSortChange)="sortChange($event)"
    (onPageChange)="pageChange($event)" (onFilterChange)="filtersChange($event)" [showProject]="false"
    (onSelectProfessional)="selectProfessional($event)"></professional-list>
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">{{'shared.Chiudi' | translate}}</button>
</mat-dialog-actions>
