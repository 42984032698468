<header mat-dialog-title class="u-flex-full">
  <span>
    <span *ngIf="user">{{'shared.Cambia password per' | translate : {user: user.name} }}</span>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="passwordForm" novalidate>
    <div class="u-full-width">
      <span class="u-flex-full">
        <mat-form-field>
          <input type="password" placeholder="{{'users.Nuova password' | translate}}" matInput formControlName="newPass" >
          <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field class="u-full-width">
          <input type="password" placeholder="{{'users.Conferma password' | translate}}" matInput formControlName="confirmPass">
          <mat-error *ngIf="passwordForm.controls['confirmPass'].errors?.notEquivalent">{{'users.Le password non coincidono' | translate}}</mat-error>
        </mat-form-field>
      </span>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()"
      [disabled]="passwordForm.pristine">{{'shared.Reset' | translate}}</button>
    <button mat-raised-button color="primary" (click)="updatePassword()"
      [disabled]="passwordForm.pristine || !passwordForm.valid">{{'shared.Salva' | translate}}</button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">{{'shared.Chiudi' | translate}}</button>
</mat-dialog-actions>
