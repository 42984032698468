<header mat-dialog-title class="u-flex-full">
  <span>
    <span *ngIf="offer">{{'offers.Modifica offerta' | translate}} : {{offer?.title}}</span>
    <span *ngIf="!offer">{{'offers.Nuova offerta' | translate}}</span>
  </span>
  <span *ngIf="offer">
    <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
      <button mat-menu-item (click)="deleteOffer()">
        <mat-icon>delete</mat-icon>
        {{'shared.Elimina' | translate}}
      </button>
    </mat-menu>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="offerForm" novalidate>
    <div class="u-flex-full">
      <!-- <mat-form-field class="u-full-width">
        <mat-label>Business Opportunity</mat-label>
        <app-business-opportunity-field formControlName="businessOpportunity"></app-business-opportunity-field>
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field> -->
      <mat-form-field class="u-full-width">
        <mat-label>{{'offers.Versione' | translate}}</mat-label>
        <input matInput formControlName="version" type="number" readonly>
        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
      </mat-form-field>

      <mat-form-field class="u-full-width">
        <mat-label>{{'shared.Titolo' | translate}}</mat-label>
        <input matInput formControlName="title" type="text">
        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
      </mat-form-field>
    </div>

    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input matInput [matDatepicker]="fromDatePicker1" formControlName="date" placeholder="{{'shared.Data' | translate}}" />
        <mat-datepicker-toggle matSuffix [for]="fromDatePicker1"></mat-datepicker-toggle>
        <mat-datepicker #fromDatePicker1></mat-datepicker>
        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
      </mat-form-field>

      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'offers.Valore totale' | translate}}" formControlName="totalAmount" type="number" min="0" forceNumber>
        <span class="prefix" matPrefix>€</span>
        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
      </mat-form-field>
    </div>

    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <mat-select placeholder="{{'shared.Probabilità' | translate}}" formControlName="probability">
          <mat-option *ngFor="let probability of probabilities" [value]="probability">
            {{probability | offerProbability}}
          </mat-option>
        </mat-select>
        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
      </mat-form-field>

      <mat-form-field class="u-full-width">
        <mat-select placeholder="{{'offers.Tipologia Spesa' | translate}}" formControlName="expenseType">
          <mat-option *ngFor="let type of expenseTypes" [value]="type">
            {{'shared.' + type | translate}}
          </mat-option>
        </mat-select>
        <mat-error>{{'shared.Campo obbligatorio' | translate}}</mat-error>
      </mat-form-field>
    </div>

    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input matInput placeholder="{{'shared.Costo € / km' | translate}}" formControlName="kmCost" type="number" min="0" forceNumber>
        <span class="prefix" matPrefix>€</span>
      </mat-form-field>
      <mat-form-field class="u-full-width" *ngIf="offerForm?.value?.businessOpportunity">
        <mat-label>{{'shared.Commessa' | translate}}</mat-label>
        <app-project-field formControlName="project" [projectFilters]="projectFilters" [showPromoFilter]="false"
          [showClientFilter]="false"></app-project-field>
      </mat-form-field>
    </div>

    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <mat-label>{{'shared.Note' | translate}}</mat-label>
        <textarea matInput formControlName="notes" rows="3"></textarea>
      </mat-form-field>
    </div>

    <mat-card>
      <div class="u-flex-full">
        <h4>{{'shared.Distribuzione giornate' | translate}}</h4>
        <h4>
          <span>{{'offers.Valore stimato' | translate}} : {{estimatedValue | customCurrency}}&nbsp;€</span><br />
          <ng-container *ngIf="days">
            <span>{{'offers.Totale giornate' | translate}} : {{days}}</span><br />
            <span>{{'offers.Valore a giornata' | translate}} : {{(days ? estimatedValue / days : 0) |
              customCurrency}}&nbsp;€</span><br />
          </ng-container>
        </h4>
      </div>
      <app-days-distribution-field [form]="daysDistributionCtrls" [daysDistributions]="offer?.daysDistributions"
        [reset$]="reset$"></app-days-distribution-field>
    </mat-card>

    <mat-accordion multi="true">
      <!-- <mat-expansion-panel>
        <mat-expansion-panel-header>
          Distribuzione giornate
        </mat-expansion-panel-header>
        <app-days-distribution-field [form]="daysDistributionCtrls" [daysDistributions]="offer?.daysDistributions" [reset$]="reset$"></app-days-distribution-field>
        <div class="read-only-fields">
          <div class="u-flex-full">
            <mat-form-field class="u-full-width">
              <mat-label>Giornate</mat-label>
              <input matInput formControlName="days" readonly>
              <mat-hint>Campo in sola lettura</mat-hint>
            </mat-form-field>

            <mat-form-field class="u-full-width">
              <mat-label>Valore a giornata</mat-label>
              <input matInput formControlName="valuePerDay" readonly>
              <span class="prefix" matPrefix>€</span>
              <mat-hint>Campo in sola lettura</mat-hint>
            </mat-form-field>

            <mat-form-field class="u-full-width">
              <mat-label>Valore stimato </mat-label>
              <input matInput formControlName="estimatedValue" readonly>
              <span class="prefix" matPrefix>€</span>
              <mat-hint>Campo in sola lettura</mat-hint>
            </mat-form-field>
          </div>
        </div>
      </mat-expansion-panel> -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          {{'shared.Allegati' | translate}}
        </mat-expansion-panel-header>
        <h4 class="u-center-text" *ngIf="!offer">{{'offers.Salvare offerta per poter inserire allegati' | translate}}
        </h4>
        <ng-container *ngIf="offer">
          <app-offer-attachments [filters]="filters"></app-offer-attachments>
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>

    <!-- {{offer?.attachments | json}} -->
  </form>

</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()"
      [disabled]="offerForm.pristine">{{'shared.Reset' | translate}}</button>
    <button mat-raised-button color="primary" (click)="save()"
      [disabled]="offerForm.pristine || !offerForm.valid">{{'shared.Salva' | translate}}</button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">{{'shared.Chiudi' | translate}}</button>
</mat-dialog-actions>