<header mat-dialog-title class="u-flex-full">
  <span>{{'projects.Seleziona commessa' | translate}} </span>
</header>
<mat-dialog-content>
  <project-list [projects]="projects" [defaultFilters]="defaultFilters" [displayedColumns]="displayedColumns"
    [total]="total" [canSelect]="true" [canSelectClosed]="canSelectClosed" (onSortChange)="sortChange($event)"
    (onPageChange)="pageChange($event)" (onFilterChange)="filtersChange($event)"
    (onSelectProject)="selectProject($event)" [showClientFilter]="showClientFilter"
    [showPromoFilter]="showPromoFilter"></project-list>
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span> </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">
    {{'shared.Chiudi' | translate}}
  </button>
</mat-dialog-actions>